import { Form, useField, useFormikContext } from 'formik';
import FormElement from 'components/global/FormElement';
import CustomSelect from 'components/global/CustomSelect';
import {
  ExplanatoryParagraphProps,
  FormElementPropsType,
  HorizontalInputPairProps,
  StaticInputProps,
} from '../../../types/stepFlow.type';
import RadioGroup from '../../global/RadioGroup';
import styles from './stepForm.module.scss';
import React, { Key, useEffect } from 'react';
import Tooltip from '../../global/Tooltip';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import { FormikCheckbox, FormikCheckboxProps } from '../../global/Checkbox';

interface TrialStepFormProps {
  children?: React.ReactNode;
  rightHalfElement?: React.ReactNode;
  formElements: FormElementPropsType[];
  valuesCallback?: (values: any, touched: any) => void;
}

interface StaticTextFieldProps {
  element: StaticInputProps;
}

const StaticTextField: React.FC<StaticTextFieldProps> = ({ element }) => {
  const [field] = useField(element.name);

  return (
    <div className={styles['step-form-static-text']}>
      <div className={styles['step-form-static-text__label']}>
        {'label' in element && element?.label ? element.label : 'No Label'}
        {'tooltip' in element && element?.tooltip ? (
          <Tooltip label={element.tooltip}>
            <InfoIcon />
          </Tooltip>
        ) : null}
      </div>
      <div className={styles['step-form-static-text__value']}>
        {('displayValue' in element && element?.displayValue) || element.value}
        {/* Hidden input field so Formik can see these */}
        {'name' in element && element?.name ? (
          <input type="hidden" {...field} />
        ) : null}
      </div>
    </div>
  );
};

const renderElement = (
  element: FormElementPropsType,
  index: Key | null | undefined,
  rest: Record<string, any>,
) => {
  index = `${index}-${element.element}-${
    ('name' in element && element?.name) || 'no-field-name'
  }`;
  switch (element.element) {
    case 'select':
      return (
        <CustomSelect
          name={element.name}
          label={('label' in element && element.label) || element.name}
          direction={('direction' in element && element?.direction) || 'row'}
          options={('options' in element && element.options) || []}
          key={index}
          allowOther={('allowOther' in element && element.allowOther) || false}
          otherInputLabel={
            ('otherInputLabel' in element && element.otherInputLabel) ||
            element.name
          }
        />
      );
    case 'radioGroup':
      return (
        <RadioGroup
          name={element.name ? element.name : 'radioGroup'}
          key={index}
          options={element?.options ? element.options : []}
          align={
            'align' in element && element?.align ? element.align : undefined
          }
          radioGroupHeader={
            ('radioGroupHeader' in element && element?.radioGroupHeader) ||
            undefined
          }
          radioGroupFooter={
            ('radioGroupFooter' in element && element?.radioGroupFooter) ||
            undefined
          }
          defaultValue={
            Array.isArray(element.value)
              ? element.value[0]
              : typeof element.value === 'number'
              ? element.value.toString()
              : element.value
          }
          {...rest}
          onChange={element.onChange ?? (() => {})}
        />
      );
    case 'horizontalInputPair':
      const typedElement = element as HorizontalInputPairProps;
      return (
        <div
          className={styles['step-form-horizontal-pair-container']}
          key={index}
        >
          {typedElement.elements.map((el, i) => renderElement(el, i, rest))}
        </div>
      );
    case 'horizontalDivider':
      return (
        <div className={styles['step-form-horizontal-divider']} key={index} />
      );
    case 'sectionHeading':
      let style = styles['step-form-section-heading'];
      if ('align' in element && element.align === 'singleInput') {
        style = styles['step-form-section-heading-single-input'];
      }
      return (
        <div key={index} className={style}>
          <div>
            {'label' in element && element?.label ? element.label : 'No Label'}
            {'tooltip' in element && element?.tooltip ? (
              <Tooltip label={element.tooltip}>
                <InfoIcon />
              </Tooltip>
            ) : null}
          </div>
        </div>
      );
    case 'explanatoryParagraphs':
      return (
        <div className={styles['step-form-explanatory-paragraphs']} key={index}>
          {(element as ExplanatoryParagraphProps).paragraphTexts.map(
            (text, i) => (
              <p key={i}>{text}</p>
            ),
          )}
        </div>
      );
    case 'checkbox':
      return (
        <FormikCheckbox key={index} {...(element as FormikCheckboxProps)} />
      );
    case 'staticText':
      return (
        <StaticTextField element={element as StaticInputProps} key={index} />
      );
    case 'summaryField':
      return (
        <div className={styles['step-form-summary-field']} key={index}>
          <div className={styles['step-form-summary-field__label']}>
            {element.label}:
          </div>
          <div className={styles['step-form-summary-field__value']}>
            {element.value}
          </div>
        </div>
      );
    default:
      return <FormElement {...element} direction="row" key={index} />;
  }
};

const StepForm = ({
  formElements,
  children,
  rightHalfElement,
  valuesCallback,
  ...rest
}: TrialStepFormProps) => {
  const { values, touched } = useFormikContext<Record<string, any>>();
  useEffect(() => {
    if (valuesCallback) {
      valuesCallback(values, touched);
    }
  }, [values, valuesCallback, touched]);
  return (
    <>
      <Form key={'step-form-actual-form'}>
        {formElements?.length
          ? formElements.map((element, index) =>
              renderElement(element, index, rest),
            )
          : null}
        {children}
      </Form>
      {rightHalfElement && rightHalfElement}
    </>
  );
};

export default StepForm;
