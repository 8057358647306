import { Link } from 'react-router-dom';
import { AuthFooterRedirectProps } from 'types/auth.type';
import styles from './authFooterRedirect.module.scss';

const AuthFooterRedirect = ({
  description,
  path,
  cta,
}: AuthFooterRedirectProps) => {
  return (
    <div className={styles['auth-footer-redirect']} data-testid="redirect">
      <span data-testid="redirect__description">{description}</span>
      <Link to={path} data-testid="redirect__link">
        {cta}
      </Link>
    </div>
  );
};

export default AuthFooterRedirect;
