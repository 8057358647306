import { StorageMethod } from 'types/global.type';

interface StorageHelpers {
  getStorageValue: <Value>(key: string, storage?: StorageMethod) => Value;
  addStorageValue: (key: string, value: any, storage?: StorageMethod) => void;
  removeStorageValue: (key: string, storage?: StorageMethod) => void;
}

export const getStorageValue = <Value>(
  key: string,
  storage?: StorageMethod,
): Value => {
  let value;

  if (storage === 'session') value = sessionStorage[key];
  else value = localStorage[key];

  if (value) return JSON.parse(value);
  return value;
};

export const addStorageValue = (
  key: string,
  value: any,
  storage?: StorageMethod,
): void => {
  const stringifiedValue = JSON.stringify(value);

  if (storage === 'session') sessionStorage[key] = stringifiedValue;
  else localStorage[key] = stringifiedValue;
};

const useStorage = (): StorageHelpers => {
  const removeStorageValue = (key: string, storage?: StorageMethod): void => {
    if (storage === 'session') sessionStorage.removeItem(key);
    else localStorage.removeItem(key);
  };

  return {
    getStorageValue,
    addStorageValue,
    removeStorageValue,
  };
};

export default useStorage;
