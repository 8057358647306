interface SpinnerProps {
  color?: string;
  size?: string;
}

//todo: fix this so the color is pulled from SCSS color vars, possibly using
//sass-extract
function Spinner({ color = '#000000de', size = '20px' }: SpinnerProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{
        margin: 'auto',
        background: 'none',
        display: 'block',
        shapeRendering: 'auto',
      }}
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      data-testid="spinner"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color === 'brand' ? '#007f66' : color}
        strokeWidth="10"
        r="40"
        strokeDasharray="188.49555921538757 64.83185307179586"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="0.7407407407407407s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}

export default Spinner;
