import { AuthFooterRedirectProps } from 'types/auth.type';
import AuthFooterRedirect from './subcomponents/AuthFooterRedirect';
import styles from './authFormWrapper.module.scss';
import { PrivacyPolicyLinks } from 'components/global/Footer';
import Button from 'components/global/Button';
import { Routes } from 'types/global.type';

interface AuthFormWrapperProps {
  title: string;
  footerRedirects?: AuthFooterRedirectProps[];
  children: React.ReactNode;
  mergePosition?: 'top' | 'bottom';
  context?: 'login' | 'others';
}

const AuthFormWrapper = ({
  title,
  footerRedirects = [],
  children,
  mergePosition,
  context,
}: AuthFormWrapperProps) => {
  return (
    <div
      className={`${styles['auth-form-wrapper']} ${
        mergePosition
          ? styles[`auth-form-wrapper--merged-${mergePosition}`]
          : ''
      }`}
      data-testid="form-wrapper"
    >
      <h1
        className={styles['auth-form-wrapper__header']}
        data-testid="form-wrapper__header"
      >
        {title}
      </h1>
      <div
        className={styles['auth-form-wrapper__form']}
        data-testid="form-wrapper__form"
      >
        {children}
        {context === 'login' && (
          <>
            <h1
              className={styles['auth-form-wrapper__header']}
              data-testid="form-wrapper__header"
            >
              Don't have an account yet?
            </h1>
            <Button
              action="link"
              variant="brand"
              useDefaultWidth
              style={{ width: '100%' }}
              to={Routes.signUp}
            >
              Create Account
            </Button>
          </>
        )}
      </div>
      <div
        className={styles['auth-form-wrapper__footer']}
        data-testid="form-wrapper__footer"
      >
        {footerRedirects.map((footerRedirect, index) => (
          <AuthFooterRedirect {...footerRedirect} key={index} />
        ))}

        <PrivacyPolicyLinks />
      </div>
    </div>
  );
};

export default AuthFormWrapper;
