import {
  BaseQueryFn,
  fetchBaseQuery,
  FetchArgs,
} from '@reduxjs/toolkit/dist/query';
import { RootState } from 'redux/store';
import { Routes } from 'types/global.type';
import { LoginResponse } from 'types/auth.type';
import { setAuthState } from 'redux/slices/auth';
import { addStorageValue } from 'hooks/useStorage';
import getErrorMessage from './helpers/getErrorMessage';
import getStorageMethod from './helpers/getStorageMethod';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const USER_IDS_DELETE_PAID_TRIALS =
  process.env.REACT_APP_USER_IDS_DELETE_PAID_TRIALS;
export const FEATURE_FLAG_ANALYSIS_UI =
  process.env.REACT_APP_FEATURE_FLAG_ANALYSIS_UI === 'true';

const getDefaultBaseQuery = (
  pathname?: string,
  useRefreshToken?: boolean,
  apiToUse?: 'design' | 'analysis',
): BaseQueryFn<string | FetchArgs> => {
  const apiBaseUrl =
    apiToUse === 'analysis'
      ? process.env.REACT_APP_ANALYSIS_API_BASE_URL
      : process.env.REACT_APP_API_BASE_URL;
  return fetchBaseQuery({
    baseUrl: `${apiBaseUrl}${pathname ? `/${pathname}` : ''}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth[
        useRefreshToken ? 'refreshToken' : 'token'
      ];

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  });
};

export const getBaseQuery = (
  pathname?: string,
  calledApi?: 'design' | 'analysis',
): BaseQueryFn => {
  return async (args, api, extraOptions) => {
    if (!calledApi) {
      calledApi = 'design';
    }
    const baseQuery = getDefaultBaseQuery(pathname, false, calledApi);

    let result = await baseQuery(args, api, extraOptions);

    if (result.error && getErrorMessage(result.error) === 'Token has expired') {
      const authState = (api.getState() as RootState).auth;

      const refreshResult = await getDefaultBaseQuery('', true)(
        {
          url: '/refresh',
          headers: { Authorization: `Bearer ${authState.refreshToken}` },
          method: 'POST',
        },
        api,
        extraOptions,
      );

      if (refreshResult.data) {
        const { access_token: token, refresh_token: refreshToken } =
          refreshResult.data as LoginResponse;

        const storage = getStorageMethod();
        addStorageValue('token', token, storage);
        addStorageValue('refreshToken', refreshToken, storage);

        api.dispatch(
          setAuthState({
            ...authState,
            token,
            refreshToken,
          }),
        );

        result = await baseQuery(args, api, extraOptions);
      } else {
        await baseQuery('/logout', api, extraOptions);

        localStorage.clear();
        sessionStorage.clear();
        window.location.href = Routes.login;
      }
    }

    return result;
  };
};
