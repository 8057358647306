import styles from './formErrorMessage.module.scss';

interface FormErrorMessageProps {
  error: React.ReactNode;
  variant?: 'default' | 'auth' | 'standalone';
  type?: 'error' | 'success';
  'data-testid'?: string;
}

const FormErrorMessage = ({
  error,
  variant = 'default',
  type = 'error',
  'data-testid': dataTestId,
}: FormErrorMessageProps) => {
  return error ? (
    <p
      className={`${styles['form-error-message']} ${
        styles['form-error-message--' + variant]
      } ${styles['form-error-message--' + type]}`}
      data-testid={dataTestId || type}
    >
      {error}
    </p>
  ) : null;
};

export default FormErrorMessage;
