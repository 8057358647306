import { useEffect } from 'react';
import { FormProps } from 'types/global.type';
import { Form, useFormikContext } from 'formik';
import Button from 'components/global/Button';
import FormElement from 'components/global/FormElement';
import FormErrorMessage from 'components/global/FormErrorMessage';

const ChangePasswordForm = ({ loading, error, successMsg }: FormProps) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (successMsg) resetForm();
  }, [successMsg, resetForm]);

  return (
    <Form data-testid="change-password-form">
      <FormErrorMessage error={error} variant="auth" />

      <FormElement
        name="old_password"
        type="password"
        variant="auth"
        label="Old Password"
      />
      <FormElement
        name="new_password"
        type="password"
        variant="auth"
        label="New Password"
      />
      <FormElement
        name="confirm_password"
        type="password"
        variant="auth"
        label="Confirm New Password"
      />

      <Button action="button" variant="brand" type="submit" isLoading={loading}>
        Update Password
      </Button>
    </Form>
  );
};

export default ChangePasswordForm;
