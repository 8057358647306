import SEO from 'components/global/SEO';
import SignUpContainer from 'containers/auth/SignUp';
import AuthFormWrapper from 'components/auth/AuthFormWrapper';
import { Routes } from 'types/global.type';
import { authFooterRedirects } from 'utils/auth';

const SignUpPage = () => {
  const { login } = authFooterRedirects;

  return (
    <>
      <SEO prefix="Sign Up" path={Routes.signUp} />
      <AuthFormWrapper
        title="Create your FarmTest Account"
        footerRedirects={[login]}
      >
        <SignUpContainer />
      </AuthFormWrapper>
    </>
  );
};

export default SignUpPage;
