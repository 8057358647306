import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  privacyPolicy: false,
  termsAndConditions: false,
};

export const privacyPolicySlice = createSlice({
  name: 'privacy',
  initialState,
  reducers: {
    updatePrivacyPolicy: (state, action: PayloadAction<boolean>) => {
      state.privacyPolicy = action.payload;
    },
    updateTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      state.termsAndConditions = action.payload;
    },
  },
});

export const { updatePrivacyPolicy, updateTermsAndConditions } =
  privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;
