import Button from 'components/global/Button';
import Checkbox from 'components/global/Checkbox';
import Modal from 'components/global/Modal';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ReactComponent as CloseIcon } from 'assets/vectors/close.svg';
import styles from './privacyPolicyModal.module.scss';
import {
  updatePrivacyPolicy,
  updateTermsAndConditions,
} from 'redux/slices/privacyPolicy';

interface PrivacyPolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
  continueAction: () => void;
  isRegister?: boolean;
}

const PrivacyPolicyModal = ({
  isOpen,
  onClose,
  continueAction,
  isRegister,
}: PrivacyPolicyModalProps) => {
  const { privacyPolicy, termsAndConditions } = useAppSelector(
    (state) => state.privacy,
  );
  const dispatch = useAppDispatch();

  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={onClose}
      className={styles['terms-modal']}
      data-testid="terms-modal"
    >
      <div className={styles['terms-modal__close-button-container']}>
        <Button
          action="button"
          variant="icon"
          onClick={onClose}
          className={styles['terms-modal__close-button']}
          data-testid="terms-modal__close-button"
        >
          <CloseIcon />
        </Button>
      </div>
      <p className={styles['terms-modal__description']}>
        You must accept our Terms and acknowledge our Privacy Policy to upload
        your data and use our services. Please review and provide your consent.
      </p>
      <div className={styles['terms-modal__checkboxes']}>
        <Checkbox
          label={
            <span>
              I have read and agree to FarmTest’s{' '}
              <a
                href="https://farmtest.ag/terms"
                target="_blank"
                rel="noopenner noreferrer"
              >
                Terms
              </a>
            </span>
          }
          id="terms"
          checked={termsAndConditions}
          onChange={() =>
            dispatch(updateTermsAndConditions(!termsAndConditions))
          }
        />
        <Checkbox
          label={
            <span>
              I have read and agree to FarmTest’s{' '}
              <a
                href="https://farmtest.ag/privacy-policy"
                target="_blank"
                rel="noopenner noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          }
          id="privacy-policy"
          checked={privacyPolicy}
          onChange={() => dispatch(updatePrivacyPolicy(!privacyPolicy))}
        />
      </div>

      <p className={styles['terms-modal__description']}>
        {isRegister
          ? 'You can demo our tools without creating an account or accepting our Terms or Privacy Policy by cancelling and instead pressing the Try FarmTest button, and then using the Demo Shapefiles.'
          : 'No consent is needed to demo our services. Just click off of this window and use the Demo File buttons.'}
      </p>

      <Button
        action="button"
        onClick={() => {
          onClose();
          continueAction();
        }}
        isDisabled={!privacyPolicy || !termsAndConditions}
        className={styles['terms-modal__continue-button']}
        data-testid="terms-modal__continue-button"
      >
        Continue
      </Button>
    </Modal>
  );
};

export default PrivacyPolicyModal;
