import FormAndImageStep from '../FormAndImageStep';
import { UploadAndPreviewShapefileStepProps } from '../../../types/stepFlow.type';
import FileUploadModal from '../../create-trial/FileUploadModal';
import { useState } from 'react';
import ShapefileImagePreview from '../../analyze-trial/ShapefileImagePreview';
import notYetProvided from '../../../assets/images/not-yet-provided.png';

const UploadAndPreviewShapefileStep = ({
  shapefileType,
  fieldResultId,
  imageUrl,
  currentShapefile,
  ...rest
}: UploadAndPreviewShapefileStepProps) => {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <FormAndImageStep
      {...rest}
      imagePreviewElement={
        <ShapefileImagePreview
          initialImageUrl={imageUrl || notYetProvided}
          useGCPFiles={false}
          files={files}
          fieldResultId={fieldResultId}
          shapefileType={shapefileType}
          previewType="raw"
          {...rest}
        />
      }
    >
      <FileUploadModal
        name={rest.uniqueKey}
        metaName={`${rest.uniqueKey}_meta`}
        title={shapefileType}
        fileDescription={`Select the ${shapefileType} shapefile.`}
        uploadAction={(files) => {
          setFiles(files);
        }}
      />
      {currentShapefile && files.length === 0 ? (
        <div>
          <p>Your existing shapefile for this {`${shapefileType}`} data is</p>
          <a
            href={currentShapefile.file_data?.signed_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {currentShapefile.filename}
          </a>
          .
          <p>
            Click <b>Select</b> to choose a new shapefile and replace it.
          </p>
        </div>
      ) : null}
      <div>
        <p>
          <b> To ensure efficient uploads, please note the following:</b>
        </p>
        <p>
          1. We have implemented a maximum file size limit of <b>50MB </b>
          per set of shapefile components.
        </p>
        <p>
          2. We recommend zipping your files before uploading to reduce size and
          ensure faster uploads.
        </p>
        <p>
          3. If you upload an unzipped file, it will be automatically zipped on
          our server to optimize processing.
        </p>
        <p>
          4. Files larger than 10MB may take a few minutes to process. Please be
          patient and do not refresh or close the page.
        </p>
      </div>
      {rest.children}
    </FormAndImageStep>
  );
};

export default UploadAndPreviewShapefileStep;
