import notYetProvided from '../../../assets/images/not-yet-provided.png';
import FormAndImageStep from '../../step-flow/FormAndImageStep';
import * as Yup from 'yup';
import {
  FormElementPropsType,
  RepsToRemoveStepProps,
} from '../../../types/stepFlow.type';
import {
  AsAppliedSubmitStructure,
  FieldData,
  YieldSubmitStructure,
} from '../../../types/analyzeTrial.type';
import ShapefileImagePreview from '../ShapefileImagePreview';
import { useEffect, useState } from 'react';

export const cleanRepsToRemove = (
  reps: string | string[] | undefined,
): string => {
  if (!reps) {
    return '';
  }
  if (Array.isArray(reps)) {
    return reps
      .map((rep) => rep.trim())
      .filter((rep) => rep !== '')
      .join(',');
  }
  return reps
    .split(',')
    .map((rep) => rep.trim())
    .filter((rep) => rep !== '')
    .join(',');
};

const RepsToRemoveStep = ({
  imageUrl,
  savedData,
  uploadedFiles,
  useGCPFiles,
  fieldResultId,
  shapefileType,
  trialType,
  inProgressExecution,
  ...rest
}: RepsToRemoveStepProps) => {
  const [doUpdate, setDoUpdate] = useState(false);
  const [hasDoneUpdate, setHasDoneUpdate] = useState(false);

  let typedInProgressExecution;

  if (shapefileType === 'as-applied') {
    typedInProgressExecution = inProgressExecution as AsAppliedSubmitStructure;
  } else if (shapefileType === 'yield') {
    typedInProgressExecution = inProgressExecution as YieldSubmitStructure;
  }

  const getRepsToRemoveInitialValues = ({
    savedData,
  }: {
    savedData: FieldData | undefined | null;
  }) => ({
    reps_to_remove: cleanRepsToRemove(savedData?.reps_to_remove),
  });

  const repsToRemoveValidationSchema = Yup.object().shape({
    reps_to_remove: Yup.string()
      .transform((value, originalValue) => {
        // Replace any sequence of spaces and/or commas with a single comma, as well as trailing commas
        if (typeof originalValue === 'string') {
          return cleanRepsToRemove(originalValue);
        }
        return originalValue;
      })
      .matches(
        /^(?:[^,\s]+,)*[^,\s]+$/,
        'Must be a comma separated string of replicate identifiers.',
      )
      .nullable(),
  });

  const repsToRemoveFooter = (
    <div>
      <p>Separate each deactivated replicate number with a comma.</p>
      <p>
        Note that any replicates removed due to issues with trial execution will
        necessarily be excluded from the yield data and vice versa, as without
        both datasets no analysis is possible for that rep.
      </p>
      <p>
        We will handle this for you - on this page, only list replicates that
        need to be removed due to issues in{' '}
        {shapefileType === 'as-applied'
          ? trialType === 'Planting'
            ? 'as-planted'
            : 'as-applied'
          : 'yield'}{' '}
        data.
      </p>
    </div>
  );

  const repsToRemoveFields: FormElementPropsType[] = [
    {
      element: 'sectionHeading',
      label: `List any replicates that need to be deactivated ${
        shapefileType !== 'yield' ? ' due to improper execution' : ''
      }`,
      tooltip:
        'Replicates are typically deactivated due to the wrong rate or product being applied, or due to  misalignment between the as-executed data and the prescription that is larger than the buffering of the ends and sides of the plots.',
    },
    {
      element: 'annotatedInput',
      label: 'Replicate numbers to deactivate:',
      name: `reps_to_remove`,
      footer: repsToRemoveFooter,
      value: '',
    },
  ];

  const repsToRemoveInitialValues = getRepsToRemoveInitialValues({
    savedData: savedData,
  });

  const handleUpdate = () => {
    setDoUpdate(false);
    setHasDoneUpdate(true);
  };

  useEffect(() => {
    // runs once, on the initial mount if scaled_with_plots_preview_image is not present
    // or if new files are uploaded
    if (
      (!savedData?.scaled_with_plots_preview_image?.signed_url ||
        uploadedFiles.length > 0) &&
      !hasDoneUpdate
    ) {
      setDoUpdate(true);
    }
  }, [
    hasDoneUpdate,
    savedData?.scaled_with_plots_preview_image?.signed_url,
    uploadedFiles.length,
  ]);

  return (
    <FormAndImageStep
      {...rest}
      initialValues={repsToRemoveInitialValues}
      validationSchema={repsToRemoveValidationSchema}
      formElements={repsToRemoveFields}
      imagePreviewElement={
        <ShapefileImagePreview
          initialImageUrl={imageUrl || notYetProvided}
          useGCPFiles={useGCPFiles}
          files={uploadedFiles}
          triggerUpdate={doUpdate}
          triggerUpdateCallback={handleUpdate}
          columnMap={typedInProgressExecution}
          fieldResultId={fieldResultId}
          shapefileType={shapefileType}
          previewType="scaled_with_plots"
          {...rest}
        />
      }
    >
      {rest.children}
    </FormAndImageStep>
  );
};

export default RepsToRemoveStep;
