const ablineValuesChanged = (
  values: Record<string, any>,
  trial: Record<string, any>,
) => {
  const get_abline_method_changed =
    values.get_abline_method !== trial.get_abline_method &&
    values.get_abline_method !== undefined;
  const abline_adjust_changed = values.abline_adjust !== trial.abline_adjust;
  const abline_heading_changed =
    values.abline_heading !== trial.abline_heading &&
    values.abline_heading !== '';
  const abline_rotation_changed =
    values.abline_rotation !== trial.abline_rotation;
  return (
    get_abline_method_changed ||
    abline_adjust_changed ||
    abline_heading_changed ||
    abline_rotation_changed
  );
};

export default ablineValuesChanged;
