import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CheckoutResponse,
  CreateTrialResponse,
  DownloadShapefilesResponse,
  PurchaseResponse,
} from 'types/createTrial.type';
import { DefaultAPIResponse } from 'types/global.type';
import {
  ABLinePreviewResponse,
  FieldsResponse,
  FieldTrialsResponse,
  LSDPercentageResponse,
  SingleTrialResponse,
} from 'types/workspace.type';
import { getBaseQuery } from 'utils/config';

const workspaceApi = createApi({
  reducerPath: 'workspaceApi',
  baseQuery: getBaseQuery('workspace'),
  endpoints: (builder) => ({
    fields: builder.query<FieldsResponse, void>({
      query: () => '/fields',
    }),
    fieldTrials: builder.query<FieldTrialsResponse, string>({
      query: (fieldId) => `/fields/${fieldId}/trials`,
    }),
    fieldTrial: builder.query<SingleTrialResponse, string>({
      query: (trialId) => `/trials/${trialId}`,
    }),
    downloadShapefiles: builder.query<DownloadShapefilesResponse, string>({
      query: (trialId) => `/trials/${trialId}/download`,
    }),
    deleteTrial: builder.mutation<DefaultAPIResponse, string>({
      query: (trialId) => ({
        url: `/trials/${trialId}`,
        method: 'DELETE',
      }),
    }),
    ablinePreview: builder.mutation<ABLinePreviewResponse, FormData>({
      query: (body) => ({
        url: '/trials/abline',
        body,
        method: 'POST',
      }),
    }),
    createTrial: builder.mutation<CreateTrialResponse, FormData>({
      query: (body) => ({
        url: '/trials/save',
        body,
        method: 'POST',
      }),
    }),
    partnerPurchase: builder.query<PurchaseResponse, FormData>({
      query: (body: FormData) => ({
        url: `/trials/checkout/partner`,
        body,
        method: 'POST',
      }),
    }),
    spendTrialCredit: builder.query<PurchaseResponse, FormData>({
      query: (body) => ({
        url: '/trials/checkout/trial_credit',
        body,
        method: 'POST',
      }),
    }),
    checkout: builder.query<
      CheckoutResponse,
      {
        type: 'stripe_single' | 'stripe_multiple';
        formData: FormData;
      }
    >({
      query: ({ type, formData }) => ({
        url: `/trials/checkout/${type}`,
        body: formData,
        method: 'POST',
      }),
    }),
    computeLsdPercentage: builder.mutation<LSDPercentageResponse, FormData>({
      query: (body) => ({
        url: '/trials/lsd',
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useFieldsQuery,
  useFieldTrialsQuery,
  useFieldTrialQuery,
  useLazyFieldTrialQuery,
  useLazyDownloadShapefilesQuery,
  useAblinePreviewMutation,
  useCreateTrialMutation,
  useDeleteTrialMutation,
  useLazyPartnerPurchaseQuery,
  useLazySpendTrialCreditQuery,
  useLazyCheckoutQuery,
  useComputeLsdPercentageMutation,
} = workspaceApi;

export default workspaceApi;
