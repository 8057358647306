import { useFormikContext } from 'formik';
import ReactSelect, { MultiValue, SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FormErrorMessage from '../FormErrorMessage';
import getOptions from 'utils/helpers/getOptions';
import getOption from 'utils/helpers/getOption';
import styles from './select.module.scss';
import { Option } from '../../../types/stepFlow.type';

interface SelectProps {
  options: Option[];
  name: string;
  label?: string;
  isRequired?: boolean;
  creatable?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  noOptionsMessage?: string;
  isMulti?: boolean;
}

const Select = ({
  name,
  label,
  isRequired,
  creatable,
  noOptionsMessage,
  ...rest
}: SelectProps) => {
  const { errors, touched, values, setFieldValue } =
    useFormikContext<Record<string, any>>();

  const props = {
    id: name,
    value: !values[name]
      ? undefined
      : Array.isArray(values[name])
      ? getOptions(values[name], rest.options)
      : getOption(values[name], undefined, rest.options),
    onChange: (newValue: SingleValue<Option> | MultiValue<Option>) => {
      let parsedValue: string | string[];

      if (Array.isArray(newValue)) {
        parsedValue = (newValue as MultiValue<Option>).map(
          (option) => option.value,
        );
      } else {
        parsedValue = (newValue as SingleValue<Option>)?.value || '';
      }

      setFieldValue(name, parsedValue);
    },
    noOptionsMessage: () => noOptionsMessage,
    styles: {
      menu: (provided: any) => ({
        ...provided,
        borderColor: '#bdbdbd',
      }),
      placeholder: (provided: any) => ({
        ...provided,
        color: '#999999',
      }),
      control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? '#20df7f' : '#bdbdbd',
        height: !rest.isMulti ? 40 : 'unset',
        transition: 'all 0.3s',
        cursor: 'pointer',
        boxShadow: 'none',
        ':hover': {
          borderColor: '#20df7f',
        },
      }),
      singleValue: (provided: any) => ({
        ...provided,
        height: 16,
        fontSize: 15,
      }),
      multiValue: (provided: any) => ({
        ...provided,
        color: '#000000de',
        fontSize: 16,
        padding: '4px 2px',
        margin: '3px 4px',
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        color: '#000000de',
        fontWeight: 500,
        cursor: 'pointer',
      }),
    },
    ...rest,
  };

  return (
    <div className={styles['select']} data-testid={name}>
      {label ? (
        <label htmlFor={name} className={styles['select__label']}>
          {label}
        </label>
      ) : null}

      {/* @ts-ignore */}
      {creatable ? <CreatableSelect {...props} /> : <ReactSelect {...props} />}

      <FormErrorMessage
        error={errors[name] && touched[name] ? errors[name] : ''}
        data-testid={`${name}-error`}
      />
    </div>
  );
};

export default Select;
