import SEO from 'components/global/SEO';
import HomeContainer from 'containers/workspace/Home';
import { Routes } from 'types/global.type';

const HomePage = () => {
  return (
    <>
      <SEO prefix="Home" path={Routes.home} />
      <HomeContainer />
    </>
  );
};

export default HomePage;
