import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { ReactComponent as ArrowLeft } from 'assets/vectors/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'assets/vectors/arrow-right.svg';
import Button from 'components/global/Button';
import styles from './stepPagination.module.scss';
import { StepContentProps } from '../../index';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Step } from '../../../../../types/stepFlow.type';

interface PaginationProps extends StepContentProps {
  submitAndUpdateStep: (cb?: () => void) => void;
  updateStateFunction: ActionCreatorWithPayload<Step, any>;
  stateSelector: (state: any) => any;
}

const StepPagination = ({
  activeIndex,
  setActiveIndex,
  submitAndUpdateStep,
  updateStateFunction,
  stateSelector,
  flowSteps,
}: PaginationProps) => {
  const { completedSteps } = useAppSelector(stateSelector);
  const dispatch = useAppDispatch();

  const goToNextStep = useCallback(
    (event: CustomEvent<Record<string, any>>) => {
      dispatch(
        updateStateFunction({
          index: activeIndex,
          data: event.detail,
        }),
      );
      setActiveIndex(activeIndex + 1);
    },
    [activeIndex, dispatch, updateStateFunction, setActiveIndex],
  );

  useEffect(() => {
    window.addEventListener<any>('next-step-triggered', goToNextStep);

    return () => {
      window.removeEventListener<any>('next-step-triggered', goToNextStep);
    };
  }, [goToNextStep]);

  const showBackButton = activeIndex > 0;
  const showNextButton = activeIndex < flowSteps.length - 1;

  const goBack = () => {
    setActiveIndex(activeIndex - 1);
  };

  const goForward = () => {
    submitAndUpdateStep(() => setActiveIndex(activeIndex + 1));
  };

  const goToStep = (index: number) => {
    if (completedSteps[activeIndex]) {
      submitAndUpdateStep();
    }

    const isCurrentStep = index === Object.keys(completedSteps).length;

    if (completedSteps[index] || isCurrentStep) {
      setActiveIndex(index);
    }
  };

  return (
    <div className={styles['step-pagination']} data-testid="step-pagination">
      {showBackButton ? (
        <Button
          action="button"
          variant="ghost"
          leftIcon={<ArrowLeft />}
          onClick={goBack}
          data-testid="back-button"
        >
          Back
        </Button>
      ) : (
        <span></span>
      )}

      <div className={styles['step-pagination__step-button-list']}>
        {flowSteps.map((_, index) => (
          <button
            className={`${styles['step-pagination__step-button']} ${
              completedSteps[index]
                ? styles['step-pagination__step-button--active']
                : ''
            } ${
              index === activeIndex
                ? styles['step-pagination__step-button--current']
                : ''
            }`}
            onClick={() => goToStep(index)}
            key={index}
            data-testid="step-button"
          />
        ))}
      </div>

      {showNextButton ? (
        <Button
          action="button"
          variant="ghost"
          rightIcon={<ArrowRight />}
          onClick={goForward}
          data-testid="next-button"
        >
          Next
        </Button>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default StepPagination;
