import { AxiosError } from 'axios';
import { APIErrorResponse } from 'types/global.type';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';

const getErrorMessage = (
  err:
    | AxiosError
    | FetchBaseQueryError
    | SerializedError
    | PayloadAction<APIErrorResponse>
    | void
    | unknown,
  type?: 'default' | 'middleware',
): string => {
  if (!err) return '';

  switch (type) {
    case 'middleware':
      const error = err as PayloadAction<
        APIErrorResponse,
        string,
        never,
        Error
      >;

      if (error.payload) return (error.payload?.msg as string) || '';
      else return error.error.message;

    case 'default':
    default:
      if ((err as FetchBaseQueryError).data || (err as AxiosError).response) {
        return (
          (
            ((err as FetchBaseQueryError)?.data as APIErrorResponse)?.msg as {
              error: string;
            }
          )?.error ||
          (
            ((err as FetchBaseQueryError)?.data as APIErrorResponse)?.error as {
              error: string;
            }
          )?.error ||
          ((err as FetchBaseQueryError)?.data as APIErrorResponse)?.msg ||
          ((err as FetchBaseQueryError)?.data as APIErrorResponse)?.error ||
          (err as AxiosError).response?.data?.msg?.error ||
          (err as AxiosError).response?.data?.error?.error ||
          (err as AxiosError).response?.data?.msg ||
          (err as AxiosError).response?.data?.error
        );
      } else {
        const errorMessage =
          (err as SerializedError).message ||
          (err as any).error ||
          'An unexpected error occured. Please try again later';
        // this handles error returned when api calls fail due to network issues even though the user
        // might be connected to an internet source
        const chromiumError = 'Failed to fetch';
        const webkitError = 'Load failed';
        const geckoError = 'NetworkError';
        const fetchErrors = [chromiumError, webkitError, geckoError];
        if (fetchErrors.some((error) => errorMessage.includes(error))) {
          return 'A network error occured while trying to fetch the resource. Please check your connection and try again.';
        }
        return errorMessage;
      }
  }
};

export default getErrorMessage;
