import { Routes } from 'types/global.type';
import { API_BASE_URL } from 'utils/config';
import { SocialLoginType } from 'types/auth.type';
import styles from './socialLink.module.scss';

interface SocialLinkProps {
  type: SocialLoginType;
}

const SocialLink = ({ type }: SocialLinkProps) => {
  const redirectUrl = window.location.origin + Routes.login;
  const url = `${API_BASE_URL}/${type.toLowerCase()}-auth?redirect_url=${redirectUrl}`;

  return (
    <a
      href={url}
      className={`${styles['social-btn']} ${styles[type.toLowerCase()]}`}
      data-testid="social-link"
    >
      Continue with {type}
    </a>
  );
};

export default SocialLink;
