import { Routes } from 'types/global.type';
import { authFooterRedirects } from 'utils/auth';
import SEO from 'components/global/SEO';
import LoginContainer from 'containers/auth/Login';
import AuthFormWrapper from 'components/auth/AuthFormWrapper';

const LoginPage = () => {
  const { requestVerification } = authFooterRedirects;

  return (
    <>
      <SEO prefix="Login" path={Routes.login} />
      <AuthFormWrapper
        title="Login to FarmTest"
        footerRedirects={[requestVerification]}
        context="login"
      >
        <LoginContainer />
      </AuthFormWrapper>
    </>
  );
};

export default LoginPage;
