import { RootState } from 'redux/store';
import { useCallback, useEffect, useState } from 'react';
import { Routes } from 'types/global.type';
import { API_BASE_URL } from 'utils/config';
import { LoginResponse } from 'types/auth.type';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import useAxiosWithRedux, { NetworkRequestType } from 'use-axios-with-redux';
import useStorage from './useStorage';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import getStorageMethod from 'utils/helpers/getStorageMethod';

export interface ReduxConfigType {
  action?: string;
  callback?: (state: RootState) => void;
}

const useNetworkRequest = <RequestDataType, ResponseDataType>(
  path: string,
  config?: AxiosRequestConfig,
  reduxConfig?: ReduxConfigType,
): NetworkRequestType<RequestDataType, ResponseDataType> => {
  const [data, setData] = useState<Record<string, any>>({});
  const [refreshTokenSuccess, setRefreshTokenSuccess] =
    useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { getStorageValue, addStorageValue } = useStorage();

  const storage = getStorageMethod();
  const token = getStorageValue<string>('token', storage);
  const refreshToken = getStorageValue<string>('refreshToken', storage);

  const refreshAccessToken = (
    refreshToken: string,
    updateTokens: (token: string, refreshToken: string) => void,
  ) => {
    axios
      .post(API_BASE_URL + '/refresh', null, {
        headers: { Authorization: `Bearer ${refreshToken}` },
      })
      .then((res: AxiosResponse<LoginResponse>) => {
        const {
          data: { access_token, refresh_token },
        } = res;

        updateTokens(access_token, refresh_token);
      })
      .catch(() => {
        axios.post(API_BASE_URL + '/logout').then(() => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = Routes.login;
        });
      });
  };

  const axiosWithRedux = useAxiosWithRedux<RequestDataType, ResponseDataType>(
    API_BASE_URL + path,
    {
      ...(reduxConfig || {}),
      useSelector: useAppSelector,
      appDispatch: useAppDispatch(),
    },
    {
      ...config,
      headers: {
        ...config?.headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    },
  );

  const [{ error: axiosError }, dispatch] = axiosWithRedux;
  // this redefines itself often and can cause infinite renders. The hook this
  // comes from is a tiny project on github that hasn't been updated in 2 years.
  // TODO: get rid of it. do this right. do we even need axios?

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stableDispatch = useCallback(dispatch, []);

  if (!error && axiosError) setError(getErrorMessage(axiosError));

  useEffect(() => {
    if (refreshTokenSuccess) {
      if (!data?.method || data.method === 'GET') {
        dispatch(data.body, data.params);
      }

      setRefreshTokenSuccess(false);
    }
  }, [refreshTokenSuccess, data, dispatch]);

  useEffect(() => {
    const updateTokens = (token: string, refreshToken: string) => {
      addStorageValue('token', token, storage);
      addStorageValue('refreshToken', refreshToken, storage);
      setRefreshTokenSuccess(true);
    };

    if (error === 'Token has expired') {
      refreshAccessToken(refreshToken, updateTokens);
      setError('');
    }
  }, [error, refreshToken, addStorageValue, storage]);

  if (error === 'Token has expired') {
    axiosWithRedux[0].error = null;
  }
  axiosWithRedux[1] = useCallback(
    (body?: RequestDataType, params?: RequestDataType) => {
      setData({ body, params, method: config?.method });
      stableDispatch(body, params);
    },
    [config?.method, setData, stableDispatch],
  );

  return axiosWithRedux;
};

export default useNetworkRequest;
