import { useEffect, useRef, useState } from 'react';
import styles from './menu.module.scss';

interface MenuProps {
  /** Button component for opening the menu */
  button: React.ReactNode;

  isOpen: boolean;
  className?: string;
  onClose: () => void;
  children: React.ReactNode;
}

const Menu = ({
  children,
  isOpen,
  button,
  className = '',
  onClose,
}: MenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isMirrored, setIsMirrored] = useState<boolean>(false);

  useEffect(() => {
    if (menuRef.current) {
      const rect = menuRef.current.getBoundingClientRect();

      if (rect.left + 200 > window.innerWidth) setIsMirrored(true);
    }
  }, [menuRef]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node))
        onClose();
    };

    const closeOnEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', closeOnEscape);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', closeOnEscape);
    };
  }, [menuRef, onClose]);

  return (
    <div className={className} ref={menuRef}>
      <div
        className={`${styles['menu__button-container']} ${
          isOpen ? styles['menu__button-container--active'] : ''
        }`}
        data-testid="menu__button-container"
      >
        {button}
      </div>
      <div
        className={`${styles['menu']} ${isOpen ? styles['menu--visible'] : ''}`}
        data-testid="menu"
        onClick={onClose}
      >
        <div
          className={styles['menu__overlay']}
          data-testid="menu__overlay"
        ></div>
        <div
          className={`${styles['menu__content']} ${
            isMirrored ? styles['menu__content--mirrored'] : ''
          }`}
          data-testid="menu__content"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Menu;
