const isEqual = (
  filesA?: File[] | string,
  filesB?: File[] | string,
): boolean => {
  // return true if both are undefined
  if (filesA === undefined && filesB === undefined) {
    return true;
  }

  // If one is undefined but not the other, return false
  if (filesA === undefined || filesB === undefined) {
    return false;
  }

  // we sometimes use "demo" instead of a file array, so we need to handle that case.
  if (typeof filesA === 'string' && typeof filesB === 'string') {
    return filesA === filesB;
  }

  if (typeof filesA === 'string' && Array.isArray(filesB)) {
    return false;
  }

  if (Array.isArray(filesA) && typeof filesB === 'string') {
    return false;
  }

  if (Array.isArray(filesA) && Array.isArray(filesB)) {
    return isEqualFiles(filesA, filesB);
  }
  return false;
};

const isEqualFiles = (filesA: File[], filesB: File[]): boolean => {
  // Check if both file arrays are of the same length
  if (filesA.length !== filesB.length) {
    return false;
  }

  // Sort files by a consistent property to ensure order
  const sortedFilesA = [...filesA].sort((a, b) => a.name.localeCompare(b.name));
  const sortedFilesB = [...filesB].sort((a, b) => a.name.localeCompare(b.name));

  // Compare each file in the sorted arrays
  return sortedFilesA.every((fileA, index) => {
    const fileB = sortedFilesB[index];
    return (
      fileA.name === fileB.name &&
      fileA.size === fileB.size &&
      fileA.type === fileB.type &&
      fileA.lastModified === fileB.lastModified
    );
  });
};

export default isEqual;
