import { createPortal } from 'react-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './tooltip.module.scss';

interface TooltipProps {
  label?: React.ReactNode;
  children: React.ReactNode;
}

const Tooltip = ({ label, children }: TooltipProps): JSX.Element | null => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: 0,
    left: 0,
    marginLeft: -100,
  });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipLabelRef = useRef<HTMLSpanElement>(null);

  const updateTooltipPosition = useCallback(() => {
    if (tooltipRef.current) {
      let marginLeft = -100;
      let { x, y, width } = tooltipRef.current.getBoundingClientRect();

      if (tooltipLabelRef.current) {
        let { height, width } = tooltipLabelRef.current.getBoundingClientRect();

        marginLeft = -(width / 2);
        y -= height + 12;
      }

      setTooltipPosition({ left: x + width / 2, top: y, marginLeft });
    }
  }, []);

  useEffect(() => {
    updateTooltipPosition();
  }, [updateTooltipPosition]);

  useEffect(() => {
    updateTooltipPosition();
    window.addEventListener('resize', updateTooltipPosition);

    return () => {
      window.removeEventListener('resize', updateTooltipPosition);
    };
  }, [updateTooltipPosition]);

  const tooltipsContainer = document.getElementById('tooltips-container');

  if (!tooltipsContainer) return null;
  if (!label) return <>{children}</>;
  return (
    <div
      className={styles['tooltip']}
      onMouseEnter={() => {
        if (!tooltipVisible) setTooltipVisible(true);
      }}
      onMouseLeave={() => {
        if (tooltipVisible) setTooltipVisible(false);
      }}
      ref={tooltipRef}
      data-testid="tooltip"
    >
      {children}

      {createPortal(
        <span
          className={styles['tooltip__label']}
          style={{
            ...tooltipPosition,
            visibility: tooltipVisible ? 'visible' : 'hidden',
          }}
          ref={tooltipLabelRef}
          data-testid="tooltip__label"
        >
          {label}
        </span>,
        tooltipsContainer,
      )}
    </div>
  );
};

export default Tooltip;
