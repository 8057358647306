import FormElement from 'components/global/FormElement';
import { useDebouncedCallback } from 'use-debounce';

interface ABLineAdjustmentOptionsProps {
  generatePreviewIfValid: (name: string, value?: string) => void;
}

const ABLineAdjustmentOptions = ({
  generatePreviewIfValid,
}: ABLineAdjustmentOptionsProps) => {
  return (
    <div>
      <h2>Adjust AB line (optional)</h2>
      <FormElement
        name="abline_rotation"
        label="Rotate AB line"
        direction="row"
        rightElement="°"
        onChangeCallback={useDebouncedCallback(
          (event: { target: { value: string | undefined } }) => {
            if (event.target.value !== '') {
              generatePreviewIfValid('abline_rotation', event.target.value);
            }
          },
          750,
        )}
      />
      <FormElement
        name="abline_adjust"
        label="Translate AB line"
        direction="row"
        rightElement="ft"
        onChangeCallback={useDebouncedCallback(
          (event: { target: { value: string | undefined } }) => {
            if (event.target.value !== '') {
              generatePreviewIfValid('abline_adjust', event.target.value);
            }
          },
          750,
        )}
      />
    </div>
  );
};

export default ABLineAdjustmentOptions;
