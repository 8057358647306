import AuthFormWrapper from 'components/auth/AuthFormWrapper';
import SEO from 'components/global/SEO';
import ForgotPasswordContainer from 'containers/auth/ForgotPassword';
import { Routes } from 'types/global.type';
import { authFooterRedirects } from 'utils/auth';

const ForgotPasswordPage = () => {
  const { login, signUp, requestVerification } = authFooterRedirects;

  return (
    <>
      <SEO prefix="Forgot Password" path={Routes.forgotPassword} />
      <AuthFormWrapper
        title="Recover Password"
        footerRedirects={[login, signUp, requestVerification]}
      >
        <ForgotPasswordContainer />
      </AuthFormWrapper>
    </>
  );
};

export default ForgotPasswordPage;
