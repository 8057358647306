import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { DefaultAPIResponse } from 'types/global.type';
import { ReactComponent as CloseIcon } from 'assets/vectors/close.svg';
import useAuth from 'hooks/useAuth';
import useStorage from 'hooks/useStorage';
import Modal from 'components/global/Modal';
import Button from 'components/global/Button';
import FileChooser from './subcomponents/FileChooser';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getStorageMethod from 'utils/helpers/getStorageMethod';
import getFileExtension from 'utils/helpers/getFileExtension';
import UploadedFileList from './subcomponents/UploadedFileList';
import PrivacyPolicyModal from 'components/create-trial/PrivacyPolicyModal';
import FileUploadModalButton from './subcomponents/FileUploadModalButton';
import styles from './fileUploadModal.module.scss';

interface FileUploadModalProps {
  name: string;
  metaName: string;
  title: string;
  fileDescription: string;
  isDisabled?: boolean;
  uploadAction?: (files: File[]) => void;
}

const FileUploadModal = ({
  name,
  metaName,
  title,
  fileDescription,
  isDisabled,
  uploadAction,
}: FileUploadModalProps) => {
  const [uploadEnabled, setUploadEnabled] = useState<boolean>(false);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState<boolean>(false);
  const { user, isLoggedIn } = useAuth();
  const { addStorageValue } = useStorage();
  const { privacyPolicy, termsAndConditions } = useAppSelector(
    (state) => state.privacy,
  );
  const { values, errors, touched } = useFormikContext<Record<string, any>>();
  const [foregroundError, setForegroundError] = useState<string | null>('');
  const [{ data: termsData }, acceptTerms] = useNetworkRequest<
    null,
    DefaultAPIResponse
  >('/accept-terms');

  useEffect(() => {
    if (termsData) {
      //update user object in storage
      addStorageValue(
        'user',
        { ...user, terms_accepted: true },
        getStorageMethod(),
      );
    }
  }, [termsData, user, addStorageValue]);

  const files = useMemo(() => {
    return (values?.[name] as File[]) || [];
  }, [values, name]);

  useEffect(() => {
    if (files.length === 0) {
      setUploadEnabled(false);
      return;
    }

    if (files.toString() === 'demo') {
      setUploadEnabled(false);
      return;
    }

    // Calculate total size of all files in bytes
    const totalSize = files.reduce((total, file) => total + file.size, 0);
    // Check if total size exceeds 50MB
    if (
      totalSize > 50 * 1024 * 1024 &&
      ['as-applied', 'yield', 'as-planted'].includes(title)
    ) {
      setUploadEnabled(false);
      setForegroundError('Total file size exceeds 50MB');
      return;
    } else {
      setForegroundError('');
    }

    const fileExtensions = files.map((file) => getFileExtension(file.name));
    const hasZipFileOnly =
      fileExtensions.length === 1 && fileExtensions[0] === 'zip';

    if (hasZipFileOnly) {
      setUploadEnabled(true);
      return;
    }

    const requiredExtensions = ['dbf', 'shx', 'shp'];
    const hasRequiredFiles = requiredExtensions.every((ext) =>
      fileExtensions.includes(ext),
    );

    setUploadEnabled(hasRequiredFiles);
  }, [files, title]);

  const openUploadModal = () => {
    if (user?.terms_accepted || (privacyPolicy && termsAndConditions)) {
      setUploadModalOpen(true);
    } else {
      setPrivacyModalOpen(true);
    }
  };
  return (
    <>
      <FileUploadModalButton
        description={fileDescription}
        name={name}
        metaName={metaName}
        files={files}
        filesTitle={title}
        error={errors[name] && touched[name] ? (errors[name] as string) : ''}
        isDisabled={Boolean(isDisabled)}
        openUploadModal={openUploadModal}
      />

      <Modal
        isOpen={uploadModalOpen}
        onClickOutside={() => setUploadModalOpen(false)}
        className={styles['file-upload']}
        data-testid="file-upload-modal"
      >
        <div className={styles['file-upload__header']}>
          <h1>Upload Files</h1>
          <Button
            action="button"
            variant="icon"
            onClick={() => setUploadModalOpen(false)}
            data-testid="close-button"
          >
            <CloseIcon />
          </Button>
        </div>

        <div className={styles['file-upload__content']}>
          <div className={styles['file-upload__description']}>
            <h2 className={styles['file-upload__description__header']}>
              {`Upload ${title} shapefiles`}
            </h2>
            <p className={styles['file-upload__description__file-types']}>
              Acceptable file types: .dbf, .shp, .shx, .cpg, .prj, .zip
            </p>
            {['as-applied', 'yield', 'as-planted'].includes(title) && (
              <p className={styles['file-upload__description__file-types']}>
                Maximum file size for all shapefiles: 50MB
              </p>
            )}
            {foregroundError && (
              <p className={styles['file-upload__description__error']}>
                {foregroundError}
              </p>
            )}
          </div>

          <FileChooser name={name} />

          <UploadedFileList name={name} />
        </div>
        <div className={styles['file-upload__footer']}>
          <Button
            action="button"
            variant="transparent"
            onClick={() => setUploadModalOpen(false)}
            data-testid="cancel-button"
          >
            Cancel
          </Button>
          <Button
            action="button"
            className={styles['file-upload__footer__upload-button']}
            isDisabled={!uploadEnabled}
            onClick={() => {
              setUploadModalOpen(false);

              if (uploadAction) uploadAction(files);
            }}
            data-testid="modal-upload-button"
          >
            UPLOAD
          </Button>
        </div>
      </Modal>

      <PrivacyPolicyModal
        isOpen={privacyModalOpen}
        continueAction={() => {
          setUploadModalOpen(true);
          if (isLoggedIn) acceptTerms();
        }}
        onClose={() => setPrivacyModalOpen(false)}
      />
    </>
  );
};

export default FileUploadModal;
