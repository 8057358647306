import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styles from './modal.module.scss';

interface ModalProps {
  isOpen: boolean;
  onClickOutside: () => void;
  children: React.ReactNode;
  containerClass?: string;
  className?: string;
  'data-testid'?: string;
}

const Modal = ({
  isOpen,
  onClickOutside,
  containerClass = '',
  className = '',
  children,
  ...rest
}: ModalProps): null | JSX.Element => {
  const modalsContainer = document.getElementById('modals-container');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        (ref?.current && !ref?.current.contains(event.target as Node)) ||
        ref?.current?.isEqualNode(event.target as Node)
      ) {
        onClickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);

  if (!modalsContainer || !isOpen) return null;

  return createPortal(
    <div
      ref={ref}
      className={`${styles['modal']} ${containerClass}`}
      id="modal"
      data-testid={rest['data-testid'] || 'modal'}
    >
      <div className={className}>{children}</div>
    </div>,
    modalsContainer,
  );
};

export default Modal;
