import AuthFormWrapper from 'components/auth/AuthFormWrapper';
import SEO from 'components/global/SEO';
import ResetPasswordContainer from 'containers/auth/ResetPassword';
import { Routes } from 'types/global.type';
import { authFooterRedirects } from 'utils/auth';

const ResetPasswordPage = () => {
  const { login, signUp, requestVerification } = authFooterRedirects;

  return (
    <>
      <SEO prefix="Reset Password" path={Routes.resetPassword} />
      <AuthFormWrapper
        title="Reset Password"
        footerRedirects={[login, signUp, requestVerification]}
      >
        <ResetPasswordContainer />
      </AuthFormWrapper>
    </>
  );
};

export default ResetPasswordPage;
