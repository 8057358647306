import { useEffect } from 'react';
import { FormProps } from 'types/global.type';
import { Form, useFormikContext } from 'formik';
import Button from 'components/global/Button';
import FormElement from 'components/global/FormElement';
import FormErrorMessage from 'components/global/FormErrorMessage';

const ForgotPasswordForm = ({ loading, error, successMsg }: FormProps) => {
  const { resetForm } = useFormikContext();

  useEffect(() => {
    if (successMsg) resetForm();
  }, [successMsg, resetForm]);

  return successMsg ? (
    <FormErrorMessage error={successMsg} variant="standalone" type="success" />
  ) : (
    <Form data-testid="forgot-password-form">
      <FormErrorMessage error={error} variant="auth" />

      <FormElement
        name="email"
        type="email"
        variant="auth"
        placeholder="Email address"
      />

      <Button action="button" variant="brand" type="submit" isLoading={loading}>
        Send
      </Button>
    </Form>
  );
};

export default ForgotPasswordForm;
