import { FormProps } from 'types/global.type';
import { Form, useFormikContext } from 'formik';
import { UpdateProfileRequest } from 'types/auth.type';
import useAuth from 'hooks/useAuth';
import Button from 'components/global/Button';
import FormElement from 'components/global/FormElement';
import FormErrorMessage from 'components/global/FormErrorMessage';

const UpdateProfileForm = ({ loading, error }: FormProps) => {
  const { user } = useAuth();
  const {
    values: { first_name, last_name },
  } = useFormikContext<UpdateProfileRequest>();

  const isButtonDisabled =
    (!first_name && !last_name) ||
    (user?.first_name === first_name && user?.last_name === last_name);

  return (
    <Form data-testid="update-profile-form">
      <FormErrorMessage error={error} variant="auth" />

      <FormElement
        name="first_name"
        variant="auth"
        label="First Name (optional)"
      />
      <FormElement
        name="last_name"
        variant="auth"
        label="Last Name (optional)"
      />

      <Button
        action="button"
        variant="brand"
        type="submit"
        isLoading={loading}
        isDisabled={isButtonDisabled}
      >
        Update Account
      </Button>
    </Form>
  );
};

export default UpdateProfileForm;
