import { useState } from 'react';
import { Routes } from 'types/global.type';
import { drawerNavLinks } from 'utils/global';
import { ReactComponent as CloseIcon } from 'assets/vectors/close-green.svg';
import { ReactComponent as HamburgerIcon } from 'assets/vectors/hamburger.svg';
import Logo from 'assets/images/logo.png';
import Drawer from 'react-modern-drawer';
import Button from 'components/global/Button';
import DrawerNavLink from '../DrawerNavLink';
import styles from './drawerNav.module.scss';
import headerStyles from '../../header.module.scss';
import 'react-modern-drawer/dist/index.css';

const DrawerNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <>
      <Button
        action="button"
        variant="icon"
        onClick={toggleDrawer}
        data-testid="nav-menu-button"
        className={styles['drawer-nav__hamburger']}
      >
        <HamburgerIcon />
      </Button>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        size={350}
        overlayColor="transparent"
        className={styles['drawer-nav']}
      >
        <div className={styles['drawer-nav__logo-container']}>
          <img
            alt="FarmTest Logo"
            src={Logo}
            className={headerStyles['header__logo']}
          />
        </div>
        <Button
          action="button"
          variant="icon"
          onClick={toggleDrawer}
          data-testid="close-button"
        >
          <CloseIcon className={styles['drawer-nav__close-icon']} />
        </Button>

        <div className={styles['drawer-nav__links']}>
          {drawerNavLinks.map((navLink, index) => (
            <DrawerNavLink
              {...navLink}
              closeDrawer={toggleDrawer}
              key={index}
            />
          ))}
        </div>

        <div className={styles['drawer-nav__footer']}>
          <Button
            action="link"
            variant="brand"
            to={Routes.signUp}
            onClick={toggleDrawer}
            className={styles['drawer-nav__try-farmtest']}
          >
            TRY FARMTEST
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerNav;
