import { Formik } from 'formik';
import { testAreasSchema } from 'validators/createTrialSchemas';
import TestAreasForm from 'components/create-trial/TestAreasForm';

import { StepProps } from '../../types/stepFlow.type';

const TestAreasContainer = ({ children }: StepProps) => {
  return (
    <Formik
      initialValues={{
        trial_type: 'plot',
        abline_alignment: 'center',
        length_ft: 300,
        width_ft: 120,
        buffer_ft: 120,
        apply_trial_buffer_to_field_boundary: true,
      }}
      onSubmit={() => {}}
      validationSchema={testAreasSchema}
      validateOnMount
    >
      <>
        <TestAreasForm />
        {children}
      </>
    </Formik>
  );
};

export default TestAreasContainer;
