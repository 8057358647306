import AuthFormWrapper from 'components/auth/AuthFormWrapper';
import SEO from 'components/global/SEO';
import VerifyAccountContainer from 'containers/auth/VerifyAccount';
import { Routes } from 'types/global.type';
import { authFooterRedirects } from 'utils/auth';

const VerifyAccountPage = () => {
  const { forgotPassword, login, signUp } = authFooterRedirects;

  return (
    <>
      <SEO prefix="Verify Account" path={Routes.verifyAccount} />
      <AuthFormWrapper
        title="Request Email Verification"
        footerRedirects={[forgotPassword, login, signUp]}
      >
        <VerifyAccountContainer />
      </AuthFormWrapper>
    </>
  );
};

export default VerifyAccountPage;
