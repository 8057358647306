import Button from '../Button';
import Modal from '../Modal';
import styles from './confirmModal.module.scss';

interface ConfirmModalProps {
  isOpen: boolean;
  isLoading: boolean;
  heading: string;
  description: string;
  confirmCta: string;
  closeModal: () => void;
  confirmAction: () => void;
  error: string | null;
}

const ConfirmModal = ({
  isOpen,
  isLoading,
  heading,
  description,
  confirmCta,
  closeModal,
  confirmAction,
  error,
}: ConfirmModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={closeModal}
      className={styles['confirm-modal']}
      data-testid="confirm-modal"
    >
      <h1
        className={styles['confirm-modal__heading']}
        data-testid="confirm-modal__heading"
      >
        {heading}
      </h1>

      <p
        className={styles['confirm-modal__description']}
        data-testid="confirm-modal__description"
      >
        {description}
      </p>
      <p>
        {error && (
          <span
            className={styles['confirm-modal__error']}
            data-testid="confirm-modal__error"
          >
            {error}
          </span>
        )}
      </p>
      <div
        className={styles['confirm-modal__footer']}
        data-testid="confirm-modal__footer"
      >
        <Button action="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          action="button"
          variant="brand"
          onClick={confirmAction}
          isLoading={isLoading}
          data-testid="confirm-button"
        >
          {confirmCta}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
