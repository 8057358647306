import { capitalizeFirstLetter } from './capitalizationUtils';
import { Option } from '../../types/stepFlow.type';

// this has overloaded definitions - I wanted to add named parameters to it for
// clarity when used in a list of stepFlow options, but it's used extensively
// throughout the codebase. This way we have three signatures, all of which work:
//
//   getOption({value: 'a', label: 'A', tooltip: 'tip' }) - preferred
//   getOption('a', 'A') - old style. tooltip cannot be used this way as a third
//      parameter would be interpreted as allOptions, and there's one usage of
//      the old style with 3 params, in Select, that seems dangerous to change.
//   getOption(['a', 'b', 'c']) - this is preexisting and is used for multiple
//      simple options in e.g. a select.
//
// if a label is provided, it will be used verbatim (allowing for deliberate
// lower case options, as in application units). if not, the value of the option
// will be capitalized and used as the label.

function getOption(
  value: string,
  label?: string,
  allOptions?: Option[],
  tooltip?: string,
): Option;
function getOption(option: {
  value: string;
  label?: string;
  allOptions?: Option[];
  tooltip?: string;
}): Option;
function getOption(
  valueOrOption:
    | string
    | {
        value: string;
        label?: string;
        allOptions?: Option[];
        tooltip?: string;
      },
  label?: string,
  allOptions?: Option[],
  tooltip?: string,
): Option {
  if (typeof valueOrOption === 'object') {
    const option = valueOrOption;
    valueOrOption = option.value;
    label = option.label;
    tooltip = option.tooltip;
    allOptions = option.allOptions;
  }

  const value = valueOrOption;
  if (!label && allOptions) {
    label = allOptions.find((option) => option.value === value)?.label;
  }

  return {
    value,
    label: label || capitalizeFirstLetter(value),
    ...(tooltip !== undefined ? { tooltip } : {}),
  };
}

export default getOption;
