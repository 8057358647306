import Spinner from 'components/global/Spinner';
import styles from './imagePreview.module.scss';
import { useEffect, useState } from 'react';

interface ImagePreviewProps {
  imageUrl: string;
  isLoading?: boolean;
  error: string | null;
  onLoad?: () => void;
}

const ImagePreview = ({
  imageUrl,
  isLoading,
  error,
  onLoad,
}: ImagePreviewProps) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);
  return (
    <div
      className={`${styles['image-preview']} ${
        isLoading ? styles['image-preview--loading'] : ''
      } ${!imageUrl ? styles['image-preview--no-image'] : ''}`}
      data-testid="image-preview"
    >
      <img
        src={imageUrl}
        alt="Trial visualization"
        className={styles['image-preview']}
        onLoad={onLoad}
      />

      {isLoading && <Spinner color="brand" size="40px" />}
      {error && <p className={styles['image-preview--error']}>{error}</p>}
      {!isOnline && (
        <p className={styles['image-preview--offline']}>
          It looks like you're offline. Previews may not work properly. Please
          check your connection and try again.
        </p>
      )}
    </div>
  );
};

export default ImagePreview;
