import FormOnlyStepContainer from '../FormOnlyStep';
import {
  CheckboxElementProps,
  FormAndImageStepProps,
  FormElementPropsType,
} from '../../../types/stepFlow.type';
import { useCallback, useEffect, useState } from 'react';

interface OptionalGroupProps {
  checkboxElement: CheckboxElementProps;
  ifCheckedFormElements: FormElementPropsType[];
}

interface FormWithCheckboxForExtraFieldsStepProps
  extends FormAndImageStepProps {
  alwaysFormElements: FormElementPropsType[];
  optionalGroups: OptionalGroupProps[];
}

const FormWithCheckboxForExtraFieldsStep = ({
  alwaysFormElements,
  optionalGroups,
  ...rest
}: FormWithCheckboxForExtraFieldsStepProps) => {
  const [formElements, setFormElements] =
    useState<FormElementPropsType[]>(alwaysFormElements);

  const initialValues = rest.initialValues;

  const updateFormElements = useCallback(
    (values: Record<string, any>) => {
      let newFormElements = [...alwaysFormElements];
      optionalGroups.forEach((group) => {
        if (values[group.checkboxElement.name]) {
          newFormElements = [
            ...newFormElements,
            group.checkboxElement,
            ...group.ifCheckedFormElements,
          ];
        } else {
          newFormElements = [...newFormElements, group.checkboxElement];
        }
      });
      setFormElements(newFormElements);
    },
    [alwaysFormElements, optionalGroups],
  );

  useEffect(() => {
    updateFormElements(initialValues);
  }, [initialValues, updateFormElements]);

  return (
    <div>
      <FormOnlyStepContainer
        {...rest}
        formElements={formElements}
        valuesCallback={updateFormElements}
      >
        {rest.children}
      </FormOnlyStepContainer>
    </div>
  );
};

export default FormWithCheckboxForExtraFieldsStep;
