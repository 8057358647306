import { useEffect, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import { Form, useFormikContext } from 'formik';
import { useComputeLsdPercentageMutation } from 'redux/services/workspace';
import ImagePreview from '../ImagePreview';
import Button from 'components/global/Button';
import FormElement from 'components/global/FormElement';
import FormErrorMessage from 'components/global/FormErrorMessage';
import styles from './placePlotsForm.module.scss';
import toast from 'react-hot-toast';

interface PlacePlotsFormProps {
  error: string;
  isLoading: boolean;
  imageUrl: string;
  children: React.ReactNode;
  updatedLsdData: string;
  onImageLoad?: () => void;
}

const PlacePlotsForm = ({
  error,
  isLoading,
  imageUrl,
  children,
  updatedLsdData,
  onImageLoad,
}: PlacePlotsFormProps) => {
  const { trial } = useAppSelector((state) => state.createTrial);
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  // show error if nominal_management_prop is not a number
  const [computeLsdPercentage, { data: lsdData, error: lsdError, isError }] =
    useComputeLsdPercentageMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (values.nominal_management_prop) {
      const formData = new FormData();

      formData.append('transactionId', trial.transactionId);
      formData.append('length_ft', String(trial.length_ft || 120));
      formData.append('width_ft', trial.width_ft);
      formData.append('buffer_ft', String(trial.buffer_ft || 50));
      formData.append(
        'nominal_management_prop',
        String(values.nominal_management_prop / 100),
      );
      formData.append('treatment_rates', trial.treatment_rates);
      formData.append('treatment_labels', trial.treatment_labels);
      formData.append('nominal_label', trial.nominal_label);
      formData.append(
        'apply_trial_buffer_to_field_boundary',
        trial.apply_trial_buffer_to_field_boundary,
      );

      computeLsdPercentage(formData);
    }
  }, [values.nominal_management_prop, trial, computeLsdPercentage]);

  useEffect(() => {
    if (lsdData) {
      setFieldValue('lsd_percent', lsdData.lsd_percent);
    }
    if (isError) {
      const errorObj: any = lsdError;
      if (errorObj?.data?.nreps_warning_message) {
        setFieldValue('lsd_percent', '');
        setErrorMessage('');
        toast.loading(errorObj?.data?.nreps_warning_message, {
          duration: 12000,
        });
      } else {
        setErrorMessage(
          `${errorObj?.data?.error} ${
            errorObj?.data?.warning_message || 'An error occurred.'
          }`,
        );
      }
    }
  }, [lsdData, isError, lsdError, setFieldValue]);

  useEffect(() => {
    if (updatedLsdData) {
      setFieldValue('lsd_percent', updatedLsdData);
    }
  }, [updatedLsdData, setFieldValue]);

  return (
    <Form className={styles['place-plots-form']}>
      <div>
        <FormErrorMessage error={error} variant="auth" />

        <FormElement
          name="prop_keep"
          label="Minimum acceptable plot area:"
          data-testid="min_plot_area"
          tooltip="As a percent of the full plot area, i.e. Only allow plots with 75% or more of the full plot area (or 50%, etc.). Plots will not be allowed unless they are at least this size."
          rightElement="%"
        />
        <FormElement
          name="nominal_management_prop"
          label="Percent of field under Nominal Treatment:"
          data-testid="percent_field_under_nominal_treatment"
          tooltip="Lower means more field area under test, more replicates, higher test precision. Higher means less area under test, fewer replicates, lower test precision."
          rightElement="%"
        />
        <FormElement
          name="lsd_percent"
          label="Estimated least significant difference (LSD):"
          data-testid="estimated_lsd_percent"
          tooltip="As a percent of the average yield in the test area."
          rightElement="%"
          disabled
        />
        {/* error component */}
        {isError && (
          <div data-testid="lsd_error" style={{ color: 'red' }}>
            {String(errorMessage)}
          </div>
        )}

        <div className={styles['place-plots-form__action-buttons']}>
          <Button
            action="button"
            variant="brand"
            type="submit"
            isLoading={isLoading}
          >
            Preview
          </Button>
          {children}
        </div>
      </div>

      <ImagePreview imageUrl={imageUrl} error={null} onLoad={onImageLoad} />
    </Form>
  );
};

export default PlacePlotsForm;
