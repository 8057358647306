import { useCallback, useEffect, useRef } from 'react';
import { initialAuthValues } from 'utils/auth';
import { setAuthState, setUserProfile } from 'redux/slices/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  AuthState,
  LoginResponse,
  User,
  FetchProfileResponse,
} from 'types/auth.type';
import { DefaultAPIResponse, Routes, StorageMethod } from 'types/global.type';
import useStorage from './useStorage';
import useNetworkRequest from './useNetworkRequest';
import getStorageMethod from 'utils/helpers/getStorageMethod';
import getFullName from 'utils/helpers/getFullName';
import { useNavigate } from 'react-router-dom';
interface AuthHelpers extends AuthState {
  logoutLoading: boolean;
  profileLoading: boolean;
  logout: () => void;
  fetchProfile: () => void;
  updateAuthStatus: (data: LoginResponse, rememberMe?: boolean) => void;
  activateGuestMode: () => void;
}

const useAuth = (): AuthHelpers => {
  const authState = useAppSelector((state) => state.auth);
  const { addStorageValue, getStorageValue, removeStorageValue } = useStorage();

  /* eslint-disable react-hooks/exhaustive-deps */
  const memoizedAddStorageValue = useCallback(addStorageValue, []);
  const memoizedGetStorageValue = useCallback(getStorageValue, []);
  const memoizedRemoveStorageValue = useCallback(removeStorageValue, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  const [{ data: logoutData, isLoading: logoutLoading }, logout] =
    useNetworkRequest<void, DefaultAPIResponse>('/logout', {
      method: 'POST',
    });
  // fetch profile endpoint
  const [{ data: profileData, isLoading: profileLoading }, fetchProfile] =
    useNetworkRequest<void, FetchProfileResponse>('/profile');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const storage = getStorageMethod();
  const isSession = storage === 'session';
  const shouldFetchSession = isSession && !window.sessionStorage.length;

  let timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const onLogoutSuccess = (): void => {
      localStorage.clear();
      sessionStorage.clear();

      memoizedAddStorageValue('logout', Date.now());

      window.location.href = Routes.login;
    };
    if (logoutData) onLogoutSuccess();
  }, [logoutData, memoizedAddStorageValue]);

  useEffect(() => {
    const onProfileFetch = (data: User): void => {
      dispatch(setUserProfile(data));
    };
    if (profileData) onProfileFetch(profileData.data);
  }, [profileData, dispatch]);

  const updateAuthState = useCallback(
    (storage: StorageMethod) => {
      const isLoggedIn = memoizedGetStorageValue<boolean>(
        'isLoggedIn',
        storage,
      );
      const user = memoizedGetStorageValue<User>('user', storage);
      const token = memoizedGetStorageValue<string>('token', storage);
      const refreshToken = memoizedGetStorageValue<string>(
        'refreshToken',
        storage,
      );
      const isGuest = memoizedGetStorageValue<boolean>('isGuest');

      dispatch(
        setAuthState({
          isGuest: Boolean(isGuest),
          isLoggedIn: Boolean(isLoggedIn),
          token: token || '',
          refreshToken: refreshToken || '',
          user: user ? { ...user, full_name: getFullName(user) } : null,
        }),
      );
    },
    [dispatch, memoizedGetStorageValue],
  );

  const updateAuthStatus = (
    data: LoginResponse,
    rememberMe?: boolean,
  ): void => {
    const {
      data: user,
      access_token: token,
      refresh_token,
      userCategory,
    } = data;
    const storage: StorageMethod = rememberMe ? 'local' : 'session';
    if (typeof user === 'string') {
      navigate(`/login?message=${user}`);
      return;
    }

    user.category = userCategory;

    memoizedAddStorageValue('isLoggedIn', true, storage);
    memoizedAddStorageValue('user', user, storage);
    memoizedAddStorageValue('token', token, storage);
    memoizedAddStorageValue('refreshToken', refresh_token, storage);
    memoizedAddStorageValue('rememberMe', rememberMe, storage);
    memoizedRemoveStorageValue('isGuest');

    dispatch(
      setAuthState({
        isLoggedIn: true,
        user: {
          ...user,
          full_name: getFullName(user),
        },
        token,
        refreshToken: refresh_token,
        isGuest: false,
      }),
    );
    window.location.href = Routes.home;
  };

  const activateGuestMode = (): void => {
    const currentMode = memoizedGetStorageValue('isGuest');
    if (!currentMode) {
      memoizedAddStorageValue('isGuest', true);
      dispatch(
        setAuthState({
          ...initialAuthValues,
          isLoggedIn: false,
          isGuest: true,
        }),
      );
    }
  };

  useEffect(() => {
    const handleStorageEvents = (event: StorageEvent) => {
      switch (event.key) {
        case 'logout':
          localStorage.clear();
          sessionStorage.clear();
          break;
        case 'getSessionStorage':
          memoizedAddStorageValue('sessionStorage', sessionStorage);
          memoizedRemoveStorageValue('sessionStorage');
          memoizedRemoveStorageValue('getSessionStorage');
          break;
        case 'sessionStorage':
          if (
            !window.sessionStorage.length &&
            event.newValue &&
            typeof event.newValue === 'string'
          ) {
            const data = JSON.parse(event.newValue);

            for (const key in data) {
              memoizedAddStorageValue(key, JSON.parse(data[key]), 'session');
            }

            updateAuthState('session');
          }
          break;
      }
    };

    window.addEventListener('storage', handleStorageEvents);

    const sessionStorageCleanup = () => {
      window.removeEventListener('storage', handleStorageEvents);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };

    if (shouldFetchSession) {
      memoizedAddStorageValue('getSessionStorage', Date.now());

      timeoutRef.current = setTimeout(() => {
        //update auth state using localstorage if
        //session is empty and no other tabs are open
        if (!window.sessionStorage.length) updateAuthState('local');
      }, 100);

      return sessionStorageCleanup;
    }

    updateAuthState(storage);
    return sessionStorageCleanup;
  }, [
    shouldFetchSession,
    memoizedAddStorageValue,
    memoizedRemoveStorageValue,
    updateAuthState,
    storage,
  ]);

  return {
    ...authState,
    logoutLoading,
    profileLoading,
    logout,
    fetchProfile,
    updateAuthStatus,
    activateGuestMode,
  };
};

export default useAuth;
