import Button from '../Button';
import styles from './footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles['footer']}>
      <Button
        action="link"
        to="https://farmtest.ag/help/"
        target="_blank"
        rel="noopenner noreferrer"
        className={styles['footer__help-button']}
        useDefaultWidth
      >
        Help / Feedback
      </Button>

      <PrivacyPolicyLinks />
    </footer>
  );
};

export default Footer;

export const PrivacyPolicyLinks = () => {
  return (
    <div className={styles['footer__links']}>
      <a
        href="https://farmtest.ag/terms"
        target="_blank"
        rel="noopenner noreferrer"
        className={styles['footer__link']}
      >
        Terms
      </a>
      <a
        href="https://farmtest.ag/privacy-policy"
        target="_blank"
        rel="noopenner noreferrer"
        className={styles['footer__link']}
      >
        Privacy Policy
      </a>
    </div>
  );
};
