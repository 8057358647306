import { Routes } from '../types/global.type';
import SEO from '../components/global/SEO';
import { useFieldTrialQuery } from '../redux/services/workspace';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
  resetState,
  updateAnalyses,
  updateFieldResults,
  updateTrialDesign,
} from '../redux/slices/analyzeTrial';
import { useAppDispatch } from '../redux/hooks';
import {
  useAnalysesByTrialIdQuery,
  useFieldResultsByTrialIdQuery,
} from '../redux/services/analysis';
import AnalysisLandingContent from '../components/analyze-trial/AnalysisLandingContent';
import styles from './analyze-trial.module.scss';

const AnalyzeTrialPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // clear Redux state that may be present from any other trials the user has
    // visited the analysis page for in this session
    dispatch(resetState());
  }, [dispatch]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trialId = queryParams.get('trial-id');
  const { data: trialDesign, isLoading: trialDataIsLoading } =
    useFieldTrialQuery(trialId as string);
  const { data: analyses, isLoading: analysesIsLoading } =
    useAnalysesByTrialIdQuery(trialId as string);
  const { data: fieldResults, isLoading: fieldResultsIsLoading } =
    useFieldResultsByTrialIdQuery(trialId as string);

  useEffect(() => {
    if (trialDesign) {
      dispatch(updateTrialDesign({ ...trialDesign.data }));
    }
  }, [trialDesign, dispatch]);

  useEffect(() => {
    if (analyses) {
      dispatch(updateAnalyses([...analyses.data]));
    }
  }, [analyses, dispatch]);

  useEffect(() => {
    if (fieldResults) {
      dispatch(updateFieldResults([...fieldResults.data]));
    }
  }, [fieldResults, dispatch]);

  return (
    <>
      <SEO prefix="Analyze Trial & View Results" path={Routes.analyzeTrial} />
      {trialDesign || trialDataIsLoading ? (
        <AnalysisLandingContent
          trialDesign={trialDesign?.data}
          analyses={analyses?.data || []}
          fieldResults={fieldResults?.data || []}
          trialDesignIsLoading={trialDataIsLoading}
          analysesIsLoading={analysesIsLoading}
          fieldResultsIsLoading={fieldResultsIsLoading}
        />
      ) : (
        <div className={styles['analysis-landing-content-error']}>
          <span>
            {'No trial was found '}
            {trialId ? (
              <>
                for trial ID <b>{trialId}</b>
              </>
            ) : (
              <>
                because <b>no trial ID was provided</b> in the URL
              </>
            )}
            . Please check the link that brought you here or contact FarmTest
            for assistance.
          </span>
        </div>
      )}
    </>
  );
};

export default AnalyzeTrialPage;
