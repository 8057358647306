import {
  AsAppliedOrYieldSummaryProps,
  SectionHeadingProps,
  SummaryFieldProps,
} from '../../../types/stepFlow.type';
import FormAndImageStep from '../../step-flow/FormAndImageStep';
import * as Yup from 'yup';
import ImagePreview from '../../create-trial/ImagePreview';
import notYetProvided from '../../../assets/images/not-yet-provided.png';
import { titleCase } from '../../../utils/helpers/capitalizationUtils';
import { useSubmitAsAppliedMutation } from '../../../redux/services/analysis';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'types/global.type';
import React from 'react';
import { generateSummaryField } from '../../../utils/helpers/analyzeTrial';
import { AsAppliedSubmitStructure } from '../../../types/analyzeTrial.type';
import { cleanRepsToRemove } from '../RepsToRemoveStep';

const AsAppliedSummaryStep = ({
  inProgressExecution,
  currentShapefile,
  setSavedData,
  shapefileType,
  shapefileGeometry,
  imageUrl,
  fieldResultId,
  trialId,
  trialType,
  overrideInstructions,
  ...rest
}: AsAppliedOrYieldSummaryProps) => {
  const navigate = useNavigate();
  let summaryFields: Array<SectionHeadingProps | SummaryFieldProps> = [
    {
      element: 'sectionHeading',
      label: overrideInstructions
        ? overrideInstructions
        : "Please confirm the details you've given us about this data are correct, then click Save.",
    },
    generateSummaryField({
      label: 'Experiment Type',
      summaryValue: titleCase(inProgressExecution.experiment_type || ''),
    }),
    generateSummaryField({
      label: `${shapefileType} Shapefile`,
      summaryValue: (
        <a
          href={currentShapefile.file_data?.signed_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {currentShapefile.filename}
        </a>
      ),
    }),
  ];

  if (shapefileGeometry === 'points') {
    // insert the column fields for points, if they should be present, at the
    // right point in the list
    summaryFields = summaryFields.concat([
      generateSummaryField({
        label: 'Swath Width column',
        summaryValue: inProgressExecution.swath_width_column,
        unitsField: inProgressExecution.swath_width_units,
      }),
      generateSummaryField({
        label: 'Distance column',
        summaryValue: inProgressExecution.distance_column,
        unitsField: inProgressExecution.distance_units,
      }),
      generateSummaryField({
        label: 'Y Offset column',
        summaryValue: inProgressExecution.y_offset_column,
        unitsField: inProgressExecution.y_offset_units,
      }),
      generateSummaryField({
        label: 'Heading column',
        summaryValue: inProgressExecution.heading_column,
        customUnit: 'degrees',
      }),
    ]);
  }

  // continue with other fields that are always present
  summaryFields = summaryFields.concat([
    generateSummaryField({
      label: `${titleCase(shapefileType)} column`,
      summaryValue: inProgressExecution.rate_column,
      customUnit: `(${inProgressExecution.rate_units})`,
    }),
    generateSummaryField({
      label: 'Plot Length',
      summaryValue: inProgressExecution.plot_length,
      unitsField: inProgressExecution.plot_buffer_length_units,
    }),
    generateSummaryField({
      label: 'Plot Buffer Length',
      summaryValue: inProgressExecution.plot_buffer_length,
      unitsField: inProgressExecution.plot_buffer_length_units,
    }),
    generateSummaryField({
      label: 'Plot Width',
      summaryValue: inProgressExecution.plot_width,
      unitsField: inProgressExecution.plot_buffer_width_units,
    }),
    generateSummaryField({
      label: 'Plot Buffer Width',
      summaryValue: inProgressExecution.plot_buffer_width,
      unitsField: inProgressExecution.plot_buffer_width_units,
    }),
    generateSummaryField({
      label: 'Reps to Remove',
      summaryValue: cleanRepsToRemove(inProgressExecution.reps_to_remove),
    }),
    generateSummaryField({
      label: 'Planting Date',
      summaryValue: (inProgressExecution as AsAppliedSubmitStructure)
        .planting_date,
    }),
  ]);

  if (trialType !== 'planting') {
    summaryFields.push(
      generateSummaryField({
        label: 'Application Date',
        summaryValue: (inProgressExecution as AsAppliedSubmitStructure)
          .application_date,
      }),
    );
  }

  const dynamicImage = (
    <ImagePreview imageUrl={imageUrl || notYetProvided} error={null} />
  );

  // this needs to accept data, since FormOnlyStep calls it with data
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitAsAppliedCallback = (data: any) => {
    navigate(`${Routes.analyzeTrial}?trial-id=${trialId}`);
  };

  return (
    <FormAndImageStep
      {...rest}
      onSubmitMutationRef={useSubmitAsAppliedMutation}
      successfulSubmitCallback={submitAsAppliedCallback}
      imagePreviewElement={dynamicImage}
      formElements={summaryFields}
      initialValues={{
        ...inProgressExecution,
        field_result_id: fieldResultId,
        trial_id: trialId,
      }}
      validationSchema={Yup.object()}
      submitButtonText={{
        default: 'Save',
        submitting: 'Saving...',
      }}
    >
      {rest.children}
    </FormAndImageStep>
  );
};

export default AsAppliedSummaryStep;
