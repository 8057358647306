import { useState } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { Routes } from 'types/global.type';
import { Field, FieldTrial } from 'types/workspace.type';
import Table from 'components/global/Table';
import Button from 'components/global/Button';
import Spinner from 'components/global/Spinner';
import styles from './workspaceContent.module.scss';

interface WorkspaceContentProps {
  data: Field[] | FieldTrial[];
  columns: (Column<Field> | Column<FieldTrial>)[];
  isLoading: boolean;
  tableHeader?: string;
  colsWithSort?: string[];
}

const WorkspaceContent = ({
  data,
  columns,
  isLoading,
  tableHeader,
  colsWithSort,
}: WorkspaceContentProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const hasData = data.length > 0;

  const { shapefile_preview_url } =
    (data[activeIndex] as Field)?.trial ||
    (data[activeIndex] as FieldTrial) ||
    {};

  const createTrialUrl = `${Routes.createTrial}`;
  return (
    <div
      className={`${styles['workspace-content']} ${
        !hasData && !isLoading ? styles['workspace-content--no-data'] : ''
      }`}
      data-testid="workspace-content"
    >
      {isLoading && !hasData ? (
        <Spinner color="brand" size="28px" />
      ) : (
        <>
          <Button
            action="link"
            to={createTrialUrl}
            variant="brand"
            className={styles['workspace-content__create-trial']}
          >
            Create Trial
          </Button>

          {hasData ? (
            <div
              className={styles['workspace-content__fields']}
              data-testid="workspace-content__fields"
            >
              <Link
                to={createTrialUrl}
                className={styles['workspace-content__fields__image']}
                data-testid="workspace-content__fields__image"
              >
                <img
                  alt="Shapefile Preview"
                  /* Cache buster. Todo: set cache control on these at GCP level.*/
                  src={`${shapefile_preview_url}?${new Date().getTime()}`}
                />
              </Link>

              {tableHeader ? (
                <h1
                  className={styles['workspace-content__fields__header']}
                  data-testid="workspace-content__header"
                >
                  {tableHeader}
                </h1>
              ) : null}

              <Table
                columns={columns}
                data={data}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                colsWithSort={colsWithSort}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default WorkspaceContent;
