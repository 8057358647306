import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilesType } from 'types/createTrial.type';
import { updateTrialData } from 'redux/slices/createTrial';
import {
  ablineAdjustmentSchema,
  ablineCreationSchema,
} from 'validators/createTrialSchemas';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useAblinePreviewMutation } from 'redux/services/workspace';
import useAuth from 'hooks/useAuth';
import ABLineForm from 'components/create-trial/ABLineForm';
import getErrorMessage from 'utils/helpers/getErrorMessage';

import { StepProps } from '../../types/stepFlow.type';
import ablineValuesChanged from 'utils/helpers/ablineValuesChanged';

export const ABLineCreationContainer = ({ children }: StepProps) => {
  return <ABLineContainer task={'creation'} children={children} />;
};

export const ABLineAdjustmentContainer = ({ children }: StepProps) => {
  return <ABLineContainer task={'adjustment'} children={children} />;
};

interface ABLineContainerProps {
  children?: React.ReactNode;
  task: 'creation' | 'adjustment';
}

const ABLineContainer = ({ children, task }: ABLineContainerProps) => {
  const { isGuest } = useAuth();
  const { trial } = useAppSelector((state) => state.createTrial);
  const [preview, { data, isLoading, isError, error }] =
    useAblinePreviewMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        updateTrialData({
          abline_preview: data.image,
          transactionId: data.transactionId,
        }),
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (isError) {
      setErrorMessage(getErrorMessage(error));
    }
  }, [isError, error]);

  const memoizedTrialValues = useMemo(() => {
    // the enormous chain of memo/callback taint starting here and proceeding
    // through generatePreview and its dependents inside the child component
    // exists to prevent infinite re-renders and thus infinite preview AB line
    // calls to the backend, while satisfying React's exhaustive deps rule.
    return {
      trialId: trial.trialId,
      trial_boundary_gdf: trial.trial_boundary_gdf,
      field_boundary_gdf: trial.field_boundary_gdf,
      get_abline_method: trial.get_abline_method,
      abline_adjust: trial.abline_adjust,
      abline_rotation: trial.abline_rotation,
      abline_heading: trial.abline_heading,
    };
  }, [
    trial.trialId,
    trial.trial_boundary_gdf,
    trial.field_boundary_gdf,
    trial.get_abline_method,
    trial.abline_adjust,
    trial.abline_rotation,
    trial.abline_heading,
  ]) as Record<string, any>;

  const appendFiles = useCallback(
    (key: string, formData: FormData): FormData => {
      const files: FilesType = memoizedTrialValues[key];

      if (!Array.isArray(files)) {
        if (files === 'trial') {
          formData.append('trialId', memoizedTrialValues.trialId);
        }
      } else {
        for (const file of files) {
          formData.append(key, file);
        }
      }
      return formData;
    },
    [memoizedTrialValues],
  );

  const getFormData = useCallback(
    (values?: Record<string, any>) => {
      let formData = new FormData();

      if (trial.transactionId) {
        if (trial.field_boundary_gdf === 'trial') {
          formData.append('trialId', trial.trialId);
        } else {
          formData.append('transactionId', trial.transactionId);
        }
      } else {
        formData = appendFiles('trial_boundary_gdf', formData);
        formData = appendFiles('field_boundary_gdf', formData);
      }

      if (!values) {
        return formData;
      }

      const fields = [
        'get_abline_method',
        'abline_rotation',
        'abline_adjust',
        'abline_heading',
      ];

      for (const field of fields) {
        if (values[field]) {
          formData.append(field, values[field]);
        }
      }

      if (!values['abline_heading']) {
        formData.delete('abline_heading');
      }

      for (const file of values.abline_gdf || []) {
        formData.append('abline_gdf', file);
      }
      return formData;
    },
    [trial.transactionId, trial.trialId, trial.field_boundary_gdf, appendFiles],
  );

  const validationSchema =
    task === 'creation' ? ablineCreationSchema : ablineAdjustmentSchema;

  const generatePreview = useCallback(
    (values?: Record<string, any>) => {
      // reset error message
      setErrorMessage(null);
      const previewIfValuesChanged = (values: FormData) => {
        if (ablineValuesChanged(values, memoizedTrialValues)) {
          preview(values);
        }
      };

      if (values) {
        if (values.get_abline_method) {
          dispatch(
            updateTrialData({
              get_abline_method: values.get_abline_method,
              abline_adjust: values.abline_adjust,
              abline_rotation: values.abline_rotation,
            }),
          );
        }
      }
      previewIfValuesChanged(getFormData(values));
    },
    [dispatch, preview, getFormData, memoizedTrialValues],
  );

  return (
    <Formik
      initialValues={{
        get_abline_method: trial?.get_abline_method || 'guess',
        abline_gdf: trial?.abline_gdf || [],
        isGuest,
        abline_adjust: trial?.abline_adjust || 0,
        abline_rotation: trial?.abline_rotation || 0,
        abline_heading: trial?.abline_heading || '',
      }}
      onSubmit={() => {}}
      validationSchema={validationSchema}
      validateOnMount
    >
      <>
        <ABLineForm
          imageUrl={trial.abline_preview || data?.image || ''}
          isLoading={isLoading}
          task={task}
          generatePreview={generatePreview}
          error={errorMessage}
        />
        {children}
      </>
    </Formik>
  );
};
