import FormAndImageStep from '../../step-flow/FormAndImageStep';
import {
  FormElementPropsType,
  ShapefileColumnMapStepProps,
} from '../../../types/stepFlow.type';
import getOptions from '../../../utils/helpers/getOptions';
import getOption from '../../../utils/helpers/getOption';
import { trialTypeOptions } from '../../../utils/createTrial';
import * as Yup from 'yup';
import { FieldData } from '../../../types/analyzeTrial.type';
import { titleCase } from '../../../utils/helpers/capitalizationUtils';
import ShapefileImagePreview from '../ShapefileImagePreview';
import { useState } from 'react';
import { ShapefileColumnMapFields } from '../../../types/analyzeTrial.type';
import notYetProvided from '../../../assets/images/not-yet-provided.png';

interface GetShapefileColumnMapFieldsProps {
  shapefileGeometry: 'points' | 'polygons';
  shapefileType: 'as-applied' | 'yield';
  savedData?: FieldData | null;
  shapefileKeyColumnLabel?: string;
  trialUnit?: string;
}

export const getShapefileColumnMapInitialValues = ({
  shapefileGeometry,
  trialUnit,
  savedData,
}: GetShapefileColumnMapFieldsProps) => {
  let values: ShapefileColumnMapFields = {
    rate_column: '',
    rate_units: trialUnit ? trialUnit : '',
  };

  if (savedData) {
    values.rate_column = savedData?.rate_column || '';

    // if the shapefile is points, we need extra column mappings. Most of the
    // functional chunks of this file do this same check and extend their
    // appropriate representations if so. This way, if the user gives us
    // polygons, they only have to fill out the rate column.
    if (shapefileGeometry === 'points') {
      values.swath_width_column = savedData.swath_width_column || '';
      values.swath_width_units = savedData.swath_width_units || 'ft';
      values.distance_column = savedData.distance_column || '';
      values.distance_units = savedData.distance_units || 'ft';
      values.y_offset_column = savedData.y_offset_column || '';
      values.y_offset_units = savedData.y_offset_units || 'ft';
      values.heading_column = savedData.heading_column || '';
      values.heading_units = 'Degrees';
    }
    return values;
  } else {
    return {
      swath_width_column: '',
      swath_width_units: 'ft',
      distance_column: '',
      distance_units: 'ft',
      y_offset_column: '',
      y_offset_units: 'ft',
      heading_column: '',
      heading_units: 'Degrees',
      rate_column: '',
      rate_units: trialUnit ? trialUnit : '',
    };
  }
};

export const getShapefileColumnMapValidator = ({
  shapefileGeometry,
  shapefileType,
}: GetShapefileColumnMapFieldsProps) => {
  let validationStructure: Record<string, any> = {
    rate_column: Yup.string().required(
      `${titleCase(shapefileType)} column is required`,
    ),
    rate_units: Yup.string().required(
      `${titleCase(shapefileType)} units are required`,
    ),
  };
  if (shapefileGeometry === 'points') {
    validationStructure.swath_width_column = Yup.string().required(
      'Swath width column is required',
    );
    validationStructure.swath_width_units = Yup.string().required(
      'Swath width units are required',
    );
    validationStructure.distance_column = Yup.string().required(
      'Distance column is required',
    );
    validationStructure.distance_units = Yup.string().required(
      'Distance units are required',
    );
    validationStructure.y_offset_column = Yup.string().required(
      'Y offset column is required',
    );
    validationStructure.y_offset_units = Yup.string().required(
      'Y offset units are required',
    );
    validationStructure.heading_column = Yup.string().required(
      'Heading is required',
    );
    validationStructure.heading_units = Yup.string().equals(['Degrees']);
  }
  return Yup.object().shape(validationStructure);
};

export const getShapefileColumnMapFields = ({
  shapefileType,
  shapefileGeometry,
  savedData,
}: GetShapefileColumnMapFieldsProps): FormElementPropsType[] => {
  let shapefileColumns: string[] = [];

  if (
    savedData &&
    'current_shapefile' in savedData &&
    savedData.current_shapefile !== undefined
  ) {
    shapefileColumns = savedData.current_shapefile.columns;
  }

  if (savedData) {
    shapefileColumns = [
      ...shapefileColumns,
      ...(savedData?.swath_width_column ? [savedData.swath_width_column] : []),
      ...(savedData?.rate_column ? [savedData.rate_column] : []),
      ...(savedData?.heading_column ? [savedData.heading_column] : []),
      ...(savedData?.y_offset_column ? [savedData.y_offset_column] : []),
      ...(savedData?.distance_column ? [savedData.distance_column] : []),
    ];
  }

  shapefileColumns = [...new Set(shapefileColumns)];
  let shapefileKeyColumnLabel;
  let rateUnitOptions = trialTypeOptions.units;
  let allowFreeFormRateUnit = false;
  if (shapefileType === 'as-applied') {
    shapefileKeyColumnLabel = 'Rate';
  } else if (shapefileType === 'yield') {
    rateUnitOptions = ['bu/ac', 'lb/ac'].map((unit) => getOption(unit, unit));
    if (
      savedData?.rate_units &&
      !rateUnitOptions.find((o) => o.value === savedData.rate_units)
    ) {
      // add the saved unit, if it is other
      rateUnitOptions.push({
        value: savedData.rate_units,
        label: savedData.rate_units,
      });
    }
    allowFreeFormRateUnit = true;
    shapefileKeyColumnLabel = 'Yield';
  }

  let fields: FormElementPropsType[] = [
    {
      element: 'sectionHeading',
      label: 'Match each property to a column name',
    },
    {
      element: 'horizontalInputPair',
      elements: [
        {
          element: 'select',
          name: 'rate_column',
          label: `${shapefileKeyColumnLabel}:`,
          options: getOptions(shapefileColumns),
        },
        {
          element: 'select',
          name: 'rate_units',
          label: 'Unit:',
          otherInputLabel: 'Enter unit:',
          options: rateUnitOptions,
          allowOther: allowFreeFormRateUnit,
        },
      ],
    },
  ];
  if (shapefileGeometry === 'points') {
    fields.push(
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'select',
            name: 'swath_width_column',
            label: 'Swath width:',
            allowOther: false,
            options: getOptions(shapefileColumns),
          },
          {
            element: 'radioGroup',
            name: 'swath_width_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            direction: 'column',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'select',
            name: 'distance_column',
            label: 'Distance:',
            allowOther: false,
            options: getOptions(shapefileColumns),
          },
          {
            element: 'radioGroup',
            name: 'distance_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'select',
            name: 'y_offset_column',
            label: 'Y offset:',
            allowOther: false,
            options: [
              ...getOptions(shapefileColumns),
              { value: 'none', label: 'The data has no y-offset' },
            ],
          },
          {
            element: 'radioGroup',
            name: 'y_offset_units',
            value: 'ft',
            radioGroupHeader: 'Unit:',
            options: [getOption('ft', 'Feet'), getOption('m', 'Meters')],
          },
        ],
      },
      {
        element: 'horizontalInputPair',
        elements: [
          {
            element: 'select',
            name: 'heading_column',
            label: 'Heading:',
            allowOther: false,
            options: getOptions(shapefileColumns),
          },
          {
            element: 'staticText',
            label: 'Unit:',
            name: 'heading_units',
            value: 'Degrees',
          },
        ],
      },
    );
  }

  return fields;
};

const ShapefileColumnMapStep = ({
  shapefileType,
  fieldResultId,
  uploadedFiles,
  shapefileGeometry,
  imageUrl,
  ...rest
}: ShapefileColumnMapStepProps) => {
  const [canUpdate, setCanUpdate] = useState(true);
  const [currentFieldValues, setCurrentFieldValues] =
    useState<ShapefileColumnMapFields>(
      rest.initialValues as ShapefileColumnMapFields,
    );
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const shouldUpdate = (values: ShapefileColumnMapFields) => {
    // set the fields we want to update on change for
    const columnKeys: (keyof ShapefileColumnMapFields)[] =
      shapefileGeometry === 'polygons'
        ? ['rate_column', 'rate_units']
        : [
            'rate_column',
            'rate_units',
            'swath_width_column',
            'swath_width_units',
            'distance_column',
            'distance_units',
            'y_offset_column',
            'y_offset_units',
            'heading_column',
          ];

    // check if all fields are non-empty
    const allKeysNonEmpty = columnKeys.every((key) => Boolean(values[key]));

    if (currentFieldValues !== values && allKeysNonEmpty) {
      for (const key of columnKeys) {
        if (currentFieldValues[key] !== values[key]) {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const handleUpdate = (values: ShapefileColumnMapFields) => {
    // the way this works is: we store the initial values of the fields. we then compare them to
    // the new values every time any field is updated. if all the column fields are non-emptystring
    // and anything is different, we fire an update in the ShapefileImagePreview, and set the stored
    // values to the new values (to compare against next update). When we do this, we set this
    // component's canUpdate to false, so that we don't send multiple updates. When the
    // ShapefileImagePreview is done updating, it will fire handleUpdateComplete, which will set
    // canUpdate back to true.
    // The goal is that when the user has set the initial values for all fields, the update fires,
    // and then it refreshes when they change anything after that.
    if (shouldUpdate(values)) {
      setCurrentFieldValues(values);
      if (canUpdate) {
        setTriggerUpdate(true);
        setCanUpdate(false);
      }
    }
  };

  const handleUpdateComplete = () => {
    setCanUpdate(true);
    setTriggerUpdate(false);
  };

  return (
    <FormAndImageStep
      {...rest}
      imagePreviewElement={
        <ShapefileImagePreview
          initialImageUrl={imageUrl || notYetProvided}
          files={uploadedFiles}
          triggerUpdate={triggerUpdate}
          triggerUpdateCallback={handleUpdateComplete}
          columnMap={currentFieldValues}
          fieldResultId={fieldResultId}
          shapefileType={shapefileType}
          previewType="scaled"
          {...rest}
        />
      }
      valuesCallback={(values) => {
        handleUpdate(values);
      }}
    >
      {rest.children}
    </FormAndImageStep>
  );
};

export default ShapefileColumnMapStep;
