import Spinner from 'components/global/Spinner';
import styles from './menuButton.module.scss';

interface MenuButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Left icon for the menu button */
  icon?: React.ReactNode;

  isLoading?: boolean;
  children: React.ReactNode;
  onClick: React.MouseEventHandler;
}

const MenuButton = ({
  icon,
  children,
  isLoading,
  onClick,
  ...rest
}: MenuButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`${styles['menu-button']} ${
        isLoading ? styles['menu-button--loading'] : ''
      }`}
      data-testid="menu-button"
      {...rest}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {icon ? icon : null}
          {children}
        </>
      )}
    </button>
  );
};

export default MenuButton;
