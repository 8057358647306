import { Formik } from 'formik';
import { signUpSchema } from 'validators/authSchemas';
import { LoginResponse, SignUpRequest } from 'types/auth.type';
import SignUpForm from 'components/auth/SignUpForm';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getErrorMessage from 'utils/helpers/getErrorMessage';

const SignUpContainer = () => {
  const [{ data, isLoading, error }, signUp] = useNetworkRequest<
    SignUpRequest,
    LoginResponse
  >('/signup', {
    method: 'POST',
  });

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(values) => {
          const { email, password, first_name, last_name } = values;

          signUp({
            email,
            password,
            ...(first_name ? { first_name } : {}),
            ...(last_name ? { last_name } : {}),
          });
        }}
        validationSchema={signUpSchema}
      >
        <SignUpForm
          error={getErrorMessage(error)}
          loading={isLoading}
          successMsg={
            data
              ? 'Account creation successful! Follow the instructions sent to your email to verify your account and start using FarmTest.'
              : ''
          }
        />
      </Formik>
    </>
  );
};

export default SignUpContainer;
