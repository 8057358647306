import {
  FieldData,
  ShapefileColumnMapFields,
  ShapefileUploadPayload,
} from '../../../types/analyzeTrial.type';
import { capitalizeFirstLetter } from '../../../utils/helpers/capitalizationUtils';
import ImagePreview from '../../create-trial/ImagePreview';
import notYetProvided from '../../../assets/images/not-yet-provided.png';
import { updateShapefileData } from '../../../redux/slices/analyzeTrial';
import { useUploadShapefileMutation } from '../../../redux/services/analysis';
import { useAppDispatch } from '../../../redux/hooks';
import { useCallback, useEffect, useState } from 'react';
import getErrorMessage from '../../../utils/helpers/getErrorMessage';
import { useDebouncedCallback } from 'use-debounce';

export interface PrescriptionParams {
  prescriptionUrl?: string;
  transactionId?: string;
  fieldName?: string;
}

export interface ShapefileImagePreviewProps {
  files: File[];
  triggerUpdate?: boolean;
  triggerUpdateCallback?: () => void;
  columnMap?: ShapefileColumnMapFields;
  useGCPFiles: boolean;
  shapefileType: string;
  setFieldResultId: (fieldResultId: string) => void;
  fieldResultId: string | null;
  initialImageUrl?: string;
  previewType: 'raw' | 'scaled' | 'scaled_with_plots';
  trialId: string;
  fieldBoundaryUrl: string;
  prescriptionParams?: PrescriptionParams;
  trialBoundaryUrl?: string;
}

export const parseValuesToColumnMap = (values: ShapefileColumnMapFields) => {
  let parsedValues: ShapefileColumnMapFields = {
    swath_width_column: values.swath_width_column,
    swath_width_units: values.swath_width_units,
    distance_column: values.distance_column,
    distance_units: values.distance_units,
    y_offset_units: values.y_offset_units,
    heading_column: values.heading_column,
    rate_column: values.rate_column,
    rate_units: values.rate_units,
  };
  if (values.y_offset_column !== 'none') {
    parsedValues.y_offset_column = values.y_offset_column;
  }
  return JSON.stringify(parsedValues);
};

export const useGCPFiles = (
  savedData: FieldData,
  files: File[] | undefined,
  currentStep: 'ShapefileColumnMapStep' | 'RepsToRemoveStep',
) => {
  // if there are no uploaded files, and we already have a file saved on this field result,
  // or if the files have been uploaded in a previous step, we
  // need to tell the backend to use the files on GCP. This comparison sets that bool.
  return !!(
    (savedData?.current_shapefile?.filename &&
      (files === undefined || files.length === 0)) ||
    ['ShapefileColumnMapStep', 'RepsToRemoveStep'].includes(currentStep)
  );
};

const ShapefileImagePreview = ({
  files,
  triggerUpdate,
  triggerUpdateCallback,
  columnMap,
  useGCPFiles,
  shapefileType,
  fieldResultId,
  previewType,
  initialImageUrl,
  trialId,
  fieldBoundaryUrl,
  trialBoundaryUrl,
  prescriptionParams,
  setFieldResultId,
}: ShapefileImagePreviewProps) => {
  const [uploadShapefile, { isLoading, isError, error }] =
    useUploadShapefileMutation();
  const dispatch = useAppDispatch();
  const [errorText, setErrorText] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(
    initialImageUrl || notYetProvided,
  );
  const [currentFiles, setCurrentFiles] = useState<File[]>(files);

  const assembleShapefilePayload = useCallback((): ShapefileUploadPayload => {
    const backendShapefileType = ['as-planted', 'as-applied'].includes(
      shapefileType,
    )
      ? 'as_applied'
      : 'yield';
    let payload: ShapefileUploadPayload = {
      shapefiles: files,
      shapefile_type: backendShapefileType,
      trial_id: trialId,
      extract_science_data: true,
      preview_image: true,
      preview_image_type: previewType,
      upload_to_gcs: true,
      preview_shapefile_label_type: capitalizeFirstLetter(shapefileType),
      field_boundary_url: fieldBoundaryUrl,
    };

    if (fieldResultId) {
      payload.field_result_id = fieldResultId;
    }
    if (trialBoundaryUrl) {
      payload.trial_boundary_url = trialBoundaryUrl;
    }
    if (prescriptionParams) {
      // if we have a prescription file URL, use that. If not, use the transaction ID and field name
      // to tell the backend to fetch the prescription file from the transaction folder.
      if (prescriptionParams.prescriptionUrl) {
        payload.prescription_url = prescriptionParams.prescriptionUrl;
      } else {
        payload.transaction_id = prescriptionParams.transactionId;
        payload.field_name = prescriptionParams.fieldName;
      }
    }
    if (columnMap) {
      payload.preview_image_columns = parseValuesToColumnMap(columnMap);
    }
    return payload;
  }, [
    files,
    trialId,
    shapefileType,
    fieldResultId,
    fieldBoundaryUrl,
    trialBoundaryUrl,
    previewType,
    columnMap,
    prescriptionParams,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const uploadShapefileAndPreview = useCallback(
    useDebouncedCallback(() => {
      const payload = assembleShapefilePayload();
      if (useGCPFiles) {
        payload['shapefiles'] = 'useGCP';
      }
      uploadShapefile(payload)
        .then((response) => {
          if ('data' in response) {
            if (triggerUpdateCallback) {
              // notify the outside component that the update was successful
              triggerUpdateCallback();
            }
            setErrorText(null);
            setCurrentFiles(files);
            setImageUrl(
              response.data.data?.preview_image_signed_url?.signed_url ||
                notYetProvided,
            );
            if (response.data.data?.shapefile_data) {
              dispatch(updateShapefileData(response.data.data));
              setFieldResultId(response.data.data.field_result_id);
            }
          }
        })
        .catch((error) => {
          setErrorText(error.error);
        });
    }, 500),
    [
      assembleShapefilePayload,
      uploadShapefile,
      dispatch,
      setFieldResultId,
      triggerUpdateCallback,
      useGCPFiles,
      files,
    ],
  );

  // there are 6 states this component can be in
  // - 1: new field result: no uploaded files, no stored files
  //     files == [], useGCP === false, initialImageUrl === notYetProvided. Do not preview.
  // - 2: previously stored files, no new upload files
  //     files == [], useGCP === true, initialImageUrl should be set. Do not preview.
  // - 3: user has just uploaded files, and we need to store them and preview
  //     files == [some files], useGCP === false, initialImageUrl === notYetProvided. Send preview.
  // - 4: just-uploaded files, stored files (as a result of upload just now)
  //     files == [some files], useGCP === false, initialImageUrl should be set. Do not preview.
  //     This is handled by currentFiles/setCurrentFiles local component state.
  // - 5: previously stored files, new upload files to replace them
  //     files == [some files], useGCP === false, initialImageUrl should be set. Send preview.
  // - 6: triggerUpdate === True.
  //     disregard everything else. Send preview.

  useEffect(() => {
    if (
      files.length === 0 &&
      !useGCPFiles &&
      initialImageUrl === notYetProvided
    ) {
      // case 1
    } else if (
      files.length === 0 &&
      useGCPFiles &&
      ![undefined, notYetProvided].includes(initialImageUrl)
    ) {
      // case 2
    } else if (
      files.length > 0 &&
      !useGCPFiles &&
      initialImageUrl === notYetProvided &&
      currentFiles !== files
    ) {
      // case 3
      uploadShapefileAndPreview();
    } else if (
      files.length > 0 &&
      !useGCPFiles &&
      ![undefined, notYetProvided].includes(initialImageUrl) &&
      currentFiles !== files
    ) {
      // case 5 (case 4 handled by currentFiles check)
      uploadShapefileAndPreview();
    }
  }, [
    files,
    useGCPFiles,
    initialImageUrl,
    uploadShapefileAndPreview,
    currentFiles,
  ]);

  useEffect(() => {
    if (triggerUpdate) {
      uploadShapefileAndPreview(); // case 6
    }
  }, [triggerUpdate, uploadShapefileAndPreview]);

  useEffect(() => {
    if (isError) {
      setErrorText(getErrorMessage(error));
    }
  }, [error, isError]);

  return (
    <ImagePreview imageUrl={imageUrl} isLoading={isLoading} error={errorText} />
  );
};

export default ShapefileImagePreview;
