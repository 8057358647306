import { Form } from 'formik';
import { FormProps } from 'types/global.type';
import Button from 'components/global/Button';
import FormElement from 'components/global/FormElement';
import FormErrorMessage from 'components/global/FormErrorMessage';

const ResetPasswordForm = ({ loading, error, successMsg }: FormProps) => {
  return successMsg ? (
    <FormErrorMessage error={successMsg} variant="standalone" type="success" />
  ) : (
    <Form data-testid="reset-password-form">
      <FormErrorMessage error={error} variant="auth" />

      <FormElement
        name="password"
        type="password"
        variant="auth"
        label="New Password"
      />
      <FormElement
        name="confirmPassword"
        type="password"
        variant="auth"
        label="Confirm Password"
      />

      <Button action="button" variant="brand" type="submit" isLoading={loading}>
        Reset
      </Button>
    </Form>
  );
};

export default ResetPasswordForm;
