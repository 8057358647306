import {
  useDeleteTrialMutation,
  useFieldTrialsQuery,
  useLazyDownloadShapefilesQuery,
} from 'redux/services/workspace';
import { CellProps, Column } from 'react-table';
import { Routes } from 'types/global.type';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldTrial } from 'types/workspace.type';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as TrashIcon } from 'assets/vectors/trash.svg';
import SEO from 'components/global/SEO';
import saveFile from 'utils/helpers/saveFile';
import Button from 'components/global/Button';
import Spinner from 'components/global/Spinner';
import ConfirmModal from 'components/global/ConfirmModal';
import WorkspaceContent from 'components/workspace/WorkspaceContent';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import splitString from '../../utils/helpers/splitString';
import Tooltip from '../../components/global/Tooltip';
import PurchaseShapefile from '../create-trial/PurchaseShapefile';
import styles from './FieldTrials.module.scss';
import {
  USER_IDS_DELETE_PAID_TRIALS,
  FEATURE_FLAG_ANALYSIS_UI,
} from 'utils/config';

interface DownloadTrialDesignProps {
  shapefileUrl?: string | null;
  trialId: string;
}

export const DownloadTrialDesign = ({
  shapefileUrl,
  trialId,
}: DownloadTrialDesignProps) => {
  const [downloadShapefiles, { data, isLoading }] =
    useLazyDownloadShapefilesQuery();

  useEffect(() => {
    if (data) saveFile(data.shapefileUrl[0]);
  }, [data]);

  return (
    <button
      datatype="table-link"
      onClick={() => {
        if (shapefileUrl) saveFile(shapefileUrl);
        else if (data) saveFile(data.shapefileUrl[0]);
        else downloadShapefiles(trialId);
      }}
      data-testid="download-trial"
    >
      {isLoading ? <Spinner color="brand" /> : 'Download'}
    </button>
  );
};

export const AnalyzeTrialLink = ({ trialId }: { trialId: string }) => {
  return (
    <Button
      action="link"
      variant="brand"
      to={`${Routes.analyzeTrial}?trial-id=${trialId}`}
      className={`${styles['field-trials-table__button']}`}
    >
      Results & Analysis
    </Button>
  );
};

export const getDownloadButton = ({
  value: url,
}: CellProps<FieldTrial, any>) => {
  const fileName = splitString(url || '', '/').pop() || '';

  return url && url.match(/^(http|https):\/\/[^ "]+$/) ? (
    <button
      datatype="table-link"
      onClick={() => saveFile(url)}
      data-testid="download-trial"
    >
      {fileName.slice(0, fileName.indexOf('.')).slice(0, 15)}
    </button>
  ) : url && !url.match(/^(http|https):\/\/[^ "]+$/) ? (
    'Errored - contact us'
  ) : (
    '-'
  );
};

export interface GetFieldTrialColumnsProps {
  setTrialId: (trialId: string) => void;
  setDeleteModalOpen: (val: boolean) => void;
}

export const getFieldTrialColumns = ({
  setTrialId,
  setDeleteModalOpen,
}: GetFieldTrialColumnsProps): Column<FieldTrial>[] => {
  let columns: Column<FieldTrial>[] = [
    {
      Header: (
        <div>
          <span style={{ marginRight: 8 }}>Field/Trial Boundaries</span>
          <Tooltip label="This is usually the field boundary, but can also be a part of a field">
            <InfoIcon />
          </Tooltip>
        </div>
      ),
      accessor: (row) =>
        row.trial_boundary_file_url
          ? row.trial_boundary_file_url
          : row.field_boundary_file_url,
      id: 'trial_boundary_file_url',
      Cell: getDownloadButton,
    },
    { Header: 'AB-Line', accessor: 'abline_file_url', Cell: getDownloadButton },
    {
      Header: 'Trial Settings',
      accessor: 'trial_settings_file_url',
      Cell: getDownloadButton,
    },
    {
      Header: 'Trial Design',
      id: 'download-purchase-link',
      accessor: 'paid',
      Cell: ({ row, value: isPaid }) => {
        const { shapefile_url, id } = row.original;

        return isPaid ? (
          <DownloadTrialDesign shapefileUrl={shapefile_url} trialId={id} />
        ) : (
          <PurchaseShapefile
            getTrialData={() => {
              const formData = new FormData();
              formData.append('trial_id', id);
              return formData;
            }}
          />
        );
      },
    },
    {
      Header: 'Plan Summary',
      accessor: 'plan_summary_file_url',
      Cell: getDownloadButton,
    },
    {
      Header: 'Creation Date',
      accessor: 'created_at',
      Cell: ({ value }) => new Date(value).toLocaleDateString('en-CA'),
      //@ts-ignore
      sortType: 'datetime',
    },
    {
      id: 'analyze-link',
      accessor: 'paid',
      Cell: ({ row, value: isPaid }) => {
        const { id } = row.original;
        // if paid, show analyze link
        return isPaid ? <AnalyzeTrialLink trialId={id} /> : <div />;
      },
    },
    {
      accessor: 'id',
      Cell: ({ row, value }) => (
        <Button
          action="button"
          variant="icon"
          onClick={() => {
            setTrialId(value);
            setDeleteModalOpen(true);
          }}
          // Allow test/demo user accounts to delete paid trials
          isDisabled={
            row.original.paid &&
            !USER_IDS_DELETE_PAID_TRIALS?.match(row.original.user_id)
          }
          style={{ padding: '0 16px' }}
          data-testid={`${value}-delete-button`}
        >
          <TrashIcon />
        </Button>
      ),
    },
  ];
  if (!FEATURE_FLAG_ANALYSIS_UI) {
    columns = columns.filter((col) => col.id !== 'analyze-link');
  }
  return columns;
};

const FieldTrialsContainer = () => {
  const [trialId, setTrialId] = useState('');
  const [deleteTrialAction, setDeleteTrialAction] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const { id } = useParams();

  const {
    data,
    isLoading,
    refetch: refetchFieldTrials,
  } = useFieldTrialsQuery(id as string);
  const [
    deleteTrial,
    {
      data: deleteTrialData,
      isLoading: deleteTrialLoading,
      error: deleteTrialError,
      isError: deleteTrialIsError,
    },
  ] = useDeleteTrialMutation();

  useEffect(() => {
    if (trialId && deleteTrialAction) {
      deleteTrial(trialId);
      setTrialId('');
      setDeleteTrialAction(false);
    }
  }, [trialId, deleteTrialAction, deleteTrial]);

  const handleDelete = useCallback(() => {
    // this callback exists to avoid unnecessary dependencies on data itself in the useEffect
    // below it - apparently useCallback produces a stable reference, which won't change if its own
    // deps do, thus won't trigger the useEffect when data changes, only when deleteTrialData does.
    // Should save us a few renders here and there, also gets rid of a Typescript warning.
    const dataLength = data?.data?.length;

    setDeleteModalOpen(false);

    if (dataLength === 1) {
      navigate(Routes.home);
    } else {
      refetchFieldTrials();
    }
  }, [data, navigate, refetchFieldTrials]);

  useEffect(() => {
    if (deleteTrialData) {
      handleDelete();
    }
  }, [deleteTrialData, handleDelete]);

  useEffect(() => {
    if (deleteTrialIsError) {
      setErrorMessage(getErrorMessage(deleteTrialError));
    }
  }, [deleteTrialIsError, deleteTrialError]);

  const columns = useMemo(
    // This has to be memoized to avoid an infinite render loop.
    () => getFieldTrialColumns({ setTrialId, setDeleteModalOpen }),
    [],
  );

  return (
    <>
      <SEO prefix="Field Trials" path={`${Routes.fieldTrials}/${id}`} />

      <WorkspaceContent
        data={
          data && Array.isArray(data.data)
            ? data.data.map((trial) => ({
                ...trial,
                created_at: new Date(trial.created_at),
              }))
            : []
        }
        columns={columns}
        isLoading={isLoading}
        tableHeader={`Trials for Field: ${data?.field?.field_name}`}
        colsWithSort={['created_at']}
      />

      <ConfirmModal
        isOpen={deleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        heading="Delete Field Trial?"
        description={`Are you sure you want to delete this trial? Trials cannot be recovered once they are deleted. ${
          data?.data?.length === 1
            ? 'Since this is the last trial, its corresponding field will also be deleted and cannot be recovered.'
            : ''
        }`}
        confirmCta="Delete"
        confirmAction={() => {
          setDeleteTrialAction(true);
        }}
        isLoading={deleteTrialLoading}
        error={errorMessage}
      />
    </>
  );
};

export default FieldTrialsContainer;
