const splitString = (
  string: string,
  separator: string,
  regex = /,(?=[^\s])/g,
): string[] => {
  if (typeof string === 'string' && typeof separator === 'string') {
    return string
      .replace(regex, separator)
      .split(separator)
      .map((word) => word.trim())
      .filter((word) => word !== '');
  } else return [];
};

export default splitString;
