import { useState } from 'react';
import { updateAsAppliedFlowStep } from '../../../redux/slices/analyzeTrial';
import { StepContent } from '../../step-flow/CreateStepContent';
import {
  AsAppliedSummaryStepDefinition,
  FlowStep,
} from '../../../types/stepFlow.type';
import { useAppSelector } from '../../../redux/hooks';
import styles from './noApplicationDataFlow.module.scss';
import { titleCase } from '../../../utils/helpers/capitalizationUtils';
import AsAppliedSummaryStep from '../AsAppliedSummaryStep';
import { useSearchParams } from 'react-router-dom';
import { AsAppliedSubmitStructure } from '../../../types/analyzeTrial.type';

const NoApplicationDataFlow = () => {
  const trialDesign = useAppSelector((state) => state.analyzeTrial.trialDesign);
  const [searchParams] = useSearchParams();
  const [fieldResultId] = useState<string | null>(searchParams.get('id'));
  const [, setSavedAsAppliedData] = useState<any>(null);
  const [trialType] = useState(
    trialDesign.planning_trial_type === 'Planting'
      ? 'planting'
      : 'non-planting',
  );

  const [fieldResultsType] = useState('as-planted');

  const convertDateFormat = (dateString: string): string => {
    const date = new Date(dateString);
    const formatter = new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const [{ value: month }, , { value: day }, , { value: year }] =
      formatter.formatToParts(date);
    return `${month}/${day}/${year}`;
  };

  const fakeExecution: AsAppliedSubmitStructure = {
    experiment_type: trialType,
    rate_column: 'tgt_rate',
    as_applied_shapefiles: trialDesign.treatment_file_url,
    field_boundary_url: trialDesign.field_boundary_file_url,
    trial_boundary_url: trialDesign.trial_boundary_file_url,
    special_case: 'simulated_as_applied',
    rate_units: trialDesign.application_units,
    plot_length: trialDesign.length_ft,
    plot_width: trialDesign.width_ft,
    plot_buffer_length: 50,
    plot_buffer_width: 0,
    plot_buffer_length_units: 'ft',
    plot_buffer_width_units: 'ft',
    reps_to_remove: [],
    planting_date: trialDesign.start_date
      ? convertDateFormat(trialDesign.start_date)
      : undefined,
    application_date: trialDesign.start_date
      ? convertDateFormat(trialDesign.start_date)
      : undefined,
  };

  const overrideHeader = `If you do not have ${trialType} data or you do not wish to use it 
  here, we can instead analyze the trial assuming it was executed perfectly following the trial 
  design. The values below are extracted from your trial design; please click Save to record them 
  in place of your as-applied data.`;

  const asAppliedFlowSteps: FlowStep[] = [
    {
      container: AsAppliedSummaryStep,
      heading: `Simulated ${titleCase(fieldResultsType)}: Review and Save`,
      props: {
        currentShapefile: {
          filename: 'Trial design shapefile',
          file_data: {
            signed_url: trialDesign.treatment_file_url,
          },
        },
        fieldResultId: fieldResultId,
        imageUrl: trialDesign.shapefile_preview_url,
        inProgressExecution: fakeExecution,
        setSavedData: setSavedAsAppliedData,
        shapefileGeometry: 'polygons',
        shapefileType: titleCase(fieldResultsType),
        trialId: trialDesign.id,
        trialType: trialType,
        uniqueKey: 'as_applied_summary',
        overrideInstructions: overrideHeader,
      },
    } as AsAppliedSummaryStepDefinition,
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const { heading, subheading, container, props } =
    asAppliedFlowSteps[activeIndex];

  if (Object.keys(trialDesign).length === 0) {
    return (
      <div
        data-testid="no-application-data-flow-trial-design-missing-error"
        className={styles['no-application-data-flow-error']}
      >
        <p>
          <b>The current page was loaded without a trial design.</b>
        </p>
        <p>
          This is probably because the URL was loaded directly, rather than from
          an Analysis & Results page. Please navigate from your list of field
          trials to view the analysis and results for a specific trial; from
          there you may click the link below the as-applied table to load this
          page.
        </p>
        <p>
          If you came here from a link elsewhere on the site, please contact
          FarmTest for assistance.
        </p>
      </div>
    );
  }

  return (
    <StepContent
      heading={heading}
      subheading={subheading}
      superheading={`Analyze trial`}
      Container={container}
      props={props}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      updateStateFunction={updateAsAppliedFlowStep}
      stateSelector={(state) => state.analyzeTrial.asAppliedFlow}
      flowSteps={asAppliedFlowSteps}
    />
  );
};

export default NoApplicationDataFlow;
