import { forwardRef, useEffect, useRef } from 'react';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import Tooltip from '../Tooltip';
import FormErrorMessage from '../FormErrorMessage';
import styles from './formElement.module.scss';
import {
  FormElementProps,
  FormElementTagProps,
  FormElementType,
} from '../../../types/stepFlow.type';

const FormElement = forwardRef(
  (
    {
      name,
      label,
      subLabel,
      tooltip,
      footer,
      element = 'input',
      variant = 'default',
      direction = 'column',
      containerClass = '',
      ...rest
    }: FormElementProps,
    ref,
  ) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const { errors, touched } = useFormikContext<any>();

    useEffect(() => {
      if (errors[name] && touched[name]) {
        inputRef.current?.scrollIntoView();
      }
    }, [errors, touched, name]);

    return (
      <Field name={name}>
        {({ field, form: { touched, errors } }: FieldAttributes<any>) => {
          const classList = `${styles['form-element']} ${
            styles['form-element--' + variant]
          } ${rest.className} ${
            errors[name] && touched[name] ? styles['form-element--invalid'] : ''
          }`;

          rest.id = rest.id || name;
          rest['data-testid'] = rest['data-testid'] || name;
          return (
            <div
              ref={inputRef}
              className={`${
                styles['form-element__container']
              } form-element-container ${
                styles['form-element__container--' + direction]
              } ${
                rest.disabled ? styles['form-element__container--disabled'] : ''
              } ${containerClass}`}
            >
              {label ? (
                <>
                  <div
                    className={`${styles['form-element__label-group']} form-element-label-group`}
                  >
                    <label
                      htmlFor={rest.id}
                      className={styles['form-element__label']}
                      data-testid={`${rest['data-testid']}__label`}
                    >
                      {label}

                      {tooltip ? (
                        <Tooltip label={tooltip}>
                          <InfoIcon />
                        </Tooltip>
                      ) : null}
                    </label>

                    {subLabel ? <span>{subLabel}</span> : null}
                  </div>
                </>
              ) : null}

              <div>
                <FormElementTag
                  element={element}
                  {...rest}
                  {...field}
                  className={classList}
                  ref={ref}
                />

                <FormErrorMessage
                  error={errors[name] && touched[name] ? errors[name] : ''}
                  data-testid={`${name}-error`}
                />
                {footer ? (
                  <div className={styles['form-element-footer']}>
                    <p>{footer}</p>
                  </div>
                ) : null}
              </div>
            </div>
          );
        }}
      </Field>
    );
  },
);

export default FormElement;

const FormElementTag = forwardRef<HTMLInputElement>(
  (
    {
      element,
      options = [],
      leftElement,
      rightElement,
      onChangeCallback,
      ...rest
    }: FormElementTagProps,
    ref,
  ) => {
    const onChange = (event: React.ChangeEvent<FormElementType>) => {
      if (rest.onChange) {
        rest.onChange(event);

        if (onChangeCallback) {
          onChangeCallback(event);
        }
      }
    };

    const renderFormElementTag = () => {
      switch (element) {
        case 'select':
          return (
            <select {...rest} onChange={onChange}>
              <option value="" hidden></option>

              {options?.map(({ value, label }, index) => (
                <option value={value} key={index}>
                  {label || value}
                </option>
              ))}
            </select>
          );
        case 'input':
        default:
          return (
            <div
              className={`${styles['input-group']} ${
                rest.disabled ? styles['input-group--disabled'] : ''
              }`}
            >
              {leftElement ? (
                <span className={styles['input__left-element']}>
                  {leftElement}
                </span>
              ) : null}

              <input
                {...rest}
                onChange={onChange}
                ref={ref}
                className={`${styles['input']} ${rest.className} ${
                  leftElement ? styles['input--has-left'] : ''
                } ${rightElement ? styles['input--has-right'] : ''}`}
              />

              {rightElement ? (
                <span className={styles['input__right-element']}>
                  {rightElement}
                </span>
              ) : null}
            </div>
          );
      }
    };

    return <>{renderFormElementTag()}</>;
  },
);
