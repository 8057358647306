import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Analysis,
  AnalyzeTrialState,
  FieldResult,
  ShapefileResponseData,
} from '../../types/analyzeTrial.type';
import { Step } from '../../types/stepFlow.type';

const initialState: AnalyzeTrialState = {
  trialDesign: {},
  analyses: [],
  fieldResults: [],
  yieldDataFlow: {
    steps: [],
    completedSteps: [],
    inProgress: {},
  },
  asAppliedFlow: {
    steps: [],
    completedSteps: [],
    inProgress: {},
  },
  trialDesignOverrideFlow: {
    steps: [],
    completedSteps: [],
    inProgress: {},
  },
  analysisSetupFlow: {
    steps: [],
    completedSteps: [],
    inProgress: {},
  },
};

export const analyzeTrialSlice = createSlice({
  name: 'analyzeTrial',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    updateYieldDataFlowStep: (state, action: PayloadAction<Step>) => {
      const { index, data } = action.payload;
      state.yieldDataFlow.steps[index] = data;
      state.yieldDataFlow.completedSteps[index] = true;
      state.yieldDataFlow.inProgress = {
        ...state.yieldDataFlow.inProgress,
        ...data,
      };
    },
    updateAsAppliedFlowStep: (state, action: PayloadAction<Step>) => {
      const { index, data } = action.payload;
      state.asAppliedFlow.steps[index] = data;
      state.asAppliedFlow.completedSteps[index] = true;
      state.asAppliedFlow.inProgress = {
        ...state.asAppliedFlow.inProgress,
        ...data,
      };
    },
    updateTrialDesignOverrideFlowStep: (state, action: PayloadAction<Step>) => {
      const { index, data } = action.payload;
      state.trialDesignOverrideFlow.steps[index] = data;
      state.trialDesignOverrideFlow.completedSteps[index] = true;
      state.trialDesignOverrideFlow.inProgress = {
        ...state.trialDesignOverrideFlow.inProgress,
        ...data,
      };
    },
    updateAnalysisSetupFlowStep: (state, action: PayloadAction<Step>) => {
      const { index, data } = action.payload;
      state.analysisSetupFlow.steps[index] = data;
      state.analysisSetupFlow.completedSteps[index] = true;
      state.analysisSetupFlow.inProgress = {
        ...state.analysisSetupFlow.inProgress,
        ...data,
      };
    },
    updateTrialDesign: (state, action: PayloadAction<Step['data']>) => {
      state.trialDesign = action.payload;
    },
    updateAnalyses: (state, action: PayloadAction<Array<Analysis>>) => {
      state.analyses = action.payload;
    },
    updateFieldResults: (state, action: PayloadAction<Array<FieldResult>>) => {
      state.fieldResults = action.payload;
    },
    updateShapefileData: (
      state,
      action: PayloadAction<ShapefileResponseData>,
    ) => {
      // we just got back a shapefile upload/preview response, so we need to update our Redux state
      // with the values the API returned
      if (action.payload) {
        const {
          shapefile_type,
          image_key,
          field_result_id,
          shapefile_data,
          preview_image_signed_url,
        } = action.payload;
        if (!state.fieldResults[0]) {
          state.fieldResults[0] = {
            field_result_id: field_result_id,
            customer_id: state.trialDesign.customer_id,
            trial_id: state.trialDesign.trial_id,
            [`${shapefile_type}_data`]: {
              current_shapefile: shapefile_data,
              [`${image_key}`]: preview_image_signed_url,
            },
          };
        } else {
          let targetPath = {
            ...state.fieldResults[0][`${shapefile_type}_data`],
          };
          targetPath.current_shapefile = action.payload.shapefile_data;
          if (image_key) {
            targetPath[image_key] = action.payload
              .preview_image_signed_url as any;
            if (image_key === 'raw_preview_image') {
              // we just regenerated the raw, invalidate our copies of the old scaled
              targetPath.scaled_preview_image = undefined;
              targetPath.scaled_with_plots_preview_image = undefined;
            }
          }
          state.fieldResults[0] = {
            ...state.fieldResults[0],
            [`${shapefile_type}_data`]: targetPath,
          };
        }
      }
    },
  },
});

export const {
  updateYieldDataFlowStep,
  updateAsAppliedFlowStep,
  updateTrialDesignOverrideFlowStep,
  updateAnalysisSetupFlowStep,
  updateTrialDesign,
  updateFieldResults,
  updateAnalyses,
  updateShapefileData,
  resetState,
} = analyzeTrialSlice.actions;

export default analyzeTrialSlice.reducer;
