import {
  treatmentSchema,
  trialIdentificationSchema,
} from 'validators/createTrialSchemas';
import { loadStripe } from '@stripe/stripe-js';
import { FieldTrial } from 'types/workspace.type';
import { CreateTrialFormValues } from 'types/createTrial.type';
import getOption from './helpers/getOption';
import TestAreasContainer from 'containers/create-trial/TestAreas';
import TrialBoundaryContainer from 'containers/create-trial/TrialBoundary';
import FormOnlyStepContainer from 'components/step-flow/FormOnlyStep';
import TrialTypeContainer from 'containers/create-trial/TrialType';
import PlacePlotsContainer from 'containers/create-trial/PlacePlots';
import {
  ABLineAdjustmentContainer,
  ABLineCreationContainer,
} from 'containers/create-trial/ABLine';
import { FlowStep, FormOnlyStepProps } from '../types/stepFlow.type';

export const initialFormValues: CreateTrialFormValues = {
  offset: 0,
  product: 'Mycozene',
  crop: 'Corn',
  variety: 'Mycozene',
  length_ft: 300,
  nominal_management_prop: 65,
  prop_keep: 75,
  buffer_ft: 120,
  seed: 1,
  field_name: 'Field 1',
  farm_name: '',
  business_name: '',
  width_ft: 120,
  trial_type: 'plots',
  abline_alignment: 'center',
  treatment_labels: 'Use rates',
  treatment_rates: '0, 10',
  nominal_rate: 0,
  nominal_label: '',
  control_index: 0,
  abline_gdf: [],
  field_boundary_gdf: [],
  trial_boundary_gdf: [],
};

export const getTrialFormValues = (
  trial?: FieldTrial,
): CreateTrialFormValues => {
  if (!trial) return initialFormValues;

  return {
    ...initialFormValues,
    offset: (trial.offset || 0) * 100,
    product: trial.product,
    length_ft: trial.length_ft,
    nominal_management_prop: (trial.nominal_management_prop || 0.65) * 100,
    field_name: trial.field?.field_name,
    business_name: trial.field?.business_name || '',
    farm_name: trial.field?.farm_name || '',
    prop_keep: (trial.prop_keep || 0.75) * 100,
    buffer_ft: trial.buffer_ft,
    seed: trial.seed,
    width_ft: trial.width_ft,
    trial_type: trial.trial_type,
    abline_alignment: trial.abline_alignment,
    treatment_labels: trial.treatment_labels.replaceAll(',', ', '),
    treatment_rates: trial.treatment_rates.replaceAll(',', ', '),
    nominal_rate: trial.nominal_rate,
    nominal_label: trial.nominal_label || '',
    application_units: trial.application_units || '',
    control_index: trial.control_index,
    abline_files_meta: trial.abline_files_meta,
    trial_boundary_files_meta: trial.trial_boundary_files_meta,
  };
};

export const demoFiles = {
  field_boundary_gdf: [
    'field-boundary.shx',
    'field-boundary.shp',
    'field-boundary.dbf',
  ],
  // abline_gdf: ['ab-line.shp', 'ab-line.shx', 'ab-line.dbf'],
};

export const trialFileFields = ['field_boundary_gdf', 'trial_boundary_gdf'];

export const redirectToCheckout = async (key: string, sessionId: string) => {
  const stripe = await loadStripe(key);

  try {
    stripe?.redirectToCheckout({
      sessionId,
    });
  } catch (err) {
    if (window.history.length === 1) window.close();
    else window.history.back();
  }
};
// if date is Aug 15 or before, use 12/31 of current year as default
// else use 12/31 of next year as default
const getDefaultEndDate = () => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();
  const defaultYear = month <= 7 ? year : year + 1;
  return `${defaultYear}-12-31`;
};

export const trialIdentificationProps: FlowStep['props'] = {
  uniqueKey: 'trial_identification',
  initialValues: {
    business_name: '',
    farm_name: '',
    field_name: '',
    start_date: new Date().toISOString().split('T')[0],
    end_date: getDefaultEndDate(),
  },
  formElements: [
    {
      name: 'business_name',
      label: 'Business Name:',
      tooltip: 'Can be business name or grower name',
    },
    { name: 'farm_name', label: '(Optional) Farm Name:' },
    { name: 'field_name', label: 'Field Name:' },
    {
      name: 'start_date',
      label: 'Approx. Start Date (Optional):',
      type: 'date',
    },
    {
      name: 'end_date',
      label: 'Approx. End Date (Optional):',
      type: 'date',
    },
  ],
  validationSchema: trialIdentificationSchema,
};

export const trialTypeOptions = {
  default: ['Planting', 'Pest control', 'Fertilizer'].map((value) =>
    getOption(value),
  ),
  planting: ['Seeding rate', 'Variety/Hybrid'].map((value) => getOption(value)),
  pestControl: ['Herbicide', 'Fungicide', 'Insecticide'].map((value) =>
    getOption(value),
  ),
  equipment: ['Planter', 'Fertilizer applicator', 'Sprayer'].map((value) =>
    getOption(value),
  ),
  units: ['seed/ac', 'kseed/ac', 'gal/ac', 'lb/ac', 'lb N/ac'].map((value) =>
    // pass value as label so it doesn't get capitalized
    getOption(value, value),
  ),
  nutrients: [
    { name: 'Nitrogen', symbol: 'N' },
    { name: 'Phosphorus', symbol: 'P' },
    { name: 'Potassium', symbol: 'K' },
    { name: 'Calcium', symbol: 'Ca' },
    { name: 'Magnesium', symbol: 'Mg' },
    { name: 'Sulfur', symbol: 'S' },
    { name: 'Iron', symbol: 'Fe' },
    { name: 'Manganese', symbol: 'Mn' },
    { name: 'Copper', symbol: 'Cu' },
    { name: 'Zinc', symbol: 'Zn' },
    { name: 'Boron', symbol: 'B' },
    { name: 'Molybdenum', symbol: 'Mo' },
  ].map(({ name, symbol }) => getOption(name, `${name} (${symbol})`)),
};

export const treatmentProps: FormOnlyStepProps = {
  uniqueKey: 'treatments',
  initialValues: {
    application_units: '',
    treatment_rates: '0, 10, 20',
    nominal_rate: 0,
    nominal_label: '',
    control_index: 0,
    labels: 'Use rates',
  },
  formElements: [
    {
      element: 'select',
      name: 'application_units',
      label: 'Units:',
      direction: 'row',
      options: trialTypeOptions.units,
      allowOther: true,
      otherInputLabel: 'New unit:',
      rightElement: '/ac',
    },
    {
      name: 'treatment_rates',
      label: 'Rates:',
      subLabel: '(separated by commas)',
      tooltip: 'Expressed in the above units',
    },
    {
      name: 'treatment_labels',
      label: '(Optional) Labels:',
      subLabel: '(separated by commas)',
      tooltip:
        'When provideed, if any of the labels match the nominal label, then the corresponding treatment rate must match the nominal rate',
    },
    {
      name: 'nominal_rate',
      label: 'Nominal rate:',
      tooltip:
        'When the nominal label matches a treatment label, the nominal rate must match the corresponding treatment rate',
    },
    {
      name: 'nominal_label',
      label: 'Nominal label:',
      tooltip: 'Required when treatment labels are provided',
    },
  ],
  validationSchema: treatmentSchema,
};

const defaultSteps: FlowStep[] = [
  {
    heading: 'Treatments',
    container: FormOnlyStepContainer,
    props: treatmentProps,
  },
  {
    heading: 'Test Areas',
    container: TestAreasContainer,
  },
  {
    heading: 'Place Plots',
    container: PlacePlotsContainer,
  },
];

export const createTrialSteps: FlowStep[] = [
  {
    heading: 'Trial Identification',
    subheading: 'Which field will be tested on?',
    container: FormOnlyStepContainer,
    props: trialIdentificationProps,
  },
  {
    heading: 'Trial Boundary',
    container: TrialBoundaryContainer,
  },
  {
    heading: 'AB Line Creation',
    container: ABLineCreationContainer,
  },
  {
    heading: 'AB Line Adjustment',
    container: ABLineAdjustmentContainer,
  },
  {
    heading: 'Trial Type',
    container: TrialTypeContainer,
  },
  ...defaultSteps,
];

export const guestCreateTrialSteps: FlowStep[] = [
  {
    heading: 'Trial Boundary',
    container: TrialBoundaryContainer,
  },
  {
    heading: 'AB Line Creation',
    container: ABLineCreationContainer,
  },
  {
    heading: 'AB Line Adjustment',
    container: ABLineAdjustmentContainer,
  },
  ...defaultSteps,
];
