import { SummaryFieldProps } from '../../types/stepFlow.type';
import { ReactElement } from 'react';

export function objectToFormData(
  obj: any,
  parentKey?: string,
  formData = new FormData(),
): FormData {
  // Convert an object to FormData. Finds any arrays and duplicates the key for each element in the array.
  // This is to handle File arrays for shapefiles the way the APIs expect.
  if (obj === null) {
    return formData;
  }
  if (obj.constructor === Array) {
    obj.forEach((element: any, index: number) => {
      objectToFormData(element, parentKey, formData);
    });
  } else if (
    typeof obj === 'object' &&
    !(obj instanceof File) &&
    !(obj instanceof Date)
  ) {
    Object.keys(obj).forEach((key) => {
      let element = obj[key];
      if (element === undefined) {
        return; // skip undefined
      }
      objectToFormData(
        element,
        parentKey ? `${parentKey}.${key}` : key,
        formData,
      );
    });
  } else {
    if (!['', undefined].includes(obj)) {
      formData.append(parentKey ?? '', obj);
    }
  }
  return formData;
}

interface SummaryFieldParams {
  label: string;
  summaryValue:
    | string
    | number
    | undefined
    | null
    | File[]
    | string[]
    | ReactElement;
  unitsField?: string | number;
  customUnit?: string;
}

export function generateSummaryField(
  params: SummaryFieldParams,
): SummaryFieldProps {
  // Assemble a SummaryField from various combinations of fields.
  let value;
  if (Array.isArray(params.summaryValue)) {
    if (params.summaryValue.length === 0) {
      // replace empty array with None
      value = 'None';
    }
    // Check if first element is a string
    else if (typeof params.summaryValue[0] === 'string') {
      // If it's a string, just use the string directly
      value = params.summaryValue[0];
    } else {
      // If it's not a string, then it's a File, get the filename
      value = (params.summaryValue[0] as File).name.split('.')[0];
    }
  } else if (
    typeof params.summaryValue !== 'number' &&
    typeof params.summaryValue !== 'object' &&
    [undefined, '', null, 'none'].includes(params.summaryValue)
  ) {
    value = 'None';
  } else if (params.unitsField !== undefined) {
    value = `${params.summaryValue || 0} ${
      params.customUnit || (params.unitsField === 'm' ? 'meters' : 'feet')
    }`;
  } else if (params.customUnit !== undefined) {
    value = `${params.summaryValue} ${params.customUnit}`;
  } else if (typeof params.summaryValue === 'object') {
    // pass the shapefile link straight through
    value = params.summaryValue;
  } else {
    value = `${params.summaryValue}`;
  }

  return {
    element: 'summaryField',
    label: params.label,
    value: value || 'None',
  };
}

export const timestampToDate = (timestamp: any) => {
  // Convert an integer Unix timestamp to JS Date, then format as date string.
  // JS expects milliseconds, so we have to multiply by 1000.
  if (typeof timestamp !== 'number') {
    // a date string or date was passed in
    return timestamp;
  }
  const date = new Date(timestamp * 1000);
  const result = date.toISOString().split('T')[0];
  if (result === 'Invalid Date') {
    return 'Not provided';
  } else {
    return result;
  }
};
