import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useEffect } from 'react';
import StepPagination from './subcomponents/StepPagination';
import styles from './createStepContent.module.scss';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  FlowStep,
  FormOnlyStepProps,
  Step,
} from '../../../types/stepFlow.type';

export interface StepContentProps {
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  updateStateFunction: ActionCreatorWithPayload<Step, any>;
  stateSelector: (state: any) => any;
  flowSteps: FlowStep[];
}

interface StepContentComponentProps extends StepContentProps {
  heading: string;
  subheading: string | undefined;
  superheading?: string;
  Container: React.ComponentType<any>;
  props: any;
}

// This is the entry point for generic "step flows" of the kind we use in Create Trial.
// See CreateTrialStep for the type of wrapper needed to invoke it with step contents and state.

export const StepContent: React.FC<StepContentComponentProps> = ({
  heading,
  subheading,
  superheading,
  Container,
  props,
  activeIndex,
  setActiveIndex,
  updateStateFunction,
  stateSelector,
  flowSteps,
}) => (
  <div className={styles['flow-step']} data-testid="flow-step">
    <div>
      {superheading ? (
        <p
          className={styles['flow-step__superheading']}
          data-testid="flow-step__superheading"
        >
          {superheading}
        </p>
      ) : null}
      <h1
        className={styles['flow-step__heading']}
        data-testid="flow-step__heading"
      >
        {heading}
      </h1>
      {subheading ? (
        <h2
          className={styles['flow-step__subheading']}
          data-testid="flow-step__subheading"
        >
          {subheading}
        </h2>
      ) : null}
    </div>
    <Container {...(props as FormOnlyStepProps)}>
      <CreateStepContent
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        updateStateFunction={updateStateFunction}
        stateSelector={stateSelector}
        flowSteps={flowSteps}
      />
    </Container>
  </div>
);

export const CreateStepContent = ({
  activeIndex,
  setActiveIndex,
  updateStateFunction,
  stateSelector,
  flowSteps,
}: StepContentProps) => {
  const { values, submitForm, setValues, validateForm } =
    useFormikContext<Record<string, any>>();
  const { steps } = useAppSelector(stateSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (steps[activeIndex]) {
      setValues(steps[activeIndex]);
    }
  }, [activeIndex, setValues, steps]);

  const submitAndUpdateStep = (callback?: () => void) => {
    submitForm();
    validateForm(values).then((errors) => {
      const isValid = Object.keys(errors).length === 0;

      if (isValid) {
        dispatch(
          updateStateFunction({
            index: activeIndex,
            data: values,
          }),
        );
        if (callback) callback();
      } else if (errors.test === 'error') {
        // we do this in a test, this removes the log output from test output
      } else {
        // Todo: find a way to emit these sensibly
        /* eslint-disable no-console */
        console.log('Formik found validation errors:');
        console.log(errors);
        console.log('Values provided were:');
        console.log(values);
        /* eslint-enable no-console */
      }
    });
  };

  return (
    <StepPagination
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      updateStateFunction={updateStateFunction}
      submitAndUpdateStep={submitAndUpdateStep}
      stateSelector={stateSelector}
      flowSteps={flowSteps}
    />
  );
};
