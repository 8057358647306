import { getStorageValue } from 'hooks/useStorage';
import { StorageMethod } from 'types/global.type';

const getStorageMethod = (): StorageMethod => {
  let rememberMe = getStorageValue<boolean>('rememberMe');
  if (typeof rememberMe !== 'boolean') {
    rememberMe = getStorageValue<boolean>('rememberMe', 'session');
  }

  return rememberMe ? 'local' : 'session';
};

export default getStorageMethod;
