import { useState } from 'react';
import { Routes } from 'types/global.type';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from 'assets/vectors/logout.svg';
import { ReactComponent as ProfileIcon } from 'assets/vectors/profile.svg';
import useAuth from 'hooks/useAuth';
import Menu from 'components/global/Menu';
import Button from 'components/global/Button';
import MenuButton from 'components/global/Menu/subcomponents/MenuButton';
import styles from './profileMenu.module.scss';

const ProfileMenu = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { user, logoutLoading, fetchProfile, logout } = useAuth();

  const navigate = useNavigate();

  const openMenu = () => {
    setMenuOpen(true);
    fetchProfile();
  };
  const closeMenu = () => setMenuOpen(false);

  const avatarUrl = `https://ui-avatars.com/api/?name=${
    user?.full_name || user?.email
  }&background=4A5568&color=fff&font-size=0.4`;

  return (
    <div className={styles['profile-menu']} data-testid="profile-menu">
      <Menu
        isOpen={menuOpen}
        onClose={closeMenu}
        button={
          <Button
            action="button"
            variant="icon"
            onMouseOver={openMenu}
            data-testid="profile-menu__button"
          >
            <img
              alt="User Avatar"
              src={avatarUrl}
              className={styles['profile-menu__avatar']}
            />
          </Button>
        }
      >
        <div
          className={styles['profile-menu__header']}
          data-testid="profile-menu__header"
        >
          <img
            src={avatarUrl}
            alt="User Avatar"
            className={styles['profile-menu__avatar']}
            data-testid="profile-menu__avatar"
          />
          <div>
            <p>{user?.full_name || ''}</p>
            <p>{user?.email}</p>
          </div>
        </div>
        {user?.category === 'regular' && (
          <div className={styles['profile-menu__header']}>
            <h3 data-testid="trial-credits__header">
              Trial credits: {user?.trial_credits}
            </h3>
          </div>
        )}
        <MenuButton
          icon={<ProfileIcon />}
          onClick={() => {
            navigate(Routes.editAccount);
            closeMenu();
          }}
        >
          Edit Account
        </MenuButton>
        <MenuButton
          icon={<LogoutIcon />}
          isLoading={logoutLoading}
          onClick={() => logout()}
          data-testid="logout-button"
        >
          Logout
        </MenuButton>
      </Menu>
    </div>
  );
};

export default ProfileMenu;
