import { NavLink } from 'react-router-dom';
import { DrawerNavLink as NavLinkProps } from 'types/global.type';
import styles from './drawerNavLink.module.scss';

interface DrawerNavLinkProps extends NavLinkProps {
  closeDrawer: () => void;
}

const DrawerNavLink = ({ title, to, closeDrawer }: DrawerNavLinkProps) => {
  const isRouteExternal = to.startsWith('https');

  const props = { onClick: closeDrawer };

  return isRouteExternal ? (
    <a href={to} className={styles['drawer-navlink']} {...props}>
      {title}
    </a>
  ) : (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `${styles['drawer-navlink']} ${
          isActive ? styles['drawer-navlink--active'] : ''
        }`
      }
      end
      {...props}
    >
      {title}
    </NavLink>
  );
};

export default DrawerNavLink;
