import { useEffect, useMemo, useState } from 'react';
import { Column, useSortBy, useTable } from 'react-table';
import styles from './table.module.scss';

interface TableProps {
  data: any[];
  columns: Column<any>[];
  colsWithSort?: string[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const Table = ({
  columns,
  data,
  colsWithSort,
  activeIndex,
  setActiveIndex,
}: TableProps) => {
  const sortColsBy = useMemo(() => {
    return (
      colsWithSort?.map((col) => ({
        id: col,
        desc: true,
      })) || []
    );
  }, [colsWithSort]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<any>(
      {
        columns,
        data,
        //@ts-ignore
        initialState: { sortBy: sortColsBy },
        disableSortRemove: true,
      },
      useSortBy,
    );

  const [activeSelected, setActiveSelected] = useState(false);

  useEffect(() => {
    //set initial active row to first displayed row
    if (rows.length && !activeSelected) {
      setActiveIndex(rows[0].index);
      setActiveSelected(true);
    }
  }, [rows, setActiveIndex, activeSelected]);

  return (
    <div className={styles['table__container']} data-testid="table__container">
      <table
        className={styles['table']}
        {...getTableProps()}
        data-testid="table"
      >
        <thead className={styles['table__head']} data-testid="table__head">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => {
                const sortEnabled = colsWithSort?.includes(column?.id);
                const isSortedDesc = (column as any)?.isSortedDesc;

                return (
                  <th
                    {...column?.getHeaderProps(
                      sortEnabled
                        ? (column as any)?.getSortByToggleProps({
                            title: `Sort by ${column.Header}`,
                          })
                        : undefined,
                    )}
                    // note that this has no effect because table-layout is not fixed in our SCSS
                    style={
                      column?.width
                        ? { width: column.width }
                        : { width: 'unset' }
                    }
                  >
                    {column?.render('Header')}

                    {sortEnabled ? (
                      <span
                        className={`${styles['table__head__col']} ${
                          isSortedDesc ? styles['table__head__col--sorted'] : ''
                        }`}
                      >
                        ↑
                      </span>
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody
          className={styles['table__body']}
          {...getTableBodyProps()}
          data-testid="table__body"
        >
          {rows?.map((row) => {
            prepareRow(row);
            const isActive = activeIndex === row.index;

            return (
              <tr
                {...row?.getRowProps()}
                className={`${styles['table__body__row']} ${
                  isActive ? styles['table__body__row--active'] : ''
                }`}
                onClick={() => {
                  if (!isActive) setActiveIndex(row.index);
                }}
              >
                {row?.cells?.map((cell) => {
                  return (
                    <td {...cell?.getCellProps()}>{cell?.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
