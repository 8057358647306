import { Formik } from 'formik';
import { trialBoundarySchema } from 'validators/createTrialSchemas';
import TrialBoundaryForm from 'components/create-trial/TrialBoundaryForm';

import { StepProps } from '../../types/stepFlow.type';

const TrialBoundaryContainer = ({ children }: StepProps) => {
  return (
    <Formik
      initialValues={{
        field_boundary_gdf: [],
        trial_boundary_is_field_boundary: true,
        trial_boundary_gdf: [],
      }}
      onSubmit={() => {}}
      validationSchema={trialBoundarySchema}
      validateOnMount
    >
      <>
        <TrialBoundaryForm />
        {children}
      </>
    </Formik>
  );
};

export default TrialBoundaryContainer;
