import { useEffect, useRef } from 'react';
import { Form, useFormikContext } from 'formik';
import { clearStep } from 'redux/slices/createTrial';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { createTrialSteps, guestCreateTrialSteps } from 'utils/createTrial';
import useAuth from 'hooks/useAuth';
import isEqual from 'utils/helpers/isEqual';
import Tooltip from 'components/global/Tooltip';
import FileUploadModal from '../FileUploadModal';
import Checkbox from 'components/global/Checkbox';
import styles from './trialBoundaryForm.module.scss';

const TrialBoundaryForm = () => {
  const { trial } = useAppSelector((state) => state.createTrial);
  const { values, setFieldValue, isSubmitting } =
    useFormikContext<Record<string, any>>();
  const { isGuest } = useAuth();
  const dispatch = useAppDispatch();

  const trialSteps = isGuest ? guestCreateTrialSteps : createTrialSteps;

  useEffect(() => {
    const addTrialFileNames = (metaName: string) => {
      if (trial[metaName]) setFieldValue(metaName, trial[metaName]);
    };

    addTrialFileNames('field_boundary_files_meta');
    addTrialFileNames('trial_boundary_files_meta');
  }, [trial, setFieldValue]);

  useEffect(() => {
    if (isGuest) {
      setFieldValue('trial_boundary_is_field_boundary', true);
    }
  }, [isGuest, setFieldValue]);

  const trialRef = useRef(trial);

  useEffect(() => {
    trialRef.current = trial;
  }, [trial]);

  useEffect(() => {
    //reset abline step once field boundary changes
    if (
      isSubmitting &&
      (!isEqual(values.field_boundary_gdf, trial.field_boundary_gdf) ||
        !isEqual(values.trial_boundary_gdf, trial.trial_boundary_gdf))
    ) {
      const ablineKeys = [
        'abline_preview',
        'transactionId',
        'get_abline_method',
        'abline_rotation',
        'abline_adjust',
        'abline_heading',
        'abline_gdf',
      ];
      const updatedTrial = { ...trialRef.current };

      for (const key of ablineKeys) {
        delete updatedTrial[key];
      }

      dispatch(
        clearStep({
          index: trialSteps.findIndex(({ heading }) => heading === 'AB Line'),
          data: updatedTrial,
        }),
      );
    }
  }, [
    isSubmitting,
    trial.field_boundary_gdf,
    trial.trial_boundary_gdf,
    values.field_boundary_gdf,
    values.trial_boundary_gdf,
    dispatch,
    trialSteps,
  ]);

  useEffect(() => {
    if (values.trial_boundary_is_field_boundary) {
      setFieldValue('trial_boundary_gdf', []);
    }
  }, [values.trial_boundary_is_field_boundary, setFieldValue]);

  return (
    <Form className={styles['trial-boundary-form']}>
      <FileUploadModal
        name="field_boundary_gdf"
        metaName="field_boundary_files_meta"
        title="field boundary"
        fileDescription="Select the field boundary shapefile."
      />

      <Tooltip
        label={
          isGuest
            ? 'Please create an account to use a trial boundary that is different from the field boundary. This functionality is disabled without a user account.'
            : ''
        }
      >
        <Checkbox
          id="trial_boundary_is_field_boundary"
          label="The trial boundary is the same as the field boundary"
          checked={values.trial_boundary_is_field_boundary || isGuest}
          onChange={() =>
            setFieldValue(
              'trial_boundary_is_field_boundary',
              !values.trial_boundary_is_field_boundary,
            )
          }
          disabled={isGuest}
        />
      </Tooltip>

      <FileUploadModal
        name="trial_boundary_gdf"
        metaName="trial_boundary_files_meta"
        title="trial boundary"
        fileDescription="Select the trial boundary shapefile."
        isDisabled={values.trial_boundary_is_field_boundary || isGuest}
      />
    </Form>
  );
};

export default TrialBoundaryForm;
