import { Form, useFormikContext } from 'formik';
import { trialTypeOptions } from 'utils/createTrial';
import Select from 'components/global/Select';
import Checkbox from 'components/global/Checkbox';
import FormElement from 'components/global/FormElement';
import CustomSelect from 'components/global/CustomSelect';
import styles from './trialTypeForm.module.scss';

import { FormElementType } from '../../../types/stepFlow.type';

const TrialTypeForm = () => {
  const { values, initialValues, setFieldValue, setValues } =
    useFormikContext<Record<string, any>>();

  const subtypes = ['Planting', 'Pest control'];
  const getSubtypeOptions = () => {
    let key: keyof typeof trialTypeOptions;

    switch (values.planning_trial_type) {
      case 'Planting':
        key = 'planting';
        break;
      case 'Pest control':
      default:
        key = 'pestControl';
    }

    return trialTypeOptions[key];
  };

  const resetOtherInputs = (event: React.ChangeEvent<FormElementType>) => {
    setValues({
      ...initialValues,
      planning_trial_type: event.target.value,
    });
  };

  const handleVarietyChange = (event: React.ChangeEvent<FormElementType>) => {
    if (values.planning_trial_subtype === 'Seeding rate') {
      setFieldValue('product', event.target.value);
    }
  };

  return (
    <Form className={styles['trial-type-form']}>
      <CustomSelect
        name="planning_trial_type"
        extraClassName={styles['trial-type-form__select']}
        label="What trial type are you planning?"
        options={trialTypeOptions.default}
        allowOther={true}
        onChangeCallback={resetOtherInputs}
      />

      {subtypes.includes(values.planning_trial_type) && (
        <CustomSelect
          name="planning_trial_subtype"
          label="What trial subtype are you planning?"
          extraClassName={styles['trial-type-form__select']}
          allowOther={true}
          options={getSubtypeOptions()}
        />
      )}

      {values.planning_trial_type === 'Fertilizer' && (
        <Select
          name="test_nutrients"
          label="What nutrient(s) will be tested?"
          options={trialTypeOptions.nutrients}
          isMulti
          creatable
        />
      )}

      {values.planning_trial_subtype ||
      values.test_nutrients?.length ||
      values['planning_trial_type-other'] ||
      values['planning_trial_subtype-other'] ||
      values.product ? (
        <>
          <div className={styles['trial-type-form__product']}>
            <FormElement name="crop" label="Crop" type="text" />
            <FormElement
              name="variety"
              label="Variety"
              type="text"
              tooltip="This is used to complete your report"
              onChangeCallback={handleVarietyChange}
            />
          </div>
          <div className={styles['trial-type-form__product']}>
            <FormElement
              name="product"
              label="What product(s) will you test?"
            />
            <Checkbox
              id="is_microbe_test"
              label="This is a microbe test"
              checked={values.is_microbe_test}
              onChange={() =>
                setFieldValue('is_microbe_test', !values.is_microbe_test)
              }
            />
          </div>
        </>
      ) : null}

      {values.product || values.trial_equipment ? (
        <CustomSelect
          name="trial_equipment"
          label="What equipment will you use to execute this trial?"
          extraClassName={styles['trial-type-form__select']}
          options={trialTypeOptions.equipment}
          allowOther={true}
        />
      ) : null}
    </Form>
  );
};

export default TrialTypeForm;
