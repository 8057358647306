import { Formik } from 'formik';
import { useEffect } from 'react';
import { DefaultAPIResponse } from 'types/global.type';
import { ChangePasswordRequest } from 'types/auth.type';
import { changePasswordSchema } from 'validators/authSchemas';
import toast from 'react-hot-toast';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import ChangePasswordForm from 'components/auth/ChangePasswordForm';

const ChangePassword = () => {
  const [{ data, isLoading, error }, changePassword] = useNetworkRequest<
    ChangePasswordRequest,
    DefaultAPIResponse
  >('/profile/password', {
    method: 'PUT',
  });

  useEffect(() => {
    if (data) toast.success('Password updated successfully!');
  }, [data]);

  return (
    <Formik
      initialValues={{
        old_password: '',
        new_password: '',
        confirm_password: '',
      }}
      onSubmit={(values) => changePassword(values)}
      validationSchema={changePasswordSchema}
    >
      <ChangePasswordForm
        loading={isLoading}
        error={getErrorMessage(error)}
        successMsg={data?.msg}
      />
    </Formik>
  );
};

export default ChangePassword;
