import * as Yup from 'yup';

const email = Yup.string()
  .email('Email must be a valid email address')
  .required('Required');

const password = Yup.string()
  .required('Required')
  .min(8, 'Password must contain at least 8 characters');

const getConfirmPasswordSchema = (ref = 'password') =>
  password.oneOf([Yup.ref(ref), null], 'Passwords must match');

export const loginSchema = Yup.object({
  email,
  password,
});

export const signUpSchema = Yup.object({
  email,
  firstName: Yup.string(),
  lastName: Yup.string(),
  password,
  confirmPassword: getConfirmPasswordSchema(),
});

export const forgotPasswordSchema = Yup.object({
  email,
});

export const resetPasswordSchema = Yup.object({
  password,
  confirmPassword: getConfirmPasswordSchema(),
});

export const updateProfileSchema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
});

export const changePasswordSchema = Yup.object({
  old_password: password,
  new_password: password,
  confirm_password: getConfirmPasswordSchema('new_password'),
});
