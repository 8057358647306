import { AuthState } from 'types/auth.type';
import { Routes } from 'types/global.type';

export const initialAuthValues: AuthState = {
  isLoggedIn: null,
  token: '',
  refreshToken: '',
  user: null,
  isGuest: false,
};

export const authRoutes: Routes[] = [
  Routes.login,
  Routes.forgotPassword,
  Routes.signUp,
  Routes.verifyAccount,
  Routes.resetPassword,
];

export const authFooterRedirects = {
  login: {
    description: 'Have an account already?',
    cta: 'Login',
    path: Routes.login,
  },
  signUp: {
    description: "Don't have an account yet?",
    cta: 'Create an account',
    path: Routes.signUp,
  },
  requestVerification: {
    description: "Haven't verified your account yet?",
    cta: 'Verify',
    path: Routes.verifyAccount,
  },
  forgotPassword: {
    description: 'Forgot password?',
    cta: 'Reset',
    path: Routes.forgotPassword,
  },
};
