import { Formik } from 'formik';
import { forgotPasswordSchema } from 'validators/authSchemas';
import { ForgotPasswordRequest, ForgotPasswordResponse } from 'types/auth.type';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';

const ForgotPasswordContainer = () => {
  const [{ data, isLoading, error }, forgotPassword] = useNetworkRequest<
    ForgotPasswordRequest,
    ForgotPasswordResponse
  >('/forgot-password', {
    method: 'POST',
  });

  const handleSubmit = (values: ForgotPasswordRequest) => {
    forgotPassword(values);
  };

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={handleSubmit}
      validationSchema={forgotPasswordSchema}
    >
      <ForgotPasswordForm
        loading={isLoading}
        error={getErrorMessage(error)}
        successMsg={data?.msg || ''}
      />
    </Formik>
  );
};

export default ForgotPasswordContainer;
