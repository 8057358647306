import { Formik } from 'formik';
import { Link, useSearchParams } from 'react-router-dom';
import { LoginResponse, ResetPasswordRequest } from 'types/auth.type';
import { resetPasswordSchema } from 'validators/authSchemas';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';
import { Routes } from 'types/global.type';

const ResetPasswordContainer = () => {
  const [{ data, isLoading, error }, resetPassword] = useNetworkRequest<
    ResetPasswordRequest,
    LoginResponse
  >('/reset-password', {
    method: 'POST',
  });
  const [searchParams] = useSearchParams();

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={(values) => {
        resetPassword({
          token: searchParams.get('token') || '',
          password: values.password,
        });
      }}
      validationSchema={resetPasswordSchema}
    >
      <ResetPasswordForm
        loading={isLoading}
        error={getErrorMessage(error)}
        successMsg={
          data ? (
            <>
              Password changed successfully! Please{' '}
              <Link to={Routes.login}>login</Link> to continue using your
              account.
            </>
          ) : (
            ''
          )
        }
      />
    </Formik>
  );
};

export default ResetPasswordContainer;
