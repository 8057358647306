import { ReactComponent as FileIcon } from 'assets/vectors/file.svg';
import { ReactComponent as CloseIcon } from 'assets/vectors/close.svg';
import Button from 'components/global/Button';
import styles from './uploadedFile.module.scss';
import getFileExtension from 'utils/helpers/getFileExtension';

interface UploadedFileProps {
  file: File;
  isPlaceholder: boolean;
  removeFile: () => void;
}

const UploadedFile = ({
  file,
  isPlaceholder,
  removeFile,
}: UploadedFileProps) => {
  const fileExtension = getFileExtension(file.name);

  return (
    <li
      className={`${styles['uploaded-file']} ${
        isPlaceholder ? styles['uploaded-file--placeholder'] : ''
      }`}
      data-testid="uploaded-file"
    >
      <div className={styles['uploaded-file__details']}>
        <div className={styles['uploaded-file__icon']}>
          <FileIcon />
          {isPlaceholder ? null : (
            <span data-testid="file-extension">
              {fileExtension.toUpperCase()}
            </span>
          )}
        </div>
        <div>
          <p className={styles['uploaded-file__name']} data-testid="file-name">
            {file.name.length > 40
              ? `${file.name.slice(0, 40)}....${fileExtension}`
              : file.name}
          </p>
          {isPlaceholder && ['dbf', 'shx', 'shp'].includes(fileExtension) ? (
            <p
              className={styles['uploaded-file__error']}
              data-testid="file-error"
            >{`To upload a shapefile, the .${fileExtension} file is needed.`}</p>
          ) : null}
          {isPlaceholder && ['cpg', 'prj'].includes(fileExtension) ? (
            <p
              className={styles['uploaded-file__warning']}
              data-testid="file-warning"
            >{`.${fileExtension} is optional, but if you have one it should be uploaded.`}</p>
          ) : null}
        </div>
      </div>
      {isPlaceholder ? null : (
        <Button
          action="button"
          variant="icon"
          onClick={removeFile}
          className={styles['uploaded-file__remove-button']}
          data-testid="remove-button"
        >
          <CloseIcon />
        </Button>
      )}
    </li>
  );
};

export default UploadedFile;
