import { Routes } from 'types/global.type';
import SEO from 'components/global/SEO';
import UpdateProfile from 'containers/auth/UpdateProfile';
import ChangePassword from 'containers/auth/ChangePassword';
import AuthFormWrapper from 'components/auth/AuthFormWrapper';

const EditAccountPage = () => {
  return (
    <>
      <SEO prefix="Edit Account" path={Routes.editAccount} />

      <AuthFormWrapper title="Update account details" mergePosition="top">
        <UpdateProfile />
      </AuthFormWrapper>

      <AuthFormWrapper
        title="Update security information"
        mergePosition="bottom"
      >
        <ChangePassword />
      </AuthFormWrapper>
    </>
  );
};

export default EditAccountPage;
