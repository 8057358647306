import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, User } from 'types/auth.type';
import { initialAuthValues } from 'utils/auth';
import getFullName from '../../utils/helpers/getFullName';

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthValues,
  reducers: {
    setAuthState: (_, action: PayloadAction<AuthState>) => action.payload,
    setUserProfile: (state, action: PayloadAction<User>) => {
      if (state.user === null) return;
      state.user = {
        ...action.payload,
        category: state.user.category,
        full_name: getFullName(action.payload),
      };
    },
  },
});

export const { setAuthState, setUserProfile } = authSlice.actions;

export default authSlice.reducer;
