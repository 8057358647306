import React from 'react';
import ReactDOM from 'react-dom';
import App from 'containers/App';
import ReduxProvider from 'containers/ReduxProvider';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
export { timestampToDate } from './utils/helpers/analyzeTrial';
