import ImagePreview from '../../create-trial/ImagePreview';
import FormOnlyStepContainer from '../FormOnlyStep';
import { FormAndImageStepProps } from '../../../types/stepFlow.type';
import styles from './formAndImageStep.module.scss';

const FormAndImageStep = ({
  imageUrl,
  imagePreviewElement,
  ...rest
}: FormAndImageStepProps) => {
  // Add an image to a FormOnlyStepContainer, which is a pattern we use a lot.
  // imagePreviewElement can be passed in to use a custom element, so that this
  // can be used as the core of a component which updates the image in response
  // to the form data.

  if (imageUrl && !imagePreviewElement) {
    // if we have an imageUrl and no custom imagePreviewElement, render the URL.
    imagePreviewElement = <ImagePreview imageUrl={imageUrl} error={null} />;
  }

  return (
    <div className={styles['form-step-and-image-container']}>
      <FormOnlyStepContainer {...rest} rightHalfElement={imagePreviewElement}>
        {rest.children}
      </FormOnlyStepContainer>
    </div>
  );
};

export default FormAndImageStep;
