import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AnalysisQueryResponse,
  AsAppliedSubmitResponse,
  YieldSubmitStructure,
  AnalysisSubmitStructure,
  FieldResultsQueryResponse,
  ShapefileUploadPayload,
  ShapefileUploadResponse,
  SimpleShapefileUploadPayload,
  SimpleShapefileUploadResponse,
} from '../../types/analyzeTrial.type';
import { getBaseQuery } from 'utils/config';
import { FieldTrial } from '../../types/workspace.type';
import { omit } from 'lodash';

const analysisApi = createApi({
  reducerPath: 'analysisApi',
  tagTypes: ['field-results', 'analyses'],
  baseQuery: getBaseQuery('analysis', 'analysis'),
  endpoints: (builder) => ({
    analysesByTrialId: builder.query<AnalysisQueryResponse, string>({
      query: (trial_id) => `/query/analysis/trial_id/${trial_id}`,
      providesTags: ['analyses'],
    }),
    fieldResultsByTrialId: builder.query<FieldResultsQueryResponse, string>({
      query: (trial_id) => `/query/field_result/trial_id/${trial_id}`,
      providesTags: ['field-results'],
    }),
    submitAsApplied: builder.mutation<AsAppliedSubmitResponse, FormData>({
      query: (asAppliedData) => ({
        url: '/submit/as_applied',
        method: 'POST',
        body:
          'as_applied_shapefiles' in asAppliedData
            ? omit(asAppliedData, 'as_applied_shapefiles')
            : asAppliedData,
      }),
      invalidatesTags: ['field-results'],
    }),
    uploadShapefile: builder.mutation<
      ShapefileUploadResponse,
      ShapefileUploadPayload
    >({
      query: (payload) => {
        const formData = new FormData();
        Object.keys(payload).forEach((key) => {
          if (key === 'shapefiles' && Array.isArray(payload[key])) {
            (payload[key] as File[]).forEach((file, index) => {
              formData.append(`${key}`, file);
            });
          } else {
            formData.append(key, payload[key]);
          }
        });
        return {
          url: '/upload/shapefiles',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['field-results'],
    }),
    extractDatesFromShapefile: builder.mutation<
      SimpleShapefileUploadResponse,
      SimpleShapefileUploadPayload
    >({
      query: (payload) => {
        const formData = new FormData();
        payload['shapefiles'].forEach((file, index) => {
          formData.append(`shapefiles`, file);
        });
        return {
          url: '/utils/extract_dates_from_shapefile',
          method: 'POST',
          body: formData,
        };
      },
    }),
    submitYield: builder.mutation<YieldSubmitStructure, FormData>({
      query: (yieldData) => ({
        url: '/submit/yield',
        method: 'POST',
        body:
          'yield_shapefiles' in yieldData
            ? omit(yieldData, 'yield_shapefiles')
            : yieldData,
      }),
      invalidatesTags: ['field-results'],
    }),
    submitAnalysis: builder.mutation<AnalysisSubmitStructure, FormData>({
      query: (analysisData) => {
        return {
          url: '/submit/analysis',
          method: 'POST',
          body: analysisData,
        };
      },
      invalidatesTags: ['analyses'],
    }),
    submitTrialDesign: builder.mutation<
      FieldTrial,
      { analysis_id: string; trial: FieldTrial }
    >({
      query: ({ analysis_id, trial }) => ({
        url: `/utils/save_trial_design/${analysis_id}`,
        method: 'POST',
        body: trial,
      }),
    }),
    requestReport: builder.mutation<
      AnalysisSubmitStructure,
      { analysis_id: string }
    >({
      query: (analysis_id) => ({
        url: `/utils/request_report/${analysis_id}`,
        method: 'POST',
      }),
      invalidatesTags: ['analyses'],
    }),
  }),
});

export const {
  useAnalysesByTrialIdQuery,
  useFieldResultsByTrialIdQuery,
  useSubmitAsAppliedMutation,
  useSubmitYieldMutation,
  useSubmitAnalysisMutation,
  useUploadShapefileMutation,
  useExtractDatesFromShapefileMutation,
  useSubmitTrialDesignMutation,
} = analysisApi;

export default analysisApi;
