import { Middleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import guestApi from './services/guest';
import paymentApi from './services/payment';
import workspaceApi from './services/workspace';
import analysisApi from './services/analysis';

let middleware: Middleware[] = [];

// only log in development mode
// in staging/prod NODE_ENV will be 'production'
// in tests it will be 'test' and we don't want the log output there as it's
// immensely verbose.
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(
      workspaceApi.middleware,
      paymentApi.middleware,
      guestApi.middleware,
      analysisApi.middleware,
    ),
    ...middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
