import { Link } from 'react-router-dom';
import { LoginRequest, SocialLoginType } from 'types/auth.type';
import { Form, useFormikContext } from 'formik';
import { FormProps, Routes } from 'types/global.type';
import Button from 'components/global/Button';
import Checkbox from 'components/global/Checkbox';
import SocialLink from './subcomponents/SocialLink';
import FormElement from 'components/global/FormElement';
import FormErrorMessage from 'components/global/FormErrorMessage';
import styles from './loginForm.module.scss';
import React, { useState, useEffect } from 'react';

const LoginForm = (props: FormProps) => {
  const { loading, error, successMsg } = props;
  const [message, setMessage] = useState<string | null>('');
  const { values, setFieldValue } = useFormikContext<LoginRequest>();

  const queryParams = new URLSearchParams(window.location.search);
  const myParam = queryParams.get('message');
  useEffect(() => {
    setMessage(myParam);
  }, [myParam]);

  const socialLoginOptions: SocialLoginType[] = ['Facebook', 'Google'];

  return (
    <>
      <Form data-testid="login-form">
        <FormErrorMessage error={error} variant="auth" />
        <FormErrorMessage
          error={successMsg || ''}
          variant="auth"
          type="success"
        />

        <div
          className={styles['login-form__social']}
          data-testid="login-form__social"
        >
          {socialLoginOptions.map((type) => (
            <SocialLink type={type} key={type} />
          ))}
        </div>

        <div
          className={styles['login-form__separator']}
          data-testid="login-form__separator"
        >
          <span></span>
          <p>OR</p>
          <span></span>
        </div>

        <FormElement
          name="email"
          type="email"
          variant="auth"
          placeholder="Email address"
        />
        <FormElement
          name="password"
          type="password"
          variant="auth"
          placeholder="Password"
        />

        <div
          className={styles['login-form__footer']}
          data-testid="login-form__footer"
        >
          <Checkbox
            id="remember"
            label="Remember me"
            onChange={() => setFieldValue('rememberMe', !values.rememberMe)}
          />
          <Link to={Routes.forgotPassword} data-testid="forgot-password">
            Forgot password?
          </Link>
        </div>

        <Button
          action="button"
          variant="brand"
          type="submit"
          isLoading={loading}
        >
          Login
        </Button>
      </Form>
      {message && (
        <div data-testid="error" style={{ color: 'red' }}>
          {message}
        </div>
      )}
    </>
  );
};

export default LoginForm;
