import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import getOption from 'utils/helpers/getOption';
import FormElement from '../FormElement';
import styles from './customSelect.module.scss';

import { Option, SelectProps } from '../../../types/stepFlow.type';

const CustomSelect = ({
  name,
  label,
  extraClassName,
  options,
  otherInputLabel,
  allowOther,
  ...rest
}: SelectProps) => {
  const { values, setValues } = useFormikContext<Record<string, any>>();
  const [isOtherInputActive, setIsOtherInputActive] = useState<boolean>(false);

  const otherName = `${name}-other`;
  const otherLabel = otherInputLabel || 'If Other, please describe:';

  useEffect(() => {
    if (values && values[name] === 'Other') {
      //show custom input when user selects 'Other'
      setValues({ ...values, [otherName]: 'Other', [name]: '' });
      setIsOtherInputActive(true);
    } else {
      setIsOtherInputActive(false);
    }

    if (values && values[otherName]) {
      if (values[otherName] === 'Other') {
        //show custom input if the value already selected is 'Other'
        setIsOtherInputActive(true);
      } else {
        //hide custom input when the value selected changes from 'Other'
        const newValues = { ...values };
        delete newValues[otherName];

        setValues({ ...newValues, [name]: values[otherName] });
        setIsOtherInputActive(false);
      }
    }
  }, [values, name, otherName, setValues]);

  return (
    <div
      className={`${styles['open-select']} ${
        isOtherInputActive ? styles['open-select--other-active'] : ''
      } ${extraClassName || ''}`}
      data-testid="open-select"
    >
      <FormElement
        element="select"
        name={isOtherInputActive ? otherName : name}
        className={
          isOtherInputActive ? 'custom-select-dropdown-other-open' : ''
        }
        label={label}
        options={[
          ...(options as Option[]),
          ...(allowOther ? [getOption('Other')] : []),
        ]}
        {...rest}
      />

      <div className={'custom-select-other-input'}>
        {isOtherInputActive ? (
          <FormElement name={name} label={otherLabel} />
        ) : null}
      </div>
    </div>
  );
};

export default CustomSelect;
