import { useState } from 'react';
import { createTrialSteps, guestCreateTrialSteps } from 'utils/createTrial';
import { updateStep } from '../../../redux/slices/createTrial';
import useAuth from 'hooks/useAuth';
import { StepContent } from '../../step-flow/CreateStepContent';

const CreateTrialStep = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isLoggedIn } = useAuth();

  const trialSteps = isLoggedIn ? createTrialSteps : guestCreateTrialSteps;
  const { heading, subheading, container, props } = trialSteps[activeIndex];

  return (
    <StepContent
      heading={heading}
      subheading={subheading}
      Container={container}
      props={props}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      updateStateFunction={updateStep}
      stateSelector={(state) => state.createTrial}
      flowSteps={trialSteps}
    />
  );
};

export default CreateTrialStep;
