import { useEffect } from 'react';
import { Routes } from 'types/global.type';
import { useAppDispatch } from 'redux/hooks';
import { updateTrialData } from 'redux/slices/createTrial';
import { useLazyFieldTrialQuery } from 'redux/services/workspace';
import SEO from 'components/global/SEO';
import CreateTrialStep from 'components/create-trial/CreateTrialStep';

const CreateTrialPage = () => {
  const [, { data: trial }] = useLazyFieldTrialQuery();
  const dispatch = useAppDispatch();

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-CA');
  };

  useEffect(() => {
    if (trial) {
      dispatch(
        updateTrialData({
          ...trial.data,
          ...trial.data.field,
          start_date: formatDate(trial.data.start_date),
          end_date: formatDate(trial.data.end_date),
          field_boundary_gdf: 'trial',
          trial_boundary_gdf: 'trial',
          nominal_management_prop: trial.data.nominal_management_prop * 100,
          prop_keep: trial.data.prop_keep * 100,
        }),
      );
    }
  }, [trial, dispatch]);

  return (
    <>
      <SEO prefix="Create Trial" path={Routes.createTrial} />
      <CreateTrialStep />
    </>
  );
};

export default CreateTrialPage;
