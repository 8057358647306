import ImagePreview from '../../create-trial/ImagePreview';
import notYetProvided from '../../../assets/images/not-yet-provided.png';
import FormAndImageStep from '../../step-flow/FormAndImageStep';
import { useState } from 'react';
import {
  FormElementPropsType,
  PlotBufferDirectEntryStepProps,
} from '../../../types/stepFlow.type';
import * as Yup from 'yup';
import { FieldData } from '../../../types/analyzeTrial.type';

// TODO: lock plot width and length to uneditable fields. Default buffers with tooltip
// explanation to calculated values per Nick. Make units swap visual display of
// plot length/width to meters if meters are selected.
const PlotBufferDirectEntryStep = ({
  trialPlotLength,
  trialPlotWidth,
  savedData,
  imageUrl,
  ...rest
}: PlotBufferDirectEntryStepProps) => {
  const dynamicImage = (
    <ImagePreview imageUrl={imageUrl || notYetProvided} error={null} />
  );
  const [overallDisplayUnits, setOverallDisplayUnits] = useState('Feet');
  const [overallValueUnits, setOverallValueUnits] = useState('ft');

  const getPlotBufferDirectEntryInitialValues = ({
    savedData,
  }: {
    savedData: FieldData | undefined | null;
  }) => {
    const baseValues = {
      plot_length: trialPlotLength,
      plot_width: trialPlotWidth,
    };

    // Default values only if we have no savedData
    let changeableValues = {
      plot_buffer_length: 0,
      plot_buffer_width: 0,
      plot_buffer_length_units: 'ft',
      plot_buffer_width_units: 'ft',
    };

    if (savedData) {
      changeableValues.plot_buffer_length = savedData.plot_buffer_length || 0;
      changeableValues.plot_buffer_width = savedData.plot_buffer_width || 0;
      changeableValues.plot_buffer_length_units =
        savedData.plot_buffer_length_units || 'ft';
      changeableValues.plot_buffer_width_units =
        savedData.plot_buffer_width_units || 'ft';
    }

    return {
      ...baseValues,
      ...changeableValues,
    };
  };

  const plotBufferDirectEntryInitialValues =
    getPlotBufferDirectEntryInitialValues({ savedData: savedData });

  const plotBufferDirectEntryValidationSchema = Yup.object().shape({
    plot_length: Yup.number()
      .required('Plot length is required')
      .positive('Plot length must be a positive number'),
    plot_buffer_length_units: Yup.string().required(
      'Plot buffer length units are required',
    ),
    plot_buffer_length: Yup.number().required('Plot buffer length is required'),
    plot_width: Yup.number()
      .required('Plot width is required')
      .positive('Plot width must be a positive number'),
    plot_buffer_width: Yup.number().required('Plot buffer width is required'),
    plot_buffer_width_units: Yup.string().required(
      'Plot buffer width units are required',
    ),
  });

  const PlotBufferDirectEntryFields: FormElementPropsType[] = [
    {
      element: 'sectionHeading',
      label:
        'How much length from the start and the end of each plot should we buffer out and exclude from the analysis?',
    },
    {
      element: 'horizontalInputPair',
      elements: [
        {
          element: 'input',
          name: 'plot_length',
          label: 'Plot Length:',
          type: 'number',
        },
        {
          element: 'radioGroup',
          name: 'plot_length_units',
          radioGroupHeader: 'Unit:',
          value: 'ft',
          options: [
            { value: 'ft', label: 'Feet' },
            { value: 'm', label: 'Meters' },
          ],
          onChange: (e: any) => {
            setOverallDisplayUnits(e.target.labels[0].innerHTML);
            setOverallValueUnits(e.target.value);
          },
        },
      ],
    },
    {
      element: 'horizontalInputPair',
      elements: [
        {
          element: 'input',
          name: 'plot_buffer_length',
          label: 'Plot Buffer length:',
          tooltip: 'Amount to remove',
          type: 'number',
        },
        {
          element: 'staticText',
          label: 'Unit:',
          name: 'plot_buffer_length_units',
          displayValue: overallDisplayUnits,
          value: overallValueUnits,
        },
      ],
    },
    {
      element: 'sectionHeading',
      label:
        'How much length from the left and right edges of each plot should we buffer out and exclude from the analysis?',
    },
    {
      element: 'horizontalInputPair',
      elements: [
        {
          element: 'input',
          name: 'plot_width',
          label: 'Plot width:',
          type: 'number',
        },
        {
          element: 'staticText',
          label: 'Unit:',
          name: 'plot_width_units',
          displayValue: overallDisplayUnits,
          value: overallValueUnits,
        },
      ],
    },
    {
      element: 'horizontalInputPair',
      elements: [
        {
          element: 'input',
          name: 'plot_buffer_width',
          label: 'Plot Buffer width:',
          tooltip: 'Amount to remove',
          type: 'number',
        },
        {
          element: 'staticText',
          label: 'Unit:',
          name: 'plot_buffer_width_units',
          displayValue: overallDisplayUnits,
          value: overallValueUnits,
        },
      ],
    },
  ];

  return (
    <FormAndImageStep
      {...rest}
      formElements={PlotBufferDirectEntryFields}
      validationSchema={plotBufferDirectEntryValidationSchema}
      initialValues={plotBufferDirectEntryInitialValues}
      imagePreviewElement={dynamicImage}
    >
      {rest.children}
    </FormAndImageStep>
  );
};

export default PlotBufferDirectEntryStep;
