import auth from './slices/auth';
import privacy from './slices/privacyPolicy';
import createTrial from './slices/createTrial';
import analyzeTrial from './slices/analyzeTrial';
import workspaceApi from './services/workspace';
import paymentApi from './services/payment';
import guestApi from './services/guest';
import analysisApi from './services/analysis';

const rootReducer = {
  auth,
  privacy,
  createTrial,
  analyzeTrial,
  [workspaceApi.reducerPath]: workspaceApi.reducer,
  [analysisApi.reducerPath]: analysisApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [guestApi.reducerPath]: guestApi.reducer,
};

export default rootReducer;
