import { Formik } from 'formik';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { forgotPasswordSchema } from 'validators/authSchemas';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginResponse,
} from 'types/auth.type';
import Spinner from 'components/global/Spinner';
import useNetworkRequest from 'hooks/useNetworkRequest';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';
import { Routes } from 'types/global.type';

const VerifyAccountContainer = () => {
  const [{ data, isLoading, error }, requestVerification] = useNetworkRequest<
    ForgotPasswordRequest,
    ForgotPasswordResponse
  >('/request-verification', {
    method: 'POST',
  });
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const [
    {
      data: verificationData,
      isLoading: verificationLoading,
      error: verificationError,
    },
    verifyAccount,
  ] = useNetworkRequest<void, LoginResponse>(`/email_verification/${token}`);

  useEffect(() => {
    if (token) verifyAccount();
  }, [token, verifyAccount]);

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values) => {
        requestVerification(values);
      }}
      validationSchema={forgotPasswordSchema}
    >
      {verificationLoading ? (
        <Spinner size="24px" />
      ) : (
        <ForgotPasswordForm
          loading={isLoading}
          error={getErrorMessage(error) || getErrorMessage(verificationError)}
          successMsg={
            data ? (
              data.msg
            ) : verificationData ? (
              <>
                Verification successful! Please{' '}
                <Link to={Routes.login}>login</Link> to start using your
                account.
              </>
            ) : (
              ''
            )
          }
        />
      )}
    </Formik>
  );
};

export default VerifyAccountContainer;
