import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { setAuthState } from 'redux/slices/auth';
import { DefaultAPIResponse } from 'types/global.type';
import { UpdateProfileRequest, User } from 'types/auth.type';
import { updateProfileSchema } from 'validators/authSchemas';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import toast from 'react-hot-toast';
import useAuth from 'hooks/useAuth';
import useStorage from 'hooks/useStorage';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import getStorageMethod from 'utils/helpers/getStorageMethod';
import UpdateProfileForm from 'components/auth/UpdateProfileForm';
import getFullName from 'utils/helpers/getFullName';

const UpdateProfile = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState<UpdateProfileRequest | null>(null);
  const { addStorageValue } = useStorage();
  const [{ data, isLoading, error }, updateProfile] = useNetworkRequest<
    UpdateProfileRequest,
    DefaultAPIResponse
  >('/profile', {
    method: 'PUT',
  });
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) toast.success('Account details updated successfully!');
  }, [data]);

  useEffect(() => {
    if (profile) {
      const updatedUser = { ...user, ...profile } as User;
      updatedUser.full_name = getFullName(updatedUser);

      dispatch(
        setAuthState({
          ...authState,
          user: updatedUser,
        }),
      );
      addStorageValue('user', updatedUser, getStorageMethod());

      updateProfile(profile);
      setProfile(null);
    }
  }, [profile, user, addStorageValue, authState, dispatch, updateProfile]);

  return (
    <Formik
      initialValues={{
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
      }}
      onSubmit={({ first_name, last_name }) => {
        setProfile({
          ...(first_name ? { first_name } : {}),
          ...(last_name ? { last_name } : {}),
        });
      }}
      validationSchema={updateProfileSchema}
      key={user?.first_name}
    >
      <UpdateProfileForm loading={isLoading} error={getErrorMessage(error)} />
    </Formik>
  );
};

export default UpdateProfile;
