import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { loginSchema } from 'validators/authSchemas';
import {
  LoginRequest,
  LoginResponse,
  SocialLoginRequest,
} from 'types/auth.type';
import useAuth from 'hooks/useAuth';
import Spinner from 'components/global/Spinner';
import LoginForm from 'components/auth/LoginForm';
import useNetworkRequest from 'hooks/useNetworkRequest';
import getErrorMessage from 'utils/helpers/getErrorMessage';
type CustomLoginResponse = LoginResponse & {
  data: string;
};
const LoginContainer = () => {
  const [, setParam] = useState<string | null>(null);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [{ data, isLoading, error }, login] = useNetworkRequest<
    LoginRequest,
    CustomLoginResponse
  >('/login', {
    method: 'POST',
  });

  const [
    {
      data: socialLoginData,
      isLoading: socialLoginLoading,
      error: socialLoginError,
    },
    socialLogin,
  ] = useNetworkRequest<SocialLoginRequest, LoginResponse>('/social-login', {
    method: 'POST',
  });
  const { updateAuthStatus } = useAuth();
  const [searchParams] = useSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const myParam = queryParams.get('message');
  useEffect(() => {
    setParam(myParam);
  }, [myParam]);
  useEffect(() => {
    if (data) updateAuthStatus(data, rememberMe);
    if (socialLoginData) updateAuthStatus(socialLoginData, true);
  }, [data, socialLoginData, rememberMe, updateAuthStatus]);

  const social_token = searchParams.get('social_token');
  useEffect(() => {
    if (social_token) socialLogin({ social_token });
  }, [social_token, socialLogin]);

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '', rememberMe: false }}
        onSubmit={(values) => {
          const body: LoginRequest = Object.assign({}, values);

          setRememberMe(values.rememberMe);
          delete body.rememberMe;

          login(body);
        }}
        validationSchema={loginSchema}
      >
        {socialLoginLoading ? (
          <Spinner size="24px" />
        ) : (
          <LoginForm
            error={getErrorMessage(error) || getErrorMessage(socialLoginError)}
            loading={isLoading}
            successMsg={data?.msg || socialLoginData?.msg || ''}
          />
        )}
      </Formik>
    </>
  );
};

export default LoginContainer;
