import Button from '../Button';
import useAuth from 'hooks/useAuth';
import Logo from 'assets/images/logo.png';
import DrawerNav from './subcomponents/DrawerNav';
import ProfileMenu from './subcomponents/ProfileMenu';
import { Routes } from 'types/global.type';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './header.module.scss';
import drawerNavStyles from './subcomponents/DrawerNav/drawerNav.module.scss';
import { drawerNavLinks } from 'utils/global';

const Header = () => {
  const { isLoggedIn, isGuest } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const showSignupCta = isGuest && location.pathname === Routes.createTrial;

  return (
    <header
      className={`${styles['header--outer']} ${
        isLoggedIn ? styles['header--outer--logged-in'] : ''
      }`}
      data-testid="header"
    >
      <div className={styles['header']}>
        <div
          className={styles['header__main-heading']}
          data-testid="header__main-heading"
        >
          <img
            alt="FarmTest Logo"
            src={Logo}
            onClick={() =>
              location.pathname !== Routes.home && navigate(Routes.home)
            }
            data-testid="home-button"
            className={styles['header__logo']}
          />
        </div>
        <div className={styles['header__auth-links']}>
          {drawerNavLinks.map((navLink, index) => {
            if (navLink.title === 'SIGN-IN' && isLoggedIn) {
              return null;
            }
            return (
              <Button
                action="link"
                variant="transparent"
                to={navLink.to}
                key={index}
              >
                {navLink.title}
              </Button>
            );
          })}
          {!isLoggedIn && (
            <Button
              action="link"
              variant="brand"
              to={Routes.signUp}
              className={drawerNavStyles['drawer-nav__try-farmtest']}
            >
              {showSignupCta ? 'Create Account' : 'TRY FARMTEST'}
            </Button>
          )}
          {isLoggedIn && <ProfileMenu />}
        </div>
        <div className={styles['header__action-buttons']}>
          <DrawerNav />
          {isLoggedIn && <ProfileMenu />}
        </div>
      </div>
    </header>
  );
};

export default Header;
