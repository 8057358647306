import { Formik } from 'formik';
import { trialTypeSchema } from 'validators/createTrialSchemas';
import TrialTypeForm from 'components/create-trial/TrialTypeForm';

import { StepProps } from '../../types/stepFlow.type';

const TrialTypeContainer = ({ children }: StepProps) => {
  return (
    <Formik
      initialValues={{
        planning_trial_type: '',
        planning_trial_subtype: '',
        test_nutrients: [],
        product: '',
        crop: '',
        variety: '',
        trial_equipment: '',
      }}
      onSubmit={() => {}}
      validationSchema={trialTypeSchema}
      validateOnMount
    >
      <>
        <TrialTypeForm />
        {children}
      </>
    </Formik>
  );
};

export default TrialTypeContainer;
