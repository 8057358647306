import { Link } from 'react-router-dom';
import Spinner from '../Spinner';
import styles from './button.module.scss';

type Variant = 'solid' | 'transparent' | 'brand' | 'ghost' | 'icon';
interface ButtonDefaultProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  useDefaultWidth?: boolean;
  variant?: Variant;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  'data-testid'?: string;
}

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonDefaultProps {}

interface LinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    ButtonDefaultProps {
  to: string;
}

type Props =
  | ({ action: 'link' } & LinkProps)
  | ({ action: 'button' } & ButtonProps);

const Button = ({
  isLoading,
  isDisabled,
  className,
  children,
  useDefaultWidth,
  leftIcon,
  rightIcon,
  variant = 'solid',
  ...rest
}: Props) => {
  const classList = `${styles['button']} ${className} ${
    styles['button--' + variant]
  } ${isLoading ? styles['button--loading'] : ''} ${
    isDisabled || isLoading ? styles['button--disabled'] : ''
  } ${useDefaultWidth ? styles['button--default'] : ''}`;

  rest['data-testid'] = rest['data-testid'] || 'button';

  const content = (
    <>
      {leftIcon ? (
        <span className={styles['button__left-icon']}>{leftIcon}</span>
      ) : null}

      {children}

      {rightIcon ? (
        <span className={styles['button__right-icon']}>{rightIcon}</span>
      ) : null}
    </>
  );

  const renderButton = () => {
    switch (rest.action) {
      case 'link':
        if (isDisabled) {
          return (
            <button className={classList} disabled={isDisabled}>
              {content}
            </button>
          );
        }
        return rest.to.startsWith('http') ? (
          <a className={classList} href={rest.to}>
            {content}
          </a>
        ) : (
          <Link className={classList} {...rest}>
            {content}
          </Link>
        );
      case 'button':
      default:
        return (
          <button
            className={classList}
            disabled={isDisabled || isLoading}
            type="button"
            {...rest}
          >
            {isLoading ? (
              <Spinner
                {...(variant === 'brand'
                  ? { color: '#ffffff', size: '24px' }
                  : {})}
              />
            ) : (
              content
            )}
          </button>
        );
    }
  };

  return <>{renderButton()}</>;
};

export default Button;
