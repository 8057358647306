import { Routes } from 'types/global.type';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldTrial } from 'types/workspace.type';
import { redirectToCheckout } from 'utils/createTrial';
import { useLazyStripeConfigQuery } from 'redux/services/payment';
import {
  useLazyCheckoutQuery,
  useLazyPartnerPurchaseQuery,
  useLazySpendTrialCreditQuery,
} from 'redux/services/workspace';
import useAuth from 'hooks/useAuth';
import Button from 'components/global/Button';
import Tooltip from 'components/global/Tooltip';
import ConfirmModal from 'components/global/ConfirmModal';
import getStorageMethod from 'utils/helpers/getStorageMethod';
import PurchaseChoiceModal from 'components/create-trial/PurchaseChoiceModal';
import getErrorMessage from 'utils/helpers/getErrorMessage';
import { CheckoutType } from 'types/global.type';

interface PurchaseShapefileProps {
  trial?: FieldTrial;
  saveTrial?: () => void;
  getTrialData: () => FormData;
  disablePurchase?: boolean;
}
const PurchaseShapefile = ({
  trial,
  getTrialData,
  disablePurchase,
}: PurchaseShapefileProps) => {
  const [partnerPurchaseModalOpen, setPartnerPurchaseModalOpen] =
    useState(false);
  const [spendTrialCreditModalOpen, setSpendTrialCreditModalOpen] =
    useState(false);
  const [purchaseChoiceModalOpen, setPurchaseChoiceModalOpen] = useState(false);
  const [getConfigKey, { data: configData, isLoading }] =
    useLazyStripeConfigQuery();
  const [
    checkout,
    { data: checkoutData, isLoading: checkoutLoading, error, isError },
  ] = useLazyCheckoutQuery();
  const [
    purchaseAsPartner,
    {
      data: partnerPurchaseData,
      isLoading: partnerPurchaseLoading,
      error: partnerPurchaseError,
      isError: partnerPurchaseIsError,
    },
  ] = useLazyPartnerPurchaseQuery();
  const [
    spendTrialCredit,
    {
      data: spendTrialCreditData,
      isLoading: spendTrialCreditLoading,
      error: spendTrialCreditError,
      isError: spendTrialCreditIsError,
    },
  ] = useLazySpendTrialCreditQuery();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { isGuest, user, fetchProfile } = useAuth();
  const [currentUser, setCurrentUser] = useState(user);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
    }
  }, [user]);
  useEffect(() => {
    if (configData && checkoutData) {
      const { publishableKey } = configData;
      const { sessionId } = checkoutData.data;
      const storage = getStorageMethod();

      if (storage === 'session') {
        window.open(
          `${Routes.purchaseTrial}?key=${publishableKey}&sessionId=${sessionId}`,
          '_self',
        );
      } else {
        redirectToCheckout(publishableKey, sessionId);
      }
    }
  }, [configData, checkoutData]);

  useEffect(() => {
    if (partnerPurchaseData) {
      const pathname = `${Routes.fieldTrials}/${
        trial?.field_id || partnerPurchaseData.data.field_id
      }`;

      if (location.pathname === pathname) navigate(0);
      else navigate(pathname);
    }
  }, [partnerPurchaseData, location.pathname, navigate, trial]);

  useEffect(() => {
    if (spendTrialCreditData) {
      const pathname = `${Routes.fieldTrials}/${
        trial?.field_id || spendTrialCreditData.data.field_id
      }`;

      if (location.pathname === pathname) navigate(0);
      else navigate(pathname);
    }
  }, [spendTrialCreditData, location.pathname, navigate, trial]);

  // catch any errors
  useEffect(() => {
    if (isError || partnerPurchaseIsError || spendTrialCreditIsError) {
      const errorObj: any =
        error || partnerPurchaseError || spendTrialCreditError;
      setErrorMessage(getErrorMessage(errorObj));
    }
  }, [
    error,
    isError,
    partnerPurchaseError,
    partnerPurchaseIsError,
    spendTrialCreditError,
    spendTrialCreditIsError,
  ]);

  const makePayment = () => {
    fetchProfile();
    if (currentUser?.category === 'partner') {
      setPartnerPurchaseModalOpen(true);
    } else if (currentUser?.trial_credits && currentUser.trial_credits > 0) {
      setSpendTrialCreditModalOpen(true);
    } else {
      getConfigKey();
      setPurchaseChoiceModalOpen(true);
    }
  };

  return (
    <>
      <Tooltip
        label={
          isGuest
            ? 'First create an account to enable purchasing or saving a field trial.'
            : ''
        }
      >
        <Button
          action="button"
          variant={trial ? 'transparent' : 'brand'}
          datatype={trial ? 'table-link' : ''}
          isLoading={isLoading || checkoutLoading || spendTrialCreditLoading}
          isDisabled={isGuest || disablePurchase}
          onClick={() => {
            makePayment();
          }}
          data-testid="purchase-shapefile"
        >
          Purchase
        </Button>
      </Tooltip>

      <ConfirmModal
        isOpen={partnerPurchaseModalOpen}
        isLoading={partnerPurchaseLoading}
        heading="Purchase Shapefile?"
        description="An email with the details of this purchase will be sent to FarmTest."
        confirmCta="Purchase"
        closeModal={() => {
          setPartnerPurchaseModalOpen(false);
        }}
        confirmAction={() => {
          const submitData = getTrialData();
          purchaseAsPartner(submitData);
        }}
        error={partnerPurchaseIsError ? errorMessage : null}
      />
      <ConfirmModal
        isOpen={spendTrialCreditModalOpen}
        isLoading={spendTrialCreditLoading}
        heading="Purchase Shapefile with trial credit?"
        description={`You have ${currentUser?.trial_credits} trial credits remaining. 
        Spending a trial credit will allow you to purchase this trial.`}
        confirmCta="Purchase"
        closeModal={() => {
          setSpendTrialCreditModalOpen(false);
        }}
        confirmAction={() => {
          const submitData = getTrialData();
          spendTrialCredit(submitData);
        }}
        error={spendTrialCreditIsError ? errorMessage : null}
      />
      <PurchaseChoiceModal
        isOpen={purchaseChoiceModalOpen}
        isLoading={checkoutLoading}
        optionsLoading={isLoading}
        options={configData?.prices || []}
        headerText="Purchase Options"
        closeModal={() => setPurchaseChoiceModalOpen(false)}
        confirmAction={(type: CheckoutType) => {
          getConfigKey();
          const submitData = getTrialData();
          // this only works if a variable is created with the output of getTrialData().
          // otherwise, if we just call getTrialData in checkout(), the data does not include
          // the values the user entered on this page, they're set to null.
          // I do not understand this. It's like the function return value is cached/frozen by some
          // optimizer somewhere, but assigning it to a variable magically unfreezes it.
          checkout({ type, formData: submitData });
        }}
        error={isError ? errorMessage : null}
      />
    </>
  );
};

export default PurchaseShapefile;
