import { User } from 'types/auth.type';
import { capitalizeFirstLetter } from './capitalizationUtils';

const getFullName = (user: User): string => {
  const { first_name, last_name } = user;

  return `${first_name ? capitalizeFirstLetter(first_name) + ' ' : ''}${
    capitalizeFirstLetter(last_name) || ''
  }`.trim();
};

export default getFullName;
