import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateTrialState } from 'types/createTrial.type';

import { Step } from '../../types/stepFlow.type';

const initialState: CreateTrialState = {
  trial: {},
  steps: {},
  completedSteps: {},
};

export const createTrialSlice = createSlice({
  name: 'createTrial',
  initialState,
  reducers: {
    updateStep: (state, action: PayloadAction<Step>) => {
      const { index, data } = action.payload;

      state.steps[index] = data;
      state.completedSteps[index] = true;
      state.trial = { ...state.trial, ...data };
    },
    clearStep: (state, action: PayloadAction<Step>) => {
      const { index, data } = action.payload;

      delete state.steps[index];
      state.completedSteps[index] = false;
      state.trial = data;
    },
    updateTrialData: (state, action: PayloadAction<Step['data']>) => {
      state.trial = { ...state.trial, ...action.payload };
    },
    clearTrialData: (state) => {
      state.steps = {};
      state.completedSteps = {};
      state.trial = {};
    },
  },
});

export const { updateStep, clearStep, updateTrialData, clearTrialData } =
  createTrialSlice.actions;

export default createTrialSlice.reducer;
