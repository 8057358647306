import { getBaseQuery } from 'utils/config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { StripeConfigResponse } from 'types/payment.type';

const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: getBaseQuery(),
  endpoints: (builder) => ({
    stripeConfig: builder.query<StripeConfigResponse, void>({
      query: () => '/setup',
    }),
  }),
});

export const { useLazyStripeConfigQuery } = paymentApi;

export default paymentApi;
