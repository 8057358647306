import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import getFileExtension from 'utils/helpers/getFileExtension';
import UploadedFile from '../UploadedFile';
import styles from './uploadedFileList.module.scss';

interface UploadedFileListProps {
  name: string;
}

interface DisplayedFile {
  file: File;
  uploaded: boolean;
}

interface DisplayedFiles {
  cpg?: DisplayedFile | null;
  dbf?: DisplayedFile | null;
  prj?: DisplayedFile | null;
  shp?: DisplayedFile | null;
  shx?: DisplayedFile | null;
  zip?: DisplayedFile | null;
}

const initialDisplayedFiles: DisplayedFiles = {
  cpg: null,
  dbf: null,
  prj: null,
  shp: null,
  shx: null,
};

const UploadedFileList = ({ name }: UploadedFileListProps) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();
  const [displayedFiles, setDisplayedFiles] = useState<DisplayedFiles>(
    initialDisplayedFiles,
  );

  const files = useMemo(
    () => (Array.isArray(values[name]) ? (values[name] as File[]) : []),
    [values, name],
  );

  const removeFile = (filename: string): void => {
    const updatedFileList = files.filter((file) => file.name !== filename);

    const fileExtension = getFileExtension(filename);
    const emptyFile = files.length
      ? {
          file: new File(
            [''],
            files[0].name.replace(/.{0,3}$/, fileExtension),
            {
              ...files[0],
            },
          ),
          uploaded: false,
        }
      : null;

    displayedFiles[fileExtension as keyof DisplayedFiles] = emptyFile;

    setFieldValue(name, updatedFileList);
    setDisplayedFiles(displayedFiles);
  };

  useEffect(() => {
    if (files.length) {
      let updatedFiles = Object.assign({}, initialDisplayedFiles);

      if (getFileExtension(files[0].name) === 'zip') {
        updatedFiles = {
          zip: {
            file: files[0],
            uploaded: true,
          },
        };
      } else {
        for (const extension in updatedFiles) {
          const file = files.find(
            (existingFile) => getFileExtension(existingFile.name) === extension,
          );

          if (!file) {
            updatedFiles[extension as keyof DisplayedFiles] = {
              file: new File(
                [''],
                files[0].name.replace(/.{0,3}$/, extension),
                {
                  ...files[0],
                },
              ),
              uploaded: false,
            };
          } else {
            updatedFiles[extension as keyof DisplayedFiles] = {
              file,
              uploaded: true,
            };
          }
        }
      }

      setDisplayedFiles(updatedFiles);
    }
  }, [name, files]);

  return (
    <>
      <ul className={styles['file-list']}>
        {(displayedFiles.dbf === null ? [] : Object.values(displayedFiles)).map(
          (file: DisplayedFile, index) => (
            <UploadedFile
              file={file.file}
              key={index}
              isPlaceholder={!file.uploaded}
              removeFile={() => removeFile(file.file.name)}
            />
          ),
        )}
      </ul>
    </>
  );
};

export default UploadedFileList;
