import Modal from 'components/global/Modal';
import Button from 'components/global/Button';
import styles from './purchaseChoiceModal.module.scss';
import Spinner from 'components/global/Spinner';
import { CheckoutType } from '../../../types/global.type';

interface PurchaseChoiceModalProps {
  isOpen: boolean;
  isLoading: boolean;
  optionsLoading: boolean;
  options: object[];
  headerText: string;
  error: string | null;
  closeModal: () => void;
  confirmAction: (type: CheckoutType) => void;
}

const PurchaseChoiceModal = ({
  headerText,
  options,
  isOpen,
  isLoading,
  optionsLoading,
  error,
  closeModal,
  confirmAction,
}: PurchaseChoiceModalProps) => {
  // handle loading state
  const handleBuy = (type: CheckoutType) => {
    // Logic to trigger Stripe Checkout
    confirmAction(type);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClickOutside={!isLoading ? closeModal : () => {}}
      className={styles['purchase-modal']}
      data-testid="purchase-choice-modal"
    >
      <h2
        className={styles['purchase-modal__header']}
        data-testid="purchase-choice-header"
      >
        {headerText}
      </h2>
      <div
        data-testid="purchase-options"
        className={styles['purchase-modal__purchase-options']}
      >
        {optionsLoading ? <Spinner data-testid="spinner" color="brand" /> : ''}
        {options.map((option: any) => (
          <div
            className={styles['purchase-modal__purchase-options__option']}
            key={option.type}
          >
            <ul
              className={
                styles['purchase-modal__purchase-options__option__list-items']
              }
              key={option.type}
            >
              {option.features.map((feature: string) => (
                <li
                  key={feature}
                  className={
                    styles[
                      'purchase-modal__purchase-options__option__list-item'
                    ]
                  }
                >
                  {feature}
                </li>
              ))}
            </ul>
            <Button
              className={
                styles['purchase-modal__purchase-options__option__button']
              }
              variant="brand"
              useDefaultWidth={false}
              action="button"
              data-testid={option.type}
              onClick={() => handleBuy(option.type)}
              isLoading={isLoading}
            >
              {option.price}
            </Button>
          </div>
        ))}
      </div>
      {error ? (
        <p
          data-testid="choice-error"
          className={styles['purchase-modal__choice-error']}
        >
          {error}
        </p>
      ) : (
        ''
      )}
      <div
        className={`${styles['purchase-modal__footer']} ${
          isLoading ? styles['purchase-modal__footer--disabled'] : ''
        }`}
        data-testid="purchase-choice-footer"
      >
        <Button action="button" disabled={isLoading} onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default PurchaseChoiceModal;
