import { useField } from 'formik';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import Tooltip from '../Tooltip';
import styles from './checkbox.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | React.ReactNode;
  tooltip?: string;
}

const Checkbox = ({
  label,
  tooltip,
  className,
  id,
  ...rest
}: CheckboxProps) => {
  return (
    <div
      className={`${styles['checkbox']} ${
        rest.disabled ? styles['checkbox--disabled'] : ''
      } ${className}`}
    >
      <input type="checkbox" id={id} {...rest} data-testid={id} />

      <label htmlFor={id}>{label}</label>

      {tooltip ? (
        <Tooltip label={tooltip}>
          <InfoIcon />
        </Tooltip>
      ) : null}
    </div>
  );
};

export interface FormikCheckboxProps extends CheckboxProps {
  name: string;
}

export const FormikCheckbox = ({
  label,
  tooltip,
  className,
  name,
  ...rest
}: FormikCheckboxProps) => {
  const [field, , helpers] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
  };

  return (
    <div className={styles['checkbox-wrapper']}>
      <Checkbox
        label={label}
        tooltip={tooltip}
        className={className}
        id={name}
        {...rest}
        checked={field.value}
        onChange={handleChange}
      />
    </div>
  );
};

export default Checkbox;
