import { getBaseQuery } from 'utils/config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CreateTrialResponse } from 'types/createTrial.type';

const guestApi = createApi({
  reducerPath: 'guestApi',
  baseQuery: getBaseQuery(),
  endpoints: (builder) => ({
    guestCreateTrial: builder.mutation<CreateTrialResponse, FormData>({
      query: (body) => ({
        url: '/upload',
        body,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGuestCreateTrialMutation } = guestApi;

export default guestApi;
