import { useFormikContext } from 'formik';
import styles from './radioGroup.module.scss';
import React, { ChangeEvent, MouseEvent, useState } from 'react';
import Tooltip from '../Tooltip';
import { ReactComponent as InfoIcon } from 'assets/vectors/info.svg';
import { RadioGroupProps } from '../../../types/stepFlow.type';

interface handleClickProps {
  e: MouseEvent<HTMLInputElement>;
  value: string;
}

const RadioGroup = ({
  name,
  options,
  defaultValue,
  radioGroupHeader,
  radioGroupFooter,
  align,
  ...rest
}: RadioGroupProps) => {
  const { setFieldValue } = useFormikContext();
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const handleClick = ({ e, value }: handleClickProps) => {
    // activate any passed in onClick and change the input state
    if (typeof rest?.onClick === 'function') {
      rest.onClick(e);
    }
    setSelectedValue(value);
    setFieldValue(name, value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof rest.onChange === 'function') {
      rest.onChange(e);
    }
  };

  const containerClass =
    align === 'singleInput'
      ? styles['radio-input-single-input']
      : styles['radio-input'];

  return (
    <div className="radio-group-container">
      {radioGroupHeader ? (
        <div className={`${styles['radio-group-header']} radio-group-label`}>
          <h2>{radioGroupHeader}</h2>
        </div>
      ) : null}
      {options.map(({ value, label, tooltip }) => (
        <div
          className={containerClass}
          key={`${name}-${value}`}
          data-testid={`radio-input`}
        >
          <input
            type="radio"
            id={`${name}-${value}`}
            name={name}
            value={value}
            onClick={(e) => handleClick({ e, value })}
            onChange={(e) => handleChange(e)}
            checked={value === selectedValue}
            {...rest}
            data-testid={`${name}-${value}`}
          />

          <label htmlFor={`${name}-${value}`}>
            {label}
            {tooltip ? (
              <Tooltip label={tooltip}>
                <InfoIcon />
              </Tooltip>
            ) : null}
          </label>
        </div>
      ))}
      {radioGroupFooter ? (
        <div className={styles['radio-group-footer']}>
          {typeof radioGroupFooter === 'string' ? (
            <p>{radioGroupFooter}</p>
          ) : (
            React.isValidElement(radioGroupFooter) && radioGroupFooter
          )}
        </div>
      ) : null}
    </div>
  );
};

export default RadioGroup;
