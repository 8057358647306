import Button from 'components/global/Button';
import FormErrorMessage from 'components/global/FormErrorMessage';
import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { CreateTrialFormValues, FilesType } from 'types/createTrial.type';
import { demoFiles } from 'utils/createTrial';
import styles from './fileUploadModalButton.module.scss';

interface UploadModalButtonProps {
  description: string;
  files: FilesType;
  error: string;
  name: string;
  metaName: string;
  filesTitle: string;
  isDisabled: boolean;
  openUploadModal: () => void;
}

interface DemoFiles {
  field_boundary_gdf: string[];
  // abline_gdf: string[];
}

const FileUploadModalButton = ({
  description,
  files,
  error,
  name,
  metaName,
  filesTitle,
  isDisabled,
  openUploadModal,
}: UploadModalButtonProps) => {
  const { values, setFieldValue } = useFormikContext<CreateTrialFormValues>();
  const uploadRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (error) {
      uploadRef.current?.scrollIntoView();
    }
  }, [error]);

  const getFileNames = (): string => {
    let result = 'No file selected';

    switch (files) {
      case 'demo':
        result = demoFiles[name as keyof DemoFiles].join(', ');
        break;
      case 'trial':
        const fileNames = values[metaName as keyof CreateTrialFormValues];
        if (fileNames && Array.isArray(fileNames)) {
          result = fileNames.join(', ');
        }

        break;
      default:
        if (Array.isArray(files) && files.length) {
          result = files.map((file) => file.name).join(', ');
        }
    }

    return result;
  };

  const showDemoButton = Object.keys(demoFiles).includes(name);

  return (
    <div
      className={`${styles['upload-modal-button']} ${
        isDisabled ? styles['upload-modal-button--disabled'] : ''
      }`}
      ref={uploadRef}
      data-testid="upload-modal-button"
    >
      <p data-testid="upload-modal-button__description">{description}</p>

      {showDemoButton && (
        <Button
          action="button"
          variant="brand"
          onClick={() => setFieldValue(name, 'demo')}
          className={styles['upload-modal-button__demo']}
          isDisabled={isDisabled}
          data-testid="demo-button"
        >
          {`Demo ${filesTitle} Files`}
        </Button>
      )}

      <div className={styles['upload-modal-button__container']}>
        <Button
          action="button"
          variant="brand"
          onClick={openUploadModal}
          isDisabled={isDisabled}
          data-testid="open-upload-button"
        >
          Select
        </Button>

        <p
          className={styles['upload-modal-button__file-info']}
          data-testid="file-info"
        >
          {getFileNames()}
        </p>
      </div>

      <FormErrorMessage error={error} />
    </div>
  );
};

export default FileUploadModalButton;
