export const capitalizeFirstLetter = (word: string): string => {
  if (!word || typeof word !== 'string') return '';

  return word[0].toUpperCase() + word.substring(1);
};

export const titleCase = (sentence: string): string => {
  if (!sentence || typeof sentence !== 'string') return '';

  return sentence.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};
