import Button from 'components/global/Button';
import { useFormikContext } from 'formik';
import getFileExtension from 'utils/helpers/getFileExtension';
import styles from './fileChooser.module.scss';

interface FileChooserProps {
  name: string;
}

const FileChooser = ({ name }: FileChooserProps) => {
  const { values, setFieldValue } = useFormikContext<Record<string, any>>();

  const files = Array.isArray(values[name]) ? (values[name] as File[]) : [];

  const getFileName = (name: string): string => {
    return name.slice(0, name.length - 4);
  };

  const addFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files || [];
    let acceptedFiles: File[] = [];

    for (let i = 0; i < uploadedFiles?.length; i++) {
      const file = uploadedFiles[i];
      const fileExtension = getFileExtension(file.name);

      if (fileExtension === 'zip') {
        acceptedFiles = [file];
        break;
      }

      //return if name doesn't match first file
      if (files.length) {
        const firstFilename = getFileName(files[0].name);
        const currentFilename = getFileName(file.name);

        if (firstFilename !== currentFilename) break;
      }

      const fileTypeExists = files.find(
        (existingFile) => fileExtension === getFileExtension(existingFile.name),
      );

      if (!fileTypeExists) acceptedFiles.push(file);
    }

    setFieldValue(name, [...files, ...acceptedFiles]);
  };

  return (
    <div className={styles['file-chooser']}>
      <input
        type="file"
        onChange={addFiles}
        accept=".dbf, .shp, .shx, .cpg, .prj, .zip"
        className={styles['file-chooser__input']}
        multiple
        data-testid="file-input"
      />
      <Button action="button" className={styles['file-chooser__button']}>
        Choose files to upload
      </Button>
    </div>
  );
};

export default FileChooser;
